import {
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_RECIPES,
} from '@farmersdog/constants/paths';

import { mountSpotifyEvent } from './mountSpotifyEvent';
import { SpotifyEvent } from './types';

export const SPOTIFY_PAGE_VIEW_ROUTES = [
  PATH_SIGNUP_ME,
  PATH_SIGNUP_PETS,
  PATH_SIGNUP_RECIPES,
];

/**
 * Mount a Spotify lead event
 */
export function mountSpotifyPageView(pathname: string): void {
  if (SPOTIFY_PAGE_VIEW_ROUTES.includes(pathname)) {
    mountSpotifyEvent({ name: SpotifyEvent.PageView });
  }
}
