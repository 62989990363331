import type { Sources } from '../../data';

import PickyEatingJpg from './picky-eating.jpg';
import PickyEatingWebp from './picky-eating.webp';

import WeightManagementJpg from './weight-management.jpg';
import WeightManagementWebp from './weight-management.webp';

import SeniorCareJpg from './senior-care.jpg';
import SeniorCareWebp from './senior-care.webp';

import PuppyCareJpg from './puppy-care.jpg';
import PuppyCareWebp from './puppy-care.webp';

import PuppyCareMobileJpg from './puppy-care-mobile.jpg';
import PuppyCareMobileWebp from './puppy-care-mobile.webp';

import SeniorCareMobileJpg from './senior-care-mobile.jpg';
import SeniorCareMobileWebp from './senior-care-mobile.webp';

import WeightManagementMobileJpg from './weight-management-mobile.jpg';
import WeightManagementMobileWebp from './weight-management-mobile.webp';

import PickyEatingMobileJpg from './picky-eating-mobile.jpg';
import PickyEatingMobileWebp from './picky-eating-mobile.webp';

export const WeightManagementSources: Sources = {
  webP: WeightManagementWebp,
  jpg: WeightManagementJpg,
};

export const PickyEatingSources: Sources = {
  webP: PickyEatingWebp,
  jpg: PickyEatingJpg,
};

export const PuppyCareSources: Sources = {
  webP: PuppyCareWebp,
  jpg: PuppyCareJpg,
};

export const SeniorCareSources: Sources = {
  webP: SeniorCareWebp,
  jpg: SeniorCareJpg,
};

export const PuppyCareMobileSources: Sources = {
  webP: PuppyCareMobileWebp,
  jpg: PuppyCareMobileJpg,
};

export const SeniorCareMobileSources: Sources = {
  webP: SeniorCareMobileWebp,
  jpg: SeniorCareMobileJpg,
};

export const WeightManagementMobileSources: Sources = {
  webP: WeightManagementMobileWebp,
  jpg: WeightManagementMobileJpg,
};

export const PickyEatingMobileSources: Sources = {
  webP: PickyEatingMobileWebp,
  jpg: PickyEatingMobileJpg,
};
