import { paths } from '@farmersdog/constants';

interface RecipesRoutes {
  recipesRoutes: string[];
  previousRoute: string;
  nextRoute: string;
}

export function getRecipesRoutes(routes: string[]): RecipesRoutes | undefined {
  const recipesRoutes = routes.filter(route =>
    route.includes(paths.PATH_SIGNUP_RECIPES)
  );

  if (recipesRoutes.length === 0) {
    return undefined;
  }

  const firstRecipesRouteIdx = routes.indexOf(recipesRoutes[0]);
  const previousRoute = routes[firstRecipesRouteIdx - 1];
  const lastRecipesRouteIdx = routes.indexOf(recipesRoutes.at(-1)!);
  const nextRoute = routes[lastRecipesRouteIdx + 1];

  return { recipesRoutes, previousRoute, nextRoute };
}
