import { useViewport } from '@farmersdog/corgi-x';

import { PressBarMobile } from './PressBarMobile';
import { PressBarDesktop } from './PressBarDesktop';

export const PressBarSection = () => {
  const viewport = useViewport();
  const isMobile = viewport.xs && !viewport.md;

  return isMobile ? <PressBarMobile /> : <PressBarDesktop />;
};
