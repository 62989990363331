import { useState, useRef, useEffect } from 'react';

import { Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { betterForThemItems } from './data';
import { paths } from '@farmersdog/constants';
import { ExtendedButton } from '../ExtendedButton';
import { Player } from '@lottiefiles/react-lottie-player';

import styles from './BetterForThem.module.css';

export const BetterForThem = () => {
  const [activeAnimationIndex, setActiveAnimationIndex] = useState(-1);

  const sectionRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const el = sectionRef.current;
    if (!el) return;

    const observer = new IntersectionObserver(
      entries => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setActiveAnimationIndex(0);
          observer.unobserve(el);
        }
      },
      {
        threshold: 0.2,
      }
    );

    observer.observe(el);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <section ref={sectionRef} className={styles.wrapper}>
      <div className={styles.container}>
        <Text
          bold
          as="h2"
          color="kale-3"
          align="center"
          bottomSpacing="lg"
          variant="heading-28"
          className={styles.heading}
        >
          Better for them <br className={styles.mobileBreakline} />
          and easier for you
        </Text>
        <Grid className={styles.grid} alignItems="center">
          {betterForThemItems.map(({ id, animation, title }, index) => {
            const isCurrentAnimationPlaying = index === activeAnimationIndex;

            return (
              <GridItem lg={4} key={id} className={styles.item}>
                <Player
                  key={`${index}-${activeAnimationIndex}`}
                  src={animation}
                  className={styles.player}
                  autoplay={isCurrentAnimationPlaying}
                  loop={false}
                  keepLastFrame
                  onEvent={event => {
                    if (event === 'complete' && isCurrentAnimationPlaying) {
                      setActiveAnimationIndex(prevIndex => prevIndex + 1);
                    }
                  }}
                />
                <Text
                  topSpacing={{
                    xs: 'xxs',
                    lg: 'md',
                  }}
                  align="center"
                  color="kale-3"
                  variant="heading-16"
                  as="h3"
                  className={styles.title}
                >
                  {title}
                </Text>
              </GridItem>
            );
          })}
        </Grid>

        <ExtendedButton
          type="link"
          to={paths.PATH_SIGNUP}
          variant="bordered-kale"
          className={styles.button}
        >
          Make the Switch
        </ExtendedButton>
      </div>
    </section>
  );
};
