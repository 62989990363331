import { Picture } from '@farmersdog/corgi-x';
import { PATH_SIGNUP_ME } from '@farmersdog/constants/paths';
import { getLinkToFreshSignup } from '@farmersdog/tosa/src/utils/getLinkToSignupFlow';
import { imageCardSources } from './assets';

import { ExtendedButton } from '../ExtendedButton';
import { useCorgiV2Viewport } from 'src/screen';

import styles from './ImageCard.module.css';

export const ImageCard = () => {
  const linkToFreshSignup = getLinkToFreshSignup(PATH_SIGNUP_ME);

  const { jpg, webp } = useCorgiV2Viewport({
    xs: imageCardSources.xs,
    lg: imageCardSources.lg,
  });

  return (
    <div className={styles.container}>
      <Picture
        sources={[jpg, webp]}
        className={styles.backgroundImage}
        alt="Boy sitting in The Farmers Dog box"
      />
      <ExtendedButton
        className={styles.cta}
        variant="bordered-white"
        type="link"
        to={linkToFreshSignup}
      >
        Get Started
      </ExtendedButton>
    </div>
  );
};
