import { Picture, Text } from '@farmersdog/corgi-x';

import type { Sources } from '../../data';

import OpenIcon from '../../assets/open-icon.svg';

import styles from './BenefitCard.module.css';

type TransformOrigin =
  | 'center'
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top left'
  | 'top right'
  | 'bottom left'
  | 'bottom right';

interface BenefitCardProps {
  alt: string;
  sources: Sources;
  title: React.ReactElement;
  onClick: () => void;
  zoomOrigin?: TransformOrigin;
  ariaLabel: string;
}

export const BenefitCard = ({
  alt,
  sources,
  title,
  onClick,
  zoomOrigin = 'center',
  ariaLabel,
}: BenefitCardProps) => {
  return (
    <div
      role="button"
      tabIndex={0}
      className={styles.card}
      onClick={onClick}
      aria-label={ariaLabel}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          onClick();
        }
      }}
    >
      <Picture
        alt={alt}
        sources={[sources.webP, sources.jpg]}
        className={styles.image}
        style={{ transformOrigin: zoomOrigin }}
      />
      <Text className={styles.title} color="white" variant="heading-28" bold>
        {title}
      </Text>
      <OpenIcon className={styles.openButton} />
    </div>
  );
};
