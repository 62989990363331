import { track } from '@farmersdog/analytics';

import eventNames from './eventNames';

export type PriceLoggingMetadata = {
  credits?: number;
  discountAmount?: number;
  discountPercentage?: number;
  dailyPrice?: number;
  preTaxTotal?: number;
  postTaxTotal?: number;
  taxTotal?: number;
  orderId?: number;
  petId?: number;
  frequency?: number;
};

export function trackPricesSeen(metadata: PriceLoggingMetadata) {
  track(eventNames.prices_seen, metadata);
}
