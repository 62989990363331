import { useMemo } from 'react';

import { paths } from '@farmersdog/constants';
import { isDiySignup } from '@farmersdog/lead-browser-storage';

import { useGetLead } from '../../../graphql/queries';
import { useTreatsInCheckoutUi } from '../../../hooks';
import { getRecipesRouteForPet } from '../utils/getRecipesRouteForPet';

import { isPetInfoComplete } from './isPetInfoComplete';

import type { UseFeatureHook } from '../../../types';
import type { Lead, Pet } from '../types';

/**
 * Returns an array of routes that the lead can access (based on their current
 * state) and in the order which they will proceed through them on their
 * journey through the signup flow.
 */
// TODO: Replace these arguments once we have a unified useGetFeature hook
export function useAvailableLeadJourneyRoutes({
  useFeature,
}: {
  useFeature: UseFeatureHook;
}): string[] | undefined {
  const { data: leadData } = useGetLead();

  const { getFeatureData: getTreatsInCheckoutUiFeatureData } =
    useTreatsInCheckoutUi({
      useFeature,
      lazy: true,
    });

  return useMemo(() => {
    if (!leadData) {
      return undefined;
    }

    const { lead } = leadData;

    if (!lead || lead.pets.length === 0) {
      return [];
    }

    const lastPetName = lead.pets.at(-1)?.name;
    const lastPetRoute = `${paths.PATH_SIGNUP_PETS}?pet=${lastPetName}&card=PetHealth`;

    const recipesRoutes = getRecipesRoutes(lead);

    const treatsRoute =
      canAccessTreatsPage(lead, getTreatsInCheckoutUiFeatureData) &&
      paths.PATH_SIGNUP_TREATS;

    const checkoutRoute =
      canAccessCheckoutPage(lead, getTreatsInCheckoutUiFeatureData) &&
      paths.PATH_SIGNUP_CHECKOUT;

    return [lastPetRoute, ...recipesRoutes, treatsRoute, checkoutRoute].filter(
      (s): s is string => Boolean(s)
    );
  }, [leadData, getTreatsInCheckoutUiFeatureData]);
}

function getCanAccessRecipesPages(lead: Lead): boolean {
  return lead.pets.length > 0 && lead.pets.every(isPetInfoComplete);
}

function getRecipesRoutes(lead: Lead): string[] {
  if (!getCanAccessRecipesPages(lead)) {
    return [];
  }

  if (isDiySignup()) {
    return [paths.PATH_SIGNUP_RECIPES];
  }

  return lead.pets
    .map((pet, petIndex) => {
      const previousPet = lead.pets[petIndex - 1];

      if (previousPet && !petHasFreshSelections(previousPet)) {
        return undefined;
      }

      return getRecipesRouteForPet(pet.name);
    })
    .filter((s): s is string => Boolean(s));
}

function canAccessTreatsPage(
  lead: Lead,
  getTreatsInCheckoutUiFeatureData: () => Record<string, unknown>
): boolean {
  return (
    !isDiySignup() &&
    lead.pets.length > 0 &&
    lead.pets.every(petHasFreshSelections) &&
    getTreatsInCheckoutUiFeatureData().treatment === 'page'
  );
}

function canAccessCheckoutPage(
  lead: Lead,
  getTreatsInCheckoutUiFeatureData: () => Record<string, unknown>
): boolean {
  if (lead.pets.length === 0) {
    return false;
  }

  if (isDiySignup()) {
    // We don't currently store DIY selections on the lead itself, so we can't
    // tell if they're populated here. Let's return the checkout page so that
    // customers can recover to the DIY recipes page (since it will be the
    // second-to-last route they have access to).
    return true;
  }

  return (
    lead.pets.every(petHasFreshSelections) &&
    (getTreatsInCheckoutUiFeatureData().treatment === 'page'
      ? leadHasTreatsSelections(lead)
      : true)
  );
}

function petHasFreshSelections(pet: Pet): boolean {
  return Boolean(
    pet.selection?.fresh?.options.recipes &&
      pet.selection?.fresh?.options.recipes.length > 0
  );
}

function leadHasTreatsSelections(lead: Lead): boolean {
  return Boolean(lead.selection?.treats);
}
