import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
  useMountPinterestPageView,
  useMountNextDoorPageView,
  useMountAmazonTagPageView,
  useMountGa4PageView,
  useMountAudacyPageView,
  useMountPostiePageView,
  mountSpotifyPageView,
} from '@farmersdog/pixels';

import { getIsFirstPetsPage } from '../../utils';

import { useMountTikTokPageView } from './useMountTikTokPageView';

import type { BranchNode } from '../../blueprint/types';

interface UseHandlePageViewArgs {
  current: string | undefined;
  formSteps: BranchNode[];
  email: string | undefined;
}

export function useHandlePageView({
  current,
  formSteps,
  email,
}: UseHandlePageViewArgs): void {
  const { pathname } = useLocation();

  const isFirstPetsPage = getIsFirstPetsPage({ current, formSteps });

  useMountTikTokPageView({ email, isFirstPetsPage });
  useMountGa4PageView();
  useMountPinterestPageView();
  useMountAudacyPageView();
  useMountNextDoorPageView({ isFirstPetsPage });
  useMountAmazonTagPageView({ isFirstPetsPage });
  useMountPostiePageView();

  // for pageview functions that should fire when the path (not querystring) changes
  useEffect(() => {
    mountSpotifyPageView(pathname);
  }, [pathname]);

  // if we need to fire pageviews on querystring changes as well (ie. different signup flow cards) we can create a different effect that includes search in the dependency array
}
