import type { NutritionFactsContent } from '../../types';

export enum GraphQLEndpoints {
  CGS = 'cgs',
  TOSA = 'tosa',
  AUTH = 'auth',
}

export enum TreatSku {
  ChickenSnapsV2Large = 'SNPS_CHKNV2_L',
  ChickenSnapsV2Regular = 'SNPS_CHKNV2_M',
  ChickenSnapsLarge = 'SNAPS_CHICKEN_L',
  ChickenSnapsRegular = 'SNAPS_CHICKEN_M',
  PorkSnapsLarge = 'SNPPOPE08000',
  PorkSnapsRegular = 'SNPPOPE03000',
  TurkeySnapsLarge = 'SNAPS_TURKEY_L',
  TurkeySnapsRegular = 'SNAPS_TURKEY_M',
  TurkeySnapsV2Large = 'SNPTKPE08000',
  TurkeySnapsV2Regular = 'SNPTKPE03000',
}

export enum ProductStatus {
  Available = 'Available',
  ComingSoon = 'Coming Soon',
}

export enum TreatPackageSize {
  Regular = 'Regular',
  Large = 'Large',
}

export enum SnapSticksRecipeDisplayName {
  Chicken = 'Chicken',
  Pork = 'Pork',
  Turkey = 'Turkey',
}

export enum TreatIngredientName {
  Apple = 'Apple',
  Chicken = 'Chicken',
  SeaSalt = 'Sea Salt',
  Squash = 'Squash',
  Turkey = 'Turkey',
  Pear = 'Pear',
  Pork = 'Pork',
}

export enum SnapSticksRecipeName {
  ChickenV2 = 'SNPS_CHKNV2',
  Chicken = 'SNAPS_CHICKEN',
  Pork = 'SNPS_PORK',
  Turkey = 'SNAPS_TURKEY',
  TurkeyV2 = 'SNPS_TKYV2',
}

export const CHICKEN_SNAPS_V2_REGULAR_NUTRITION_FACTS: NutritionFactsContent = {
  ingredients: 'Chicken, chicken liver, apple, sea salt.',
  ingredientsSub: 'Sea salt in trace amounts provides electrolytes dogs need.',
  guaranteedAnalysis: [
    { item: 'Crude Protein', percentage: 'min 25%' },
    { item: 'Crude Fat', percentage: 'min 23%' },
    { item: 'Crude Fiber', percentage: 'max 2.5%' },
    { item: 'Moisture', percentage: 'max 23%' },
    { item: 'Potassium', percentage: 'min .6%' },
    { item: 'Magnesium', percentage: 'min .04%' },
  ],
  calculatedCalorieContent: [
    'Metabolizable Energy: 4000 kcal/kg',
    '6 kcal/1 inch of treat (2g); approx. 6 inches/stick',
  ],
};

export const TURKEY_SNAPS_V2_REGULAR_NUTRITION_FACTS: NutritionFactsContent = {
  ingredients: 'Turkey, turkey liver, pear, sea salt.',
  ingredientsSub: 'Sea salt in trace amounts provides electrolytes dogs need.',
  guaranteedAnalysis: [
    { item: 'Crude Protein', percentage: 'min 23%' },
    { item: 'Crude Fat', percentage: 'min 15%' },
    { item: 'Crude Fiber', percentage: 'max 5%' },
    { item: 'Moisture', percentage: 'max 25%' },
    { item: 'Potassium', percentage: 'min .6%' },
    { item: 'Magnesium', percentage: 'min .04%' },
  ],
  calculatedCalorieContent: [
    'Metabolizable Energy: 3600 kcal/kg',
    '5 kcal/1 inch of treat',
  ],
};

export const PORK_SNAPS_REGULAR_NUTRITION_FACTS: NutritionFactsContent = {
  ingredients: 'Pork, pork liver, pear, sea salt.',
  ingredientsSub: 'Sea salt in trace amounts provides electrolytes dogs need.',
  guaranteedAnalysis: [
    { item: 'Crude Protein', percentage: 'min 19%' },
    { item: 'Crude Fat', percentage: 'min 20%' },
    { item: 'Crude Fiber', percentage: 'max 5%' },
    { item: 'Moisture', percentage: 'max 25%' },
    { item: 'Potassium', percentage: 'min .4%' },
    { item: 'Magnesium', percentage: 'min .03%' },
  ],
  calculatedCalorieContent: [
    'Metabolizable Energy: 4300 kcal/kg',
    '6 kcal/1 inch of treat',
  ],
};
