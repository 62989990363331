import { useEffect, useRef, useCallback } from 'react';

import { browserOnly } from '@farmersdog/utils';

interface ExitIntentTrackerProps {
  onExit: () => void;
}

const falsePositiveTimeout = 500;

export function ExitIntentTracker({ onExit }: ExitIntentTrackerProps) {
  const exitIntentTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleMouseOut = useCallback(
    (event: MouseEvent) => {
      // Trigger exit intent if the mouse leaves the top of the viewport
      if (event.clientY <= 0) {
        exitIntentTimeout.current = setTimeout(() => {
          onExit();
        }, falsePositiveTimeout);
      }
    },
    [onExit]
  );

  const handleMouseEnter = useCallback(() => {
    // Clear timeout if mouse re-enters the viewport within the buffer
    if (exitIntentTimeout.current) {
      clearTimeout(exitIntentTimeout.current);
      exitIntentTimeout.current = null;
    }
  }, [exitIntentTimeout]);

  useEffect(() => {
    browserOnly((_, document) => {
      document.body.addEventListener('mouseleave', handleMouseOut);
      document.body.addEventListener('mouseenter', handleMouseEnter);
    });

    return () => {
      browserOnly((_, document) => {
        document.body.removeEventListener('mouseleave', handleMouseOut);
        document.body.removeEventListener('mouseenter', handleMouseEnter);
      });

      if (exitIntentTimeout.current) {
        clearTimeout(exitIntentTimeout.current);
      }
    };
  }, [handleMouseOut, handleMouseEnter]);

  return null;
}
