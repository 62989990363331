import CnetMobilePng from './cnet-mobile.png';
import CnetMobileWebp from './cnet-mobile.webp';
import GoodHousekeepingMobilePng from './good-housekeeping-mobile.png';
import GoodHousekeepingMobileWebp from './good-housekeeping-mobile.webp';
import GoogleMobilePng from './google.png';
import GoogleMobileWebp from './google.webp';
import NewYorkMagazineMobilePng from './new-york-magazine-mobile.png';
import NewYorkMagazineMobileWebp from './new-york-magazine-mobile.webp';
import PopularScienceMobilePng from './popular-science-mobile.png';
import PopularScienceMobileWebp from './popular-science-mobile.webp';

export const mobileSources = {
  popularScience: [PopularScienceMobileWebp, PopularScienceMobilePng],
  goodHousekeeping: [GoodHousekeepingMobileWebp, GoodHousekeepingMobilePng],
  google: [GoogleMobileWebp, GoogleMobilePng],
  newYorkMagazine: [NewYorkMagazineMobileWebp, NewYorkMagazineMobilePng],
  cnet: [CnetMobileWebp, CnetMobilePng],
};
