// https://help.adanalytics.spotify.com/technical-pixel-docs
import { mountVendorWindowEvent } from '../../mountVendorWindowEvent';

import type { SpotifyEvent } from './types';

export interface MountSpotifyEventArgs {
  name: SpotifyEvent;
  properties?: Record<string, unknown>;
}

function _mountSpotifyEvent(
  w: Window,
  { name, properties }: MountSpotifyEventArgs
) {
  w.spdt?.(name, properties);
}

export const mountSpotifyEvent = mountVendorWindowEvent(
  'Spotify',
  _mountSpotifyEvent
);
