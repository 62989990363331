import fastdom from 'fastdom';
import { useCallback, useEffect, useState } from 'react';

export interface UseSlideshow {
  /** A function to programmatically change the currently active slide */
  goToSlide: (index: number, overrideScrollBehavior?: ScrollBehavior) => void;
  /** The currently active slide index */
  activeIndex: number;
}

interface Options {
  /**
   * A reference to the parent scrollable container element. This is the
   * scrolling box that will contain the slides.
   */
  scrollableEl: HTMLElement | null;
  /**
   * A function of a slide HTML element and the scrollable ancestor. Returns a
   * boolean to indicate if that element is the currently active slide.
   */
  getIsActiveSlide: (slideRect: DOMRect, scrollableRect: DOMRect) => boolean;
  /**
   * A function of the active slide index. This function is called when the
   * active slide changes from user interaction
   *
   * ```
   * function getIsActiveSlide(
   *   slideRect: DOMRect,
   *   scrollableRect: DOMRect
   * ) {
   *   if (slideRect.left > scrollableRect.width) {
   *     return true;
   *   }
   *
   *   return false;
   * }
   * ```
   */
  onSlideChange?: (activeIndex: number) => void;
  /** An optional active slide index when used in a controlled state pattern. */
  activeIndex?: number;
  /**
   * The scroll behavior to use when programmatically scrolling to a slide
   * element into view.
   */
  scrollBehavior: ScrollBehavior;
}

/**
 * The time since the last user interaction used for determining if scrolling
 * has ceased.
 */
const SCROLL_INTERVAL = 1000 / 15; // 15 FPS

/**
 * Add programmatic control for managing slide show state to a scrollable
 * content area.
 *
 * !Requires `polyfillSmoothScroll()` on the client for full browser support.
 *
 * @internal
 */
export function useSlideshow({
  scrollableEl,
  getIsActiveSlide,
  onSlideChange,
  activeIndex: activeIndexProp = 0,
  scrollBehavior,
}: Options): UseSlideshow {
  const [lastScroll, setLastScroll] = useState(Date.now());
  const [activeIndex, setCurrentSlide] = useState(0);

  /**
   * This effect ensures the initial position is correct when the scrollable element
   * is first rendered.
   */
  useEffect(() => {
    if (!scrollableEl) return;

    fastdom.mutate(() => {
      const slides = Array.from(scrollableEl.children);
      if (slides.length > 1) {
        const targetSlide = slides[activeIndexProp];
        if (!targetSlide) return;
        const targetRect = targetSlide.getBoundingClientRect();
        scrollableEl.scrollTo({ left: targetRect.left, behavior: 'instant' });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollableEl]);

  const getIsScrolling = useCallback((): boolean => {
    const now = Date.now();
    return Boolean(lastScroll) && now < lastScroll + SCROLL_INTERVAL;
  }, [lastScroll]);

  const handleSlideChange = useCallback(
    (nextIndex: number): void => {
      setCurrentSlide(nextIndex);

      if (onSlideChange) {
        onSlideChange(nextIndex);
      }
    },
    [onSlideChange]
  );

  const goToSlide = useCallback(
    (nextIndex: number, overrideScrollBehavior?: ScrollBehavior): void => {
      if (!scrollableEl) {
        return;
      }

      const slides = Array.from(scrollableEl.children);

      const nextSlideEl = slides[nextIndex];
      const activeSlideEl = slides[activeIndex];

      if (nextSlideEl && activeSlideEl && scrollableEl) {
        let scrollTop = 0;
        let scrollLeft = 0;

        fastdom.measure(() => {
          const nextSlideRects = nextSlideEl.getBoundingClientRect();
          const activeSlideRects = activeSlideEl.getBoundingClientRect();

          scrollTop = nextSlideRects.top - activeSlideRects.top;
          scrollLeft = nextSlideRects.left - activeSlideRects.left;
        });

        fastdom.mutate(() => {
          scrollableEl.scrollBy({
            top: scrollTop,
            left: scrollLeft,
            behavior: overrideScrollBehavior ?? scrollBehavior,
          });
        });
      }
    },
    [activeIndex, scrollableEl, scrollBehavior]
  );

  useEffect(() => {
    if (typeof activeIndexProp === 'undefined') {
      return;
    }

    if (activeIndexProp === activeIndex) {
      return;
    }

    if (!getIsScrolling()) {
      goToSlide(activeIndexProp);
    }
  }, [activeIndexProp, activeIndex, goToSlide, getIsScrolling]);

  useEffect(() => {
    if (!scrollableEl) {
      return;
    }

    const handleScroll = (): void => {
      fastdom.measure(() => {
        setLastScroll(Date.now());
        const scrollableRect = scrollableEl.getBoundingClientRect();
        const slides = Array.from(scrollableEl.children);
        const nextIndex = slides.findIndex(slideEl =>
          getIsActiveSlide(slideEl.getBoundingClientRect(), scrollableRect)
        );

        if (activeIndex !== nextIndex) {
          handleSlideChange(nextIndex);
        }
      });
    };

    scrollableEl.addEventListener('scroll', handleScroll);
    return (): void => scrollableEl.removeEventListener('scroll', handleScroll);
  }, [scrollableEl, handleSlideChange, getIsActiveSlide, activeIndex]);

  return {
    activeIndex,
    goToSlide,
  };
}
