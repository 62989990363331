import { Lightbox } from '@farmersdog/corgi';

import { useGlobalLightbox, LightboxId } from '../../../hooks';

import { AssetPreloader } from './AssetPreloader';
import { Comparison as ComparisonComponent } from './Comparison';

export interface CalculatingScreenContentProps {
  onCompleted: () => void;
}

export function CalculatingScreen() {
  const { rootProps, close, setSuppressed } = useGlobalLightbox({
    id: LightboxId.CalculatingScreen,
    onOpen: () => {
      setSuppressed(true);
    },
  });

  return (
    <>
      <AssetPreloader />
      <Lightbox withAnimation={false} {...rootProps}>
        <ComparisonComponent onCompleted={close} />
      </Lightbox>
    </>
  );
}
