import { FocusOn } from 'react-focus-on';

import classNames from 'classnames';
import styles from './Drawer.module.css';

interface DrawerProps {
  isOpen: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

export const Drawer = ({ isOpen, onClose, children }: DrawerProps) => {
  return (
    <FocusOn enabled={isOpen} focusLock scrollLock onEscapeKey={onClose}>
      <div
        data-testid="drawer-container"
        className={classNames(styles.container, { [styles.open]: isOpen })}
      >
        <div
          className={classNames(styles.overlay, { [styles.open]: isOpen })}
        />
        <div
          id="lifetime-benefits-drawer"
          aria-modal={true}
          aria-hidden={!isOpen}
          aria-labelledby="lifetime-benefits-drawer-title"
          className={classNames(styles.slidingPanel, {
            [styles.open]: isOpen,
          })}
        >
          {children}
        </div>
      </div>
    </FocusOn>
  );
};
