import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { PATH_HOME } from '@farmersdog/constants/paths';
import { Link } from '@farmersdog/corgi';
import { Progress, Logo } from '@farmersdog/corgi-x';

import { PreviousLinkNavigationLabel } from '../../../../hooks/usePreviousLinkNavigation';
import { BackButton } from '../../../shared/SignupHeader/BackButton';
import { useStaticStepData } from '../hooks/useStaticStepData';

import styles from './SmallNavigation.module.css';
import { Steps } from './Steps';

interface SmallNavigationArgs {
  previousSignupRoute: string;
}

export function SmallNavigation({ previousSignupRoute }: SmallNavigationArgs) {
  const stepData = useStaticStepData();
  const history = useHistory();

  const handleNavigatePrevious = useCallback(() => {
    history.push(previousSignupRoute);
  }, [history, previousSignupRoute]);

  return (
    <nav className={styles.container} data-testid="header-nav-mobile">
      <div className={styles.navigation}>
        <BackButton
          previousSignupRoute={previousSignupRoute}
          handleNavigatePrevious={handleNavigatePrevious}
          navigatePreviousLinkLabel={
            PreviousLinkNavigationLabel.previousSignupPage
          }
        />
        <Link
          to={PATH_HOME}
          className={styles.homeLink}
          aria-label={PreviousLinkNavigationLabel.homePage}
          data-testid="header-logo-mobile"
        >
          <Logo variant="product" size={50} />
        </Link>
        <Steps numSteps={stepData.length} />
      </div>
      <Progress
        role="status"
        className={styles.progress}
        title="Sign up progress"
        aria-label={`Step 3 of ${stepData.length}`}
        steps={stepData.length}
        activeStep={3}
        stepProgress={0}
      />
    </nav>
  );
}
