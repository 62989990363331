import { useRef, useState } from 'react';
import { Grid, Text } from '@farmersdog/corgi-x';

import { Helmet } from 'react-helmet-async';
import { Navigation } from 'src/components/Navigation';
import { Drawer } from 'src/components/Drawer';

import {
  BetterForThem,
  BowlPackSection,
  DidYouKnowSection,
  DrawerContent,
  FAQ,
  HomepageRedesignHero,
  LifetimeOfBenefits,
  Marquee,
  PressBarSection,
  StoriesFromSection,
  VetsKnowBest,
  BenefitCardVariant,
} from './components';

import schema from '../Home/schema.json';

import styles from './HomepageRedesign.module.css';

export const HomepageRedesign = () => {
  const ctaReveal = useRef(null);
  const [drawerDataKey, setDrawerDataKey] = useState<BenefitCardVariant>(
    BenefitCardVariant.WeightManagement
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = (key: BenefitCardVariant) => {
    setDrawerDataKey(key);
    setIsDrawerOpen(true);
  };

  const onCloseDrawer = () => setIsDrawerOpen(false);

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
      <Navigation ctaTrigger={ctaReveal} />
      <HomepageRedesignHero />
      <Marquee>
        <Text vSpacing="none" as="p" bold variant="heading-16" color="kale-2">
          Order now for delivery as early as{' '}
          <Text bold as="span" color="kale-3">
            Feb 3
          </Text>
        </Text>
      </Marquee>
      <div ref={ctaReveal}>
        <Grid
          className={styles.grid}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <BowlPackSection />
          <DidYouKnowSection />
          <LifetimeOfBenefits
            onTileClick={handleDrawerOpen}
            isOpen={isDrawerOpen}
          />
          <PressBarSection />
          <BetterForThem />
          <StoriesFromSection />
          <VetsKnowBest />
          <FAQ />
        </Grid>
      </div>
      <Drawer isOpen={isDrawerOpen} onClose={onCloseDrawer}>
        <DrawerContent contentKey={drawerDataKey} onClose={onCloseDrawer} />
      </Drawer>
    </>
  );
};
