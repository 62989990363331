import { Redirect, Route, Switch } from 'react-router-dom';

import { paths } from '@farmersdog/constants';

import { useGetLead } from '../../graphql/queries';
import { SignupTreatsPage } from '../SignupTreatsPage';

import { RecipesPageRoute } from './components/RecipesPageRoute';
import { RouteWithOptionalEmail } from './components/RouteWithOptionalEmail';
import { useAvailableLeadJourneyRoutes } from './useAvailableLeadJourneyRoutes';
import { getRecipesRoutes } from './utils/getRecipesRoutes';
import { getTreatsRoutes } from './utils/getTreatsRoutes';

import type { OnTosaCompleted } from '../../exports';
import type { UseFeatureHook } from '../../types';

interface NewTosaRouterProps {
  useFeature: UseFeatureHook;
  onTosaCompleted: OnTosaCompleted;
}

export function NewTosaRouter({
  useFeature,
  onTosaCompleted,
}: NewTosaRouterProps) {
  const routes = useAvailableLeadJourneyRoutes({
    useFeature,
  });

  const lead = useGetLead().data?.lead;

  if (!routes) {
    // This is just to keep users inside of NewTOSARouter until the routes have
    // had a chance to load.
    //
    // Once we have completely switched over to NewTOSARouter, we can update
    // this to just return null.
    return <Route />;
  }

  const recipesRoutes = getRecipesRoutes(routes);
  const treatsRoutes = getTreatsRoutes(routes);

  const hasCheckoutRouteAvailable = routes.includes(paths.PATH_SIGNUP_CHECKOUT);

  return (
    <Switch>
      {lead && (
        <>
          {recipesRoutes && (
            <RouteWithOptionalEmail path={paths.PATH_SIGNUP_RECIPES}>
              <RecipesPageRoute
                lead={lead}
                useFeature={useFeature}
                onTosaCompleted={onTosaCompleted}
                recipesRoutes={recipesRoutes.recipesRoutes}
                routeBeforeRecipes={recipesRoutes.previousRoute}
                routeAfterRecipes={recipesRoutes.nextRoute}
              />
            </RouteWithOptionalEmail>
          )}

          {treatsRoutes ? (
            <RouteWithOptionalEmail path={treatsRoutes.treatsRoute}>
              <SignupTreatsPage
                lead={lead}
                previousRoute={treatsRoutes.previousRoute}
                nextRoute={treatsRoutes.nextRoute}
              />
            </RouteWithOptionalEmail>
          ) : (
            // TODO: Remove this when we move the recipes page out of the
            // blueprint.
            //
            // This is just to make sure the push to the treats page at the end
            // of the blueprint will still send users to the checkout page if
            // the treats page is turned off for them.
            hasCheckoutRouteAvailable && (
              <Route path={paths.PATH_SIGNUP_TREATS}>
                <Redirect to={paths.PATH_SIGNUP_CHECKOUT} />
              </Route>
            )
          )}
        </>
      )}

      <Route>
        <Redirect to={paths.PATH_SIGNUP} />
      </Route>
    </Switch>
  );
}
