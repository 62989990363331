import { pixelRevenue } from '../constants';
import { trackSpotifyEvent } from './trackSpotifyEvent';
import { SpotifyEvent } from './types';

/**
 * track a Spotify purchase event
 */
export function trackSpotifyPurchase(orderId: string): void {
  const staticProperties = {
    is_new_customer: true,
    value: pixelRevenue,
    currency: 'USD',
  };

  trackSpotifyEvent({
    name: SpotifyEvent.Purchase,
    properties: {
      ...staticProperties,
      order_id: orderId,
    },
  });
}
