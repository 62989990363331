import { MIN_HEIGHT_FOR_DESKTOP_NAV } from '@/account/app/components/AppPage/constants';
import { useMediaQueryMatch } from '@/account/app/hooks/useMediaQueryMatch';

export function useDisplayDesktopNav(): boolean {
  return useMediaQueryMatch<boolean>([
    {
      mediaQuery: `(min-height: ${MIN_HEIGHT_FOR_DESKTOP_NAV}px)`,
      value: true,
    },
  ]);
}
