import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { ExtendedContentReveal, Quote } from '../../components';

import { ITEM_VISIBLE_TIME_MS } from './config';
import styles from './PressBarItem.module.css';

export const PressBarItem = ({
  quote,
  children,
}: {
  quote?: string;
  children?: ReactNode;
}) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setVisible(false);
    }, ITEM_VISIBLE_TIME_MS);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <ExtendedContentReveal
      in={visible}
      inAnimation="fade"
      outAnimation="fade"
      overrideTransitionDuration={{ in: 1000, out: 1000 }}
    >
      <div className={styles.item}>
        {children}
        {quote && <Quote text={quote} />}
      </div>
    </ExtendedContentReveal>
  );
};
