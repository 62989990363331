import { PATH_SIGNUP, PATH_WHY_FRESH } from '@farmersdog/constants/paths';
import { Text, Grid, GridItem, Nowrap, Link } from '@farmersdog/corgi-x';
import styles from './styles.module.css';

export enum ExitIntentOption {
  PricingInformation = 'Need more pricing information',
  WhereDoIBuy = 'Where do I buy The Farmer’s Dog?',
  Recipes = 'What are the recipes?',
  TalkToAHuman = 'I want to talk to a human',
  ClickedByAccident = 'Clicked by accident',
  Other = 'Other (free response)',
}

export const options = {
  [ExitIntentOption.PricingInformation]: {
    content: (
      <Grid data-testid="content" rowGap="lg" vSpacing="md">
        <GridItem>
          <Text variant="article-16">
            <Nowrap>
              Plans start at about $2/day, and delivery options range from 1
            </Nowrap>{' '}
            week to 2 months of food and include free shipping.
          </Text>
        </GridItem>
        <GridItem>
          <Text variant="article-16">
            Your exact{' '}
            <Link to={PATH_SIGNUP}>
              <u>plan and price</u>
            </Link>{' '}
            depends on your dog(s) and their unique needs, factoring in age,
            weight, activity, recipe
            <br />
            choice, and more.
          </Text>
        </GridItem>
      </Grid>
    ),
    ctaPath: PATH_SIGNUP,
    ctaCopy: 'Get Plans and Pricing',
    ctaType: 'link',
  },
  [ExitIntentOption.WhereDoIBuy]: {
    content: (
      <Grid data-testid="content" vSpacing="xl">
        <GridItem>
          <Text variant="article-16">
            <Nowrap>The Farmer’s Dog ships right to your door.</Nowrap>
            <br />
            <Nowrap>Start your plan today and receive deliveries on</Nowrap>
            <br />a schedule that works for you.
          </Text>
        </GridItem>
      </Grid>
    ),
    ctaPath: PATH_SIGNUP,
    ctaCopy: 'Get Started',
    ctaType: 'link',
  },
  [ExitIntentOption.Recipes]: {
    content: (
      <Grid data-testid="content" rowGap="lg">
        <GridItem>
          <Text variant="article-16">
            <Nowrap>
              All of our human-grade recipes are formulated by our team of
            </Nowrap>
            <br />
            board-certified veterinary nutritionists using real meat and
            vegetables, and are always complete and balanced.
          </Text>
        </GridItem>
        <GridItem>
          <Text variant="article-16">
            You can find the recipes we recommend for your dog
            <br />
            by starting to build your plan today.
          </Text>
        </GridItem>
      </Grid>
    ),
    ctaPath: PATH_SIGNUP,
    ctaCopy: 'Build Your Plan',
    ctaType: 'link',
  },
  [ExitIntentOption.TalkToAHuman]: {
    content: (
      <Grid data-testid="content" vSpacing="xl">
        <GridItem>
          <Text variant="article-16">
            Our customer service team is available 24/7 to
            <br />
            help you and your pup. You can call or text us
            <br />
            now at <b>(646) 780-7957</b> or email us at
            <br />
            <Text as="span" underline color="carrot-3">
              care@thefarmersdog.com
            </Text>
          </Text>
        </GridItem>
      </Grid>
    ),
    ctaPath: 'mailto:care@thefarmersdog.com',
    ctaCopy: 'Contact Us',
    ctaType: 'anchor',
  },
  [ExitIntentOption.ClickedByAccident]: {
    content: (
      <Grid
        data-testid="content"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        rowGap="md"
        vSpacing="lg"
        className={styles.accident}
      >
        <GridItem>
          <Text variant="article-16" bottomSpacing="lg">
            Thanks for letting us know!
          </Text>
        </GridItem>
        <GridItem>
          <Text variant="article-16">
            If you’d like to get to know us,
            <br />
            you can learn more below.
          </Text>
        </GridItem>
      </Grid>
    ),
    ctaPath: PATH_WHY_FRESH,
    ctaCopy: 'Our Story',
    ctaType: 'link',
  },
  [ExitIntentOption.Other]: {
    content: (
      <Grid data-testid="content" vSpacing="lg">
        <GridItem>
          <Text variant="article-16">
            Thanks for the feedback! In the meantime, if
            <br />
            you’d like to get in touch our customer service
            <br />
            team is available 24/7 to help you and your pup.
            <br />
            You can call or text us now at <b>(646) 780-7957</b> or
            <br />
            email us at{' '}
            <Text as="span" underline color="carrot-3">
              care@thefarmersdog.com.
            </Text>
          </Text>
        </GridItem>
      </Grid>
    ),
    ctaPath: PATH_SIGNUP,
    ctaCopy: 'Get Plans and Pricing',
    ctaType: 'link',
  },
} as const;

export type ExitIntentOptionValue = (typeof options)[ExitIntentOption];
