import FreeDeliveryLottie from './assets/free-delivery.json';
import PersonalizedPlanLottie from './assets/personalized-plan.json';
import SupportLottie from './assets/support.json';

import UnderlineDrawing from './assets/underline.svg';

import styles from './BetterForThem.module.css';

interface BetterForThemItem {
  id: number;
  animation: Record<string, unknown>;
  title: React.ReactNode;
}

export const betterForThemItems: BetterForThemItem[] = [
  {
    id: 1,
    animation: PersonalizedPlanLottie,

    title: (
      <>
        A personalized plan <br className={styles.mobileBreakline} />
        built <br className={styles.desktopBreakline} />
        for your dog
      </>
    ),
  },
  {
    id: 2,
    animation: FreeDeliveryLottie,
    title: (
      <>
        <span className={styles.underlineContainer}>
          Free delivery
          <UnderlineDrawing className={styles.underline} />
        </span>{' '}
        on a schedule
        <br /> that works for you
      </>
    ),
  },
  {
    id: 3,
    animation: SupportLottie,
    title: (
      <>
        With real people standing <br /> by to chat 24/7
      </>
    ),
  },
];
