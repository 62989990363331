/**
 * Note: This is an extension of the corgi-x component `ContentReveal` but with the ability to override the timeout duration config.
 */
import type { ReactElement, ReactNode } from 'react';
import {
  Children,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Transition } from 'react-transition-group';

import { browserOnly } from '@farmersdog/utils/src/browserOnly';
import classNames from 'classnames';

import transitions from './transitions';

export type ContentRevealAnimations =
  | 'springUp'
  | 'springDown'
  | 'slideUp'
  | 'slideDown'
  | 'fade';

export interface ContentRevealProps {
  /** Transition in */
  in: boolean;
  /** Animation when the content is shown */
  inAnimation: ContentRevealAnimations;
  /** Delay to showing the content */
  inDelay?: number;
  /** Animation when the content is hidden */
  outAnimation?: ContentRevealAnimations;
  /** Delay to hiding the content */
  outDelay?: number;
  /** Overrides timeout config */
  overrideTransitionDuration?: {
    in?: number;
    out?: number;
  };
  children: ReactNode;
}

/**
 * Animates enter/leave transitions on children.
 *
 * The `ContentReveal` component is used to animate enter/leave transitions of
 * conditionally rendered elements.
 *
 * @see https://corgi-x.tfd.engineering/components/contentreveal
 */
export function ExtendedContentReveal({
  inAnimation,
  inDelay = 0,
  outAnimation,
  outDelay = 0,
  overrideTransitionDuration,
  children,
  ...props
}: ContentRevealProps) {
  const [inState, setInState] = useState<boolean>(false);
  const inTransition = transitions[inAnimation];
  const outTransition = outAnimation
    ? transitions[outAnimation]
    : { ...inTransition, transitions: inTransition.defaultOut };

  const stateTransitions: Record<string, string> = {
    entering: inTransition.transitions.entering,
    exiting: outTransition.transitions.exiting,
    ...transitions.base.transitions,
  };

  useEffect(() => {
    let delayTimeout: number | undefined;

    if (props.in !== inState) {
      delayTimeout = browserOnly(window =>
        window.setTimeout(
          () => {
            setInState(props.in);
            clearTimeout(delayTimeout);
            delayTimeout = undefined;
          },
          props.in ? inDelay : outDelay
        )
      );
    }

    return (): void => {
      clearTimeout(delayTimeout);
      delayTimeout = undefined;
    };
  }, [inDelay, inState, outDelay, props.in]);

  const nodeRef = useRef(null);

  return (
    <Transition
      timeout={{
        enter: overrideTransitionDuration?.in ?? inTransition.duration,
        exit: overrideTransitionDuration?.out ?? outTransition.duration,
      }}
      in={inState}
      nodeRef={nodeRef}
    >
      {(state): ReactNode => {
        const child = Children.only(children);

        if (!isValidElement<HTMLElement>(child)) {
          return child;
        }

        const className = classNames(
          child.props.className,
          stateTransitions.init,
          stateTransitions[state]
        );
        return cloneElement(child as ReactElement, {
          className,
          ref: nodeRef,
        });
      }}
    </Transition>
  );
}
