import { useMutation } from '@apollo/client';

import { loginMutation } from '@/account/app/components/Login/graphql';
import type {
  LoginMutation,
  LoginMutationVariables,
} from '@/account/app/components/Login/graphql';

import type { MutationHookOptions } from '@apollo/client';

export type UseLoginSubmit = (email: string, password: string) => void;

export function useLoginSubmit(
  options: MutationHookOptions<LoginMutation, LoginMutationVariables>
): UseLoginSubmit {
  const [login] = useMutation<LoginMutation, LoginMutationVariables>(
    loginMutation,
    options
  );

  const callLogin = (email: string, password: string) =>
    login({ variables: { input: { email, password } } });

  return callLogin;
}
