import cookie from 'js-cookie';

import { getCookieDomain } from '@farmersdog/utils';

export const SIGNUP_EXIT_INTENT_HOME_COOKIE = 'signup-exit-intent-homepage';
export const EXPIRES = 30;

const exitCookieByPage = {
  home: SIGNUP_EXIT_INTENT_HOME_COOKIE,
};

type exitCookieByPageKey = keyof typeof exitCookieByPage;

/**
 * Set a cookie to mark when the exit intent modal was last shown.
 */
export function setExitIntentCookieForPage(page: exitCookieByPageKey): void {
  if (typeof window === 'undefined') {
    return;
  }

  const cookieName = exitCookieByPage[page];
  cookie.set(cookieName, 'true', {
    expires: EXPIRES,
    domain: getCookieDomain(),
    path: '/',
  });
}

export function getExitIntentCookieForPage(page: exitCookieByPageKey) {
  return cookie.get(exitCookieByPage[page]);
}
