import { useCallback, useRef, useState } from 'react';

import {
  getExitIntentCookieForPage,
  setExitIntentCookieForPage,
} from '@farmersdog/lead-browser-storage';

import { useHomepageExitIntent } from '../../hooks/useHomepageExitIntent';

import { ExitIntentModal } from './ExitIntentModal';
import { ExitIntentTracker } from './ExitIntentTracker';
import { trackTriggerEvent } from './analytics';

export function ExitIntent() {
  const { shouldShowExitIntent } = useHomepageExitIntent();
  const [isShowingModal, setIsShowingModal] = useState(false);
  const didTriggerModal = useRef(false);

  const onCloseModal = useCallback(() => {
    setIsShowingModal(false);
  }, [setIsShowingModal]);

  const handleExitIntent = useCallback(() => {
    if (didTriggerModal.current) {
      return;
    }

    const exitIntentCookie = getExitIntentCookieForPage('home');
    const shouldShowModal = !exitIntentCookie && shouldShowExitIntent;

    if (shouldShowModal) {
      setExitIntentCookieForPage('home');
    }

    setIsShowingModal(shouldShowModal);
    trackTriggerEvent(shouldShowModal);
    didTriggerModal.current = true;
  }, [shouldShowExitIntent]);

  return (
    <>
      <ExitIntentTracker onExit={handleExitIntent} />
      {isShowingModal && <ExitIntentModal onClose={onCloseModal} />}
    </>
  );
}
