import { pixelRevenue } from '../../types';

import { mountSpotifyEvent } from './mountSpotifyEvent';
import { SpotifyEvent } from './types';

/**
 * Mount a Spotify lead event
 */
export function mountSpotifyLead(): void {
  const staticProperties = {
    category: 'Get Started Lead',
    value: pixelRevenue,
    currency: 'USD',
  };

  mountSpotifyEvent({
    name: SpotifyEvent.Lead,
    properties: staticProperties,
  });
}
