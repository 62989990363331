import { AC_HOMEPAGE_END_TO_END_REDESIGN } from 'src/abTesting/features';
import {
  useSsrFeature,
  HomepageEndToEndRedesignTreatments,
} from 'src/abTesting';
import { useCheckForHomepageRedesignTreatmentMismatch } from './useCheckForHomepageRedesignTreatmentMismatch';

export const validHomepageEndToEndRedesignTreatments: HomepageEndToEndRedesignTreatments[] =
  [
    HomepageEndToEndRedesignTreatments.CurrentHeroDog,
    HomepageEndToEndRedesignTreatments.OptimizedHero,
    HomepageEndToEndRedesignTreatments.CurrentFormat,
  ];

export interface UseShowHomePageE2ERedesignReturnType {
  shouldShowHomepageRedesign: boolean;
  treatment: HomepageEndToEndRedesignTreatments;
}

export const useShowHomepageRedesign =
  (): UseShowHomePageE2ERedesignReturnType => {
    const { treatment: serverTreatment } =
      useSsrFeature<HomepageEndToEndRedesignTreatments>(
        AC_HOMEPAGE_END_TO_END_REDESIGN
      );

    useCheckForHomepageRedesignTreatmentMismatch({ serverTreatment });

    const shouldShowHomepageRedesign = serverTreatment
      ? validHomepageEndToEndRedesignTreatments.includes(serverTreatment)
      : false;

    return {
      shouldShowHomepageRedesign,
      treatment: serverTreatment ?? HomepageEndToEndRedesignTreatments.Off,
    };
  };
