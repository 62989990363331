import { useLocation } from 'react-router';

import { PATH_SIGNUP_TREATS } from '@farmersdog/constants/paths';

import { FreshDiscountBanner } from '../SignupHeader/DiscountBanner/FreshDiscountBanner';

import { LargeNavigation } from './LargeNavigation';
import styles from './SignupHeader.module.css';
import { SmallNavigation } from './SmallNavigation';

interface SignUpHeaderNonTosaPageProps {
  previousSignupRoute: string;
}

export function SignUpHeaderNonTosaPage({
  previousSignupRoute,
}: SignUpHeaderNonTosaPageProps) {
  const location = useLocation();

  const shouldShowDiscountBanner = location.pathname !== PATH_SIGNUP_TREATS;

  return (
    <div className={styles.container}>
      {shouldShowDiscountBanner && <FreshDiscountBanner />}
      <div className={styles.navigation}>
        <div className={styles.large}>
          <LargeNavigation previousSignupRoute={previousSignupRoute} />
        </div>
        <div className={styles.small}>
          <SmallNavigation previousSignupRoute={previousSignupRoute} />
        </div>
      </div>
    </div>
  );
}
