import { track } from '@farmersdog/analytics';
import type { ExitIntentOption } from '../options';
import { eventNames } from './eventNames';

interface TrackSelectedEventProps {
  trigger: 'select' | 'close';
  freeResponseValue?: string;
  option: ExitIntentOption | null;
}
export function trackSelectedEvent({
  trigger,
  freeResponseValue,
  option,
}: TrackSelectedEventProps) {
  track(eventNames.homepage_exit_intent_selected, {
    trigger,
    free_response_value: freeResponseValue,
    choice_name: option,
  });
}
