import { Picture, Text } from '@farmersdog/corgi-x';
import { PressBarItem } from './PressBarItem';
import { mobileSources } from './assets';

export const pressBarItems = [
  {
    sources: mobileSources.popularScience,
    alt: 'Popular Science Logo',
    height: {
      mobile: 28,
      desktop: 48,
    },
    item: (
      <PressBarItem quote="The Farmer’s Dog is working to combat poor health with fresh, human-grade food" />
    ),
  },
  {
    sources: mobileSources.goodHousekeeping,
    alt: 'Good Housekeeping Logo',
    height: {
      mobile: 28,
      desktop: 48,
    },
    item: (
      <PressBarItem quote="Helps you zero in on everything you need to consider for your dog" />
    ),
  },
  {
    sources: mobileSources.google,
    alt: 'Google customer reviews five stars',
    height: {
      mobile: 28,
      desktop: 48,
    },
    item: (
      <PressBarItem>
        <Picture
          alt="Customer reviews"
          sources={mobileSources.google}
          style={{ height: 78 }}
        />
        <Text>Customer reviews</Text>
      </PressBarItem>
    ),
  },
  {
    sources: mobileSources.newYorkMagazine,
    alt: 'New York Magazine Logo',
    height: {
      mobile: 28,
      desktop: 48,
    },
    item: (
      <PressBarItem quote="Ongoing long-term feeding trials show that the nutritional value in its food exceeds AAFCO recommendations" />
    ),
  },
  {
    sources: mobileSources.cnet,
    alt: 'CNET Logo',
    height: {
      mobile: 20,
      desktop: 32,
    },
    item: (
      <PressBarItem quote="A personalized profile and diet for your dog based on breed, age, activity level, ideal weight" />
    ),
  },
];
