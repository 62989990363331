/* CH#49502: Used by RET and CVR */
import type { SelectProps } from '@farmersdog/corgi';
import { Select } from '@farmersdog/corgi';

import { useStatesByIsoCode } from '@/account/graphql/core/queries/useCountries';
import { DEFAULT_ISO_CODE } from '@/account/graphql/core/queries/useCountries/constants';
import type { State } from '@/account/graphql/core/types';

import type { ChangeEvent } from 'react';

interface StatesSelectProps
  extends Omit<SelectProps, 'value' | 'onChange' | 'placeholder' | 'ref'> {
  /** The selected state */
  value: State | null | undefined;
  /** Event handler fired when the select changes. */
  onChange: (state: State | null) => void;
  country?: string;
}

export function StatesSelect({
  value,
  country = DEFAULT_ISO_CODE,
  onChange,
  ...props
}: StatesSelectProps) {
  const states = useStatesByIsoCode(country, {
    onError: error => {
      throw error;
    },
  });

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === '') {
      onChange(null);
      return;
    }

    const stateId = Number(e.target.value);
    const nextState = states.find(state => state.id === stateId);
    if (nextState) {
      onChange(nextState);
    }
  };

  const options = states
    .map(state => ({ label: state.name, value: state.id }))
    .sort((a, b) => (a.label < b.label ? -1 : 1));

  return (
    <Select value={value ? value.id : ''} onChange={handleChange} {...props}>
      {[
        <option key={-1} value="" />,
        ...options.map((option, i) => (
          <option key={i} value={option.value}>
            {option.label}
          </option>
        )),
      ]}
    </Select>
  );
}
