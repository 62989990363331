import { track } from '@farmersdog/analytics';

import eventNames from './eventNames';

interface trackUpdatePaymentMethodClicked {
  path: string;
}

export function trackUpdatePaymentMethodClicked({
  path,
}: trackUpdatePaymentMethodClicked) {
  return track(eventNames.update_payment_method_clicked, {
    path,
  });
}
