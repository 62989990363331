import { Section, useViewport } from '@farmersdog/corgi-x';
import { PATH_WHY_FRESH } from '@farmersdog/constants/paths';
import { ExtendedButton, ExtendedText } from '../../components';

import SafetyFactsIcon from './assets/safety-facts-icon.svg';

import styles from './DidYouKnowSection.module.css';

export const DidYouKnowSection = () => {
  const isLarge = useViewport().lg;

  return (
    <div className={styles.wrapper}>
      <Section
        aria-labelledby="did-you-know-section"
        variant="card"
        className={styles.factCard}
      >
        {isLarge && <SafetyFactsIcon className={styles.icon} />}
        <ExtendedText
          as="h2"
          bold={isLarge}
          variant={isLarge ? 'spaced-16' : 'spaced-12'}
          topSpacing={{ xs: 'none', lg: 'md' }}
          color="Kale3"
          id="did-you-know-section"
          bottomSpacing="sm"
          className={styles.heading}
        >
          SAFETY FACTS
        </ExtendedText>
        <ExtendedText
          as="p"
          topSpacing={{
            xs: 'none',
            sm: 'md',
          }}
          variant={isLarge ? 'article-28' : 'article-24'}
          bottomSpacing="md"
        >
          Our food is human-grade, made to the same{' '}
          <br className={styles.desktopBreakline} />
          high safety standards as the food you eat.
        </ExtendedText>
        <ExtendedText
          as="p"
          variant={isLarge ? 'article-28' : 'article-24'}
          topSpacing="none"
          bottomSpacing={{ xs: 'md', lg: 'xl' }}
        >
          Kibble is feed-grade, with lower safety standards{' '}
          <br className={styles.desktopBreakline} />
          that allow the use of diseased meat.
        </ExtendedText>
        <ExtendedButton
          className={styles.button}
          variant="bordered-kale"
          type="link"
          to={PATH_WHY_FRESH}
        >
          Learn more
        </ExtendedButton>
      </Section>
    </div>
  );
};
