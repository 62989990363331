import { useState } from 'react';

import config from 'src/config';
import browserOnly from 'src/utils/browserOnly';

export interface MediaQueryMap<T> {
  mediaQuery: string;
  value: T;
}
interface BackwardsCompatibleMediaQueryList extends MediaQueryList {
  // avoid TypeError
  addListener: (
    listener:
      | ((this: MediaQueryList, ev: MediaQueryListEvent) => unknown)
      | null
  ) => void;
}

/**
 * Given an array of mappings of media queries to arbitrary values,
 * returns the corresponding value for the first media query in the
 * array that is matched in the window
 *
 * @param mediaQueryMappings - array of key-value pairs in the format
 *  [media query]:[arbitrary value]
 *
 * @returns - mapped value for first match in input array
 */

export function useMediaQueryMatch<T>(
  mediaQueryMappings: MediaQueryMap<T>[]
): T {
  const isProd = config('server.env') === 'production';

  const mediaQueryLists: BackwardsCompatibleMediaQueryList[] = isProd
    ? mediaQueryMappings.map(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        browserOnly((mediaQueryMap: MediaQueryMap<T>) =>
          window.matchMedia(mediaQueryMap.mediaQuery)
        )
      )
    : mediaQueryMappings.map((mediaQueryMap: MediaQueryMap<T>) =>
        window.matchMedia(mediaQueryMap.mediaQuery)
      );
  const values = mediaQueryMappings.map(mediaQueryMap => mediaQueryMap.value);

  const getFirstMatchingValue = () => {
    const index = mediaQueryLists.findIndex(mql => mql.matches);
    return values[index];
  };

  const [matchingValue, setMatchingValue] = useState(getFirstMatchingValue);

  const handler = () => setMatchingValue(getFirstMatchingValue);

  // addEventListener is not supported by Safari <14
  // https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList#Browser_compatibility
  const addMediaQueryListener = (mql: BackwardsCompatibleMediaQueryList) => {
    if (mql.addEventListener) {
      mql.addEventListener('change', handler);
    } else {
      mql.addListener(handler);
    }
  };

  mediaQueryLists.forEach(addMediaQueryListener);

  return matchingValue;
}
