import type { MountSpotifyEventArgs } from './mountSpotifyEvent';

export enum SpotifyEvent {
  PageView = 'view',
  Lead = 'lead',
  Purchase = 'purchase',
}

export type SpotifyTagMounter = (
  name: MountSpotifyEventArgs['name'],
  properties: MountSpotifyEventArgs['properties']
) => void;
