import { useState } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';
import { Modal } from '@farmersdog/corgi';

import { Slider } from 'src/components/Slider';
import Form from 'src/components/Form';

import { track, events } from 'src/analytics';

import useSignupRouter from 'src/pages/Signup//hooks/useSignupRouter';

import { useCorgiViewport } from 'src/screen';

import useGlobalLightbox from 'src/utils/useGlobalLightbox';
import parseQueryString from 'src/utils/parseQueryString';

import useAnalytics from './useAnalytics';
import {
  getUpdatedValue,
  getVegetablesInitialState,
  getAdditionalIngredientsInitialState,
} from './utils';

import styles from './ExitIntent.module.scss';

import { SLIDE_NAME as FIRST_SLIDE } from './RootDecision';
import useSlides from './useSlides';
import { getCurrentStepFromRoute } from '../../hooks/utils';
import { ExitIntentImages } from './ExitIntentImages';

export const EXIT_MODAL_ID = 'exitIntentModal';

function ExitIntentModal({ onSubmit, isTOSA }) {
  const location = useLocation();
  const { current } = useSignupRouter({ isTOSA });
  const initialIsOpen = parseQueryString(location.search, 'exit') !== undefined;

  const size = useCorgiViewport({
    xs: {},
    md: {
      width: 590,
      height: '0px',
    },
  });

  const [answers, setAnswers] = useState({
    rootDecision: '',
    otherRootDecision: '',
    maxPrice: '',
    newProteins: [],
    customNewProtein: '',
    avoidFishMeatBlendProteins: [],
    avoidOtherFishMeatBlendMeatProtein: '',
    avoidOtherFishMeatBlendFishProtein: '',
    avoidFishProteins: [],
    avoidOtherFishProtein: '',
    avoidMeatBlendProteins: [],
    avoidOtherMeatBlendProtein: '',
    vegetables: getVegetablesInitialState(),
    additionalIngredients: getAdditionalIngredientsInitialState(),
  });
  const [finalMessage, setFinalMessage] = useState('');

  const {
    renderSlides,
    previousSlides,
    currentSlide,
    currentSlideIndex,
    goToPrevSlide,
    updateSlides,
  } = useSlides();

  const signupStep = current && getCurrentStepFromRoute(current);

  const { trackAnswerSelected, trackAnswersSubmitted } = useAnalytics({
    answers,
    previousSlides,
    currentSlide,
    currentRoute: current,
  });

  const { close, rootProps } = useGlobalLightbox({
    id: EXIT_MODAL_ID,
    initialIsOpen,
    onOpen: () => track(events.exit_intent_shown, { signup_step: signupStep }),
  });

  if (!current) {
    return null;
  }

  const submit = () => {
    trackAnswersSubmitted();

    close();
    if (onSubmit) {
      onSubmit();
    }
  };

  const handleAnswerEvent = (e, options = { track: true }) => {
    const {
      target: { name, value },
    } = e;
    handleAnswerChange(name, value, options);
  };

  const handleAnswerChange = (field, value, options = { track: true }) => {
    const prevValue = answers[field];
    const newValue = getUpdatedValue(prevValue, value);

    if (options.track) {
      trackAnswerSelected(field, newValue);
    }

    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [field]: newValue,
    }));
  };

  return (
    <Modal className={classNames(styles.lightbox)} {...size} {...rootProps}>
      <Form
        onSubmit={submit}
        className={styles.content}
        name="ExitIntentModal"
        data-name="ExitIntentModal"
      >
        <Slider
          className={styles.slider}
          fixedHeight={false}
          currentSlide={currentSlideIndex}
        >
          {renderSlides.map(({ name, slide: SlideContent }) => (
            <Slider.Slide key={name} name={name}>
              <SlideContent
                {...answers}
                submit={submit}
                onAnswerChange={handleAnswerChange}
                onAnswerEvent={handleAnswerEvent}
                setCurrentSlide={updateSlides}
                goToPrevSlide={goToPrevSlide}
                finalMessage={finalMessage}
                setFinalMessage={setFinalMessage}
                isTOSA={isTOSA}
              />
            </Slider.Slide>
          ))}
        </Slider>
      </Form>
      {currentSlide === FIRST_SLIDE && <ExitIntentImages />}
    </Modal>
  );
}

ExitIntentModal.propTypes = {
  onSubmit: PropTypes.func,
  isTOSA: PropTypes.bool,
};

export default ExitIntentModal;
