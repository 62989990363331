import classNames from 'classnames';
import { Children } from 'react';

import type {
  CSSProperties,
  HTMLAttributes,
  PropsWithChildren,
  ReactElement,
} from 'react';

import './Slider.css';

type SliderProps = PropsWithChildren<{
  animate?: boolean;
  className?: string;
  currentSlide?: number | string;
  fixedHeight?: boolean;
  visibleSlides?: number;
}>;

/**
 * Render a set of slides that scrolls horizontally.
 *
 * ```jsx
 *  <Slider currentSlide="second">
 *    <Slider.Slide name="first">A first slide</Slider.Slide>
 *    <Slider.Slide name="second">A second slide (will be visible as default)</Slider.Slide>
 *  </Slider>
 * ```
 */
export function Slider({
  children,
  currentSlide = 0,
  fixedHeight = true,
  visibleSlides = 1,
  animate = true,
  className,
  ...props
}: SliderProps) {
  const slides = Children.toArray(children) as Array<
    ReactElement<SlideProps, typeof Slide>
  >;

  let slideIndex: number;
  if (typeof currentSlide === 'string') {
    slideIndex = slides.findIndex(slide => slide.props.name === currentSlide);
  } else {
    slideIndex = currentSlide;
  }

  const slidesCount = slides.length;
  const slideWidth = 100 / visibleSlides / slidesCount;

  const style = {
    width: `${(100 / visibleSlides) * slidesCount}%`,
    transform: `translateX(${-slideIndex * slideWidth * visibleSlides}%)`,
  };

  return (
    <div className={classNames('Slider', { animate }, className)} {...props}>
      <div className="Slider-slides" style={style}>
        {slides.map((slide, i) => {
          const visible = i >= slideIndex && i < slideIndex + visibleSlides;

          const slideStyle: Pick<CSSProperties, 'visibility' | 'maxHeight'> = {
            visibility: !visible ? 'hidden' : 'visible',
            maxHeight: !fixedHeight && !visible ? 1 : 'none',
          };

          return (
            <div
              key={i}
              className={'Slider-slide'}
              data-slide-name={slide.props.name}
              style={slideStyle}
            >
              {slide}
            </div>
          );
        })}
      </div>
    </div>
  );
}

type SlideProps = PropsWithChildren<
  HTMLAttributes<HTMLDivElement> & {
    className?: string;
    name?: string;
  }
>;

/**
 * Render a slide inside a slider.
 *
 * @deprecated - Just use any single html or react element
 */
function Slide({ children, className, ...props }: SlideProps) {
  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
}

Slider.Slide = Slide;
