import { track } from '@farmersdog/analytics';

import eventNames from 'src/analytics/eventNames';

export interface TrackSecondaryCtaClickedProps {
  step: string;
  destination: 'Inventory' | 'Reschedule' | 'TMF Reschedule';
}

export function trackSecondaryCtaClicked(props: TrackSecondaryCtaClickedProps) {
  return track(eventNames.secondary_cta_clicked, {
    step: props.step,
    destination: props.destination,
  });
}
