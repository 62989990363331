import { useMemo } from 'react';

import { useTosaToken } from '../../../hooks/useTosaToken';
import { useQuery } from '../../../services/apollo';
import leadQuery from '../lead.graphql';

import type { FetchLeadQuery, FetchLeadQueryVariables } from '../../types';

// TODO: Remove options argument once we remove cvr_new_lead_query_hook Split flag
export function useGetLead() {
  const tosaTokenData = useTosaToken().data;

  // Skip the call if we know we don't have a TOSA token, as we won't be able
  // to get a lead without one.
  const skip = !tosaTokenData?.tosaToken;

  const { data, error } = useQuery<FetchLeadQuery, FetchLeadQueryVariables>(
    leadQuery,
    { skip }
  );

  const dataToReturn = useMemo(() => {
    // If we're waiting for a TOSA token, we can return data unchanged so
    // that the caller knows we don't know if we have a lead yet (even if we're
    // skipping the lead query for now)
    if (!tosaTokenData) {
      return data;
    }

    // If we're skipping the lead query, or if the lead query errored out, we
    // can return `null` as `lead` so that the caller knows we're not waiting
    // for the lead query
    return skip || error ? { lead: null } : data;
  }, [tosaTokenData, data, error, skip]);

  return { data: dataToReturn };
}
