import type { Sources } from '../../data';

import WeightManagementMobileJpg from './weight-management-mobile.jpg';
import WeightManagementMobileWebp from './weight-management-mobile.webp';

import PickyEatingMobileJpg from './picky-eating-mobile.jpg';
import PickyEatingMobileWebp from './picky-eating-mobile.webp';

import SeniorCareMobileJpg from './senior-care-mobile.jpg';
import SeniorCareMobileWebp from './senior-care-mobile.webp';

import PuppyCareMobileJpg from './puppy-care-mobile.jpg';
import PuppyCareMobileWebp from './puppy-care-mobile.webp';

export const WeightManagementMobileSources: Sources = {
  webP: WeightManagementMobileWebp,
  jpg: WeightManagementMobileJpg,
};

export const PickyEatingMobileSources: Sources = {
  webP: PickyEatingMobileWebp,
  jpg: PickyEatingMobileJpg,
};

export const SeniorCareMobileSources: Sources = {
  webP: SeniorCareMobileWebp,
  jpg: SeniorCareMobileJpg,
};

export const PuppyCareMobileSources: Sources = {
  webP: PuppyCareMobileWebp,
  jpg: PuppyCareMobileJpg,
};
