import { paths } from '@farmersdog/constants';

interface TreatsRoutes {
  treatsRoute: string;
  previousRoute: string;
  nextRoute: string;
}

export function getTreatsRoutes(routes: string[]): TreatsRoutes | undefined {
  const treatsRouteIdx = routes.indexOf(paths.PATH_SIGNUP_TREATS);

  return treatsRouteIdx > -1
    ? {
        treatsRoute: routes[treatsRouteIdx],
        previousRoute: routes[treatsRouteIdx - 1],
        nextRoute: routes[treatsRouteIdx + 1],
      }
    : undefined;
}
