import { track } from '@farmersdog/analytics';

import eventNames from 'src/analytics/eventNames';

/**
 * Track Clicked Treats Banner
 */
export function trackTreatsBannerClicked() {
  track(eventNames.treats_banner_clicked);
}
