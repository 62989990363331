import { ContentReveal } from '@farmersdog/corgi-x';

import { BasicInput } from '../base';

import type { TOSAComponentInput, TOSALeafNode } from '../../../types';

export function FoodBrandInput(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, progress } = props;

  const shouldRevealInput = progress.getPreviousLeafComplete(node.__self__);

  return (
    <ContentReveal in={shouldRevealInput} inAnimation="fade">
      <div>
        <BasicInput {...props} />
      </div>
    </ContentReveal>
  );
}
