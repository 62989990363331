import { useState, useRef } from 'react';

import { signupRecoveryModalState } from '@farmersdog/lead-browser-storage';

import { SubmitButton } from '../../../components/shared/SubmitButton';
import { useThrowToErrorBoundary } from '../../../hooks/useThrowToErrorBoundary';
import {
  getPetNamesFromFormEntries,
  denormalizePhone,
  getCompletedData,
  restoreSignupFlow,
} from '../../../utils';
import { getStateWithoutPetData } from '../../../utils/getStateWithoutPetData';
import { RecoveryModal, HasAccountModal } from '../../modals';
import { BranchNode } from '../../schema/BranchNode';

import { useContactValidation, useRecovery } from './hooks';

import type { BranchNode as BranchNodeType } from '../../../blueprint/types';
import type { FormFieldsType, TOSAComponentInput } from '../../../types';

export function ContactInfoForm(props: TOSAComponentInput<BranchNodeType>) {
  const {
    formMethods,
    formSubmitRequest,
    node,
    schema,
    formValidationSchema,
    progress,
    experiments,
    clearSignupUser,
    setFormSteps,
  } = props;
  const formValues = formMethods.getValues();
  const { petNames } = getPetNamesFromFormEntries({
    formValues,
    currentNodeName: node.name,
  });

  const {
    handleContactValidationError,
    validateContactData,
    validateZipcodeLoading,
    doesPhoneNumberExistLoading,
  } = useContactValidation();

  const [pendingData, setPendingData] = useState<null | FormFieldsType>(null);

  const hasViewedRecoveryOption = signupRecoveryModalState.get();

  const handleHasViewedRecoveryOption = () => {
    signupRecoveryModalState.set('true');
  };

  const formRef = useRef<HTMLFormElement | null>(null);

  const {
    recoverLeadProgress,
    recoverLeadProgressState,
    recoveryModalControl,
    hasAccountModalControl,
    initiateRecovery,
    createLeadState,
  } = useRecovery({
    reset: formMethods.reset,
    setFormSteps,
    formValidationSchema,
    blueprint: schema,
    recoveryOnClose: () => handleHasViewedRecoveryOption(),
    experiments,
  });

  const throwToErrorBoundary = useThrowToErrorBoundary();

  const onSubmit = formMethods.handleSubmit(async (formData, e) => {
    const data = getCompletedData({
      data: formData,
      completedLeafNodes: progress.getCompletedLeafNodes(),
    });

    try {
      if (!data.zip || !data.email) {
        throw new Error('Email and zip are required');
      }

      const denormalizedPhone = data.phone
        ? denormalizePhone(data.phone)
        : null;
      data.phone = denormalizedPhone;
      const { zip, email, phone } = data;

      await validateContactData({ zip, email, phone, experiments });

      const { leadIsRecoverable, leadAlreadyHasAccount } =
        await initiateRecovery(email);

      if (leadIsRecoverable || leadAlreadyHasAccount) {
        setPendingData(data);

        // leadAlreadyHasAccount is signup-blocking
        if (leadAlreadyHasAccount) {
          hasAccountModalControl.open();
          return;
        }

        // can progress with or without lead recovery
        if (leadIsRecoverable && hasViewedRecoveryOption !== 'true') {
          recoveryModalControl.open();
          handleHasViewedRecoveryOption();
          return;
        } else if (leadIsRecoverable && hasViewedRecoveryOption === 'true') {
          setPendingData(null);
        }
      }

      if (props.onSubmit) {
        await props.onSubmit(data, e);
      }
    } catch (error) {
      const knownErrorInfo = handleContactValidationError(error);

      if (knownErrorInfo) {
        formMethods.setError(knownErrorInfo.field, {
          message: knownErrorInfo.errorMessage,
        });
      } else {
        throwToErrorBoundary(error);
      }
    }
  });

  const handleRecover = async () => {
    if (!pendingData) {
      return;
    }

    const recoveredFormSteps = await recoverLeadProgress(pendingData);
    if (recoveredFormSteps) {
      props.formNavigation.setNext({
        formSteps: recoveredFormSteps,
      });
    }

    if (props.onFormCompleted) {
      await props.onFormCompleted(props.node.name);
    }

    recoveryModalControl.close();
    setPendingData(null);
  };

  const handleCloseModal = () => {
    clearSignupUser();
    hasAccountModalControl.close();
  };

  const handleStartOver = async () => {
    if (!pendingData) {
      return;
    }

    const [cleanData] = getStateWithoutPetData({
      state: pendingData,
    });

    // We reset input state of next page before mounting the component of next page
    // to avoid rerendering that would cause old state to pop back through calling register() on next page input
    restoreSignupFlow({
      // for starting over w/o a token, the form state data has never been casted (e.g numPet is "2" instead of 2)
      data: formValidationSchema.cast(cleanData, {
        assert: 'ignore-optionality',
      }) as FormFieldsType,
      blueprint: schema,
      reset: formMethods.reset,
      resetOptions: { keepValues: false, keepDirty: true },
      setFormSteps,
      experiments,
    });

    if (props.onSubmit) {
      await props.onSubmit(cleanData);
    }

    if (props.onFormCompleted) {
      await props.onFormCompleted(props.node.name);
    }
    recoveryModalControl.close();
    setPendingData(null);
  };

  const formSubmitLoading =
    validateZipcodeLoading ||
    doesPhoneNumberExistLoading ||
    createLeadState.loading ||
    formSubmitRequest.loading;

  const recoveryModalLoading = Boolean(
    formSubmitRequest.loading ||
      recoverLeadProgressState.loading ||
      !pendingData
  );

  return (
    <div>
      <form id={props.node.name} onSubmit={onSubmit} ref={formRef}>
        <BranchNode {...props} />
        <SubmitButton loading={formSubmitLoading} {...props} />
      </form>
      <RecoveryModal
        // TODO - use getLead query to get existing pet names - https://app.shortcut.com/farmersdog/story/80455/tosa-composition-use-getlead-query-to-get-existing-pet-names-for-recovery-modal
        petNames={petNames}
        handleRecover={handleRecover}
        handleStartOver={handleStartOver}
        loading={recoveryModalLoading}
      />
      {pendingData?.email && (
        <HasAccountModal
          email={pendingData.email}
          handleCloseModal={handleCloseModal}
        />
      )}
    </div>
  );
}
