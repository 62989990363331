import classNames from 'classnames';
import { Grid, GridItem, Picture, Section } from '@farmersdog/corgi-x';
import { useInterval } from 'src/hooks/useInterval/useInterval';
import { useState } from 'react';

import { CHANGE_ITEM_INTERVAL_SECONDS } from './config';
import { pressBarItems } from './pressBarItems';
import styles from './PressBarDesktop.module.css';

export const PressBarDesktop = () => {
  const [activeItem, setActiveItem] = useState(0);

  useInterval({
    durationSeconds: CHANGE_ITEM_INTERVAL_SECONDS,
    onTimeLapsed: () =>
      setActiveItem(prev => (prev + 1) % pressBarItems.length),
  });

  const boundedIndex = activeItem % pressBarItems.length;

  return (
    <Section
      aria-label="Press Bar"
      vSpacing="xxl"
      hSpacing="lg"
      className={styles.wrapper}
    >
      <div className={styles.container}>
        <Grid className={styles.logos}>
          {pressBarItems.map((item, index) => (
            <GridItem md={2} key={item.alt}>
              <Picture
                sources={item.sources}
                alt={item.alt}
                style={{ maxHeight: item.height.desktop }}
                className={classNames(styles.image, {
                  [styles['active']]: activeItem === index,
                })}
              />
            </GridItem>
          ))}
        </Grid>
        <div className={styles.quote} key={activeItem}>
          {pressBarItems[boundedIndex].item}
        </div>
      </div>
    </Section>
  );
};
