import { useCallback } from 'react';

/**
 * Hook to wait until a scrollable element finishes scrolling.
 * @param containerEl - The scrollable container element.
 * @returns A function that resolves when the `scrollend` event fires or if no scrolling happens.
 */
export function useScrollEnd(containerEl: HTMLElement | null) {
  return useCallback(() => {
    if (!containerEl) return Promise.resolve();

    return new Promise<void>(resolve => {
      const initialScrollLeft = containerEl.scrollLeft;

      requestAnimationFrame(() => {
        // If scrollLeft hasn't changed, resolve immediately (no actual scrolling happened)
        if (containerEl.scrollLeft === initialScrollLeft) {
          resolve();
          return;
        }

        const handleScrollEnd = () => {
          resolve();
          containerEl.removeEventListener('scrollend', handleScrollEnd);
        };

        containerEl.addEventListener('scrollend', handleScrollEnd, {
          once: true,
        });
      });
    });
  }, [containerEl]);
}
