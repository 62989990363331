import { ProductLineType } from '../../graphql/types';

import type { FetchLeadQuery, PetInputForQuote } from '../../graphql/types';
import type { PetRecipes } from '../../types';
interface ConvertToPetInputsForQuoteArgs {
  pets: FetchLeadQuery['lead']['pets'];
  currentPetRecipeSelection?: string[] | undefined;
  currentPetName: string;
  availablePetRecipesMap: PetRecipes;
}

export function convertToPetInputsForQuoteNonBlueprint({
  pets,
  currentPetRecipeSelection,
  currentPetName,
  availablePetRecipesMap,
}: ConvertToPetInputsForQuoteArgs): Omit<
  PetInputForQuote,
  'requestedCalories'
>[] {
  return pets.map(currentPet => {
    const petName = currentPet.name;

    // for the non-current pets getting quoted, we want to use the selection if they have one.
    const storedPetSelection = currentPet.selection?.fresh?.options.recipes;
    const recommendation = availablePetRecipesMap[petName]
      .filter(recipe => recipe.recommended)
      .map(recipe => recipe.name);

    const maybeValidSelection =
      petName === currentPetName
        ? currentPetRecipeSelection
        : storedPetSelection;

    const selection =
      maybeValidSelection && maybeValidSelection.length > 0
        ? maybeValidSelection
        : recommendation;

    return {
      name: petName,
      selection: {
        diy: null,
        fresh: {
          productLine: ProductLineType.Fresh,
          options: { recipes: selection },
        },
      },
    };
  });
}
