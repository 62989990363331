import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useSwipeable } from 'react-swipeable';
import { CHARCOAL_0, CHARCOAL_1, KALE_3 } from '@farmersdog/corgi/constants';

import { useViewport } from 'src/screen';

import Flex from 'src/components/_depreciated/Flex';

import { Slider } from 'src/components/Slider';
import CircleButton from 'src/components/ReviewsSlider/CircleButton';

import ArrowIcon from 'src/assets/icons/arrow-down-open.svg';

import deviceType from 'src/utils/deviceType';
import getSlides from './getSlides';

import './ReviewsSlider.scss';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

const DOT_GROUP_LENGTH = 12;

/**
 * Get the proper dot group length.
 *
 * @param {Number} totalSlides the total number of slides
 * @param {Number} currentSlide the current slide
 * @param {Number} scrollBy the amount of slides to scroll by
 * @param {Number} visibleSlides the amount of slides that are visible
 */
function getDotGroup(totalSlides, currentSlide, scrollBy, visibleSlides) {
  const remainder = (totalSlides / scrollBy) % DOT_GROUP_LENGTH;
  const maxDots = Math.min(totalSlides, DOT_GROUP_LENGTH);

  if (remainder === 0) {
    return maxDots;
  }

  if ((totalSlides - currentSlide) / scrollBy <= remainder) {
    return visibleSlides > 1 ? remainder - 1 : remainder;
  }

  return maxDots;
}

/**
 * @deprecated See CH#49502
 */
function ReviewsSlider({
  content = ['testimonial', 'instagram'],
  className = '',
  ...props
}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const visibleSlides = useViewport({ xs: 1, md: 2, lg: 4 });
  const scrollBy = useViewport({ xs: 1, lg: 2 });
  const slides = getSlides(content);

  const { isAccessibilityThemeOn } = useAccessibilityThemeContext();
  const dotSelectedColor = isAccessibilityThemeOn ? KALE_3 : CHARCOAL_1;
  const dotDeselectedColor = isAccessibilityThemeOn
    ? 'transparent'
    : CHARCOAL_0;

  const handleNextSlide = () =>
    setCurrentSlide(
      Math.min(currentSlide + scrollBy, slides.length - visibleSlides)
    );

  const handlePreviousSlide = () =>
    setCurrentSlide(Math.max(0, currentSlide - scrollBy));

  const handleSwipes = useSwipeable({
    onSwipedRight: handlePreviousSlide,
    onSwipedLeft: handleNextSlide,
    preventScrollOnSwipe: true,
  });

  if (!Array.isArray(slides) || slides.length < 1) {
    return null;
  }

  const dotGroup = getDotGroup(
    slides.length,
    currentSlide,
    scrollBy,
    visibleSlides
  );

  const showTouchInterface =
    deviceType.platform !== 'desktop' || deviceType.touchable;

  return (
    <section
      className={classNames('ReviewsSlider', className)}
      {...handleSwipes}
      {...props}
    >
      <Slider
        fixedHeight
        currentSlide={currentSlide}
        visibleSlides={visibleSlides}
      >
        {slides.map((slide, i) => {
          const { component: SlideContent, data } = slide;

          return (
            <Slider.Slide key={`reviews-${i}`}>
              <SlideContent {...data} />
            </Slider.Slide>
          );
        })}
      </Slider>
      {!showTouchInterface && (
        <Flex className="ReviewsSlider-controls">
          {currentSlide > 0 && (
            <Flex
              className="ReviewsSlider-buttonlanding"
              alignItems="center"
              style={{ marginRight: 'auto' }}
              onClick={handlePreviousSlide}
            >
              <CircleButton
                onClick={handlePreviousSlide}
                style={{ marginRight: 'auto', width: 60, height: 60 }}
                aria-label="Previous"
              >
                <ArrowIcon
                  style={{
                    transform: 'rotate(90deg) translateY(1px)',
                    width: 25,
                    height: 25,
                  }}
                />
              </CircleButton>
            </Flex>
          )}
          {currentSlide < slides.length - visibleSlides && (
            <Flex
              className="ReviewsSlider-buttonlanding"
              alignItems="center"
              style={{ marginLeft: 'auto' }}
              onClick={handleNextSlide}
            >
              <CircleButton
                onClick={handleNextSlide}
                style={{ marginLeft: 'auto', width: 60, height: 60 }}
                aria-label="Next"
              >
                <ArrowIcon
                  style={{
                    transform: 'rotate(-90deg) translateY(1px)',
                    width: 25,
                    height: 25,
                  }}
                />
              </CircleButton>
            </Flex>
          )}
        </Flex>
      )}
      {showTouchInterface && (
        <Flex justifyContent="center" style={{ marginTop: '1rem' }}>
          {[...new Array(Math.round(dotGroup))].map((_, i) => (
            <div
              key={`index-dot-${i}`}
              style={{
                width: isAccessibilityThemeOn ? 12 : 6,
                height: isAccessibilityThemeOn ? 12 : 6,
                margin: '0 3px',
                background:
                  (currentSlide / scrollBy) % DOT_GROUP_LENGTH === i
                    ? dotSelectedColor
                    : dotDeselectedColor,
                borderRadius: '100%',
                border: isAccessibilityThemeOn ? '1px solid #173B33' : 'none',
              }}
            />
          ))}
        </Flex>
      )}
    </section>
  );
}

ReviewsSlider.propTypes = {
  className: PropTypes.string,
  /**
   * Array of content types to show.
   * Since data is loaded on demand do not define this prop inline.
   * Otherwise you will run the loading function unnecessarily.
   */
  content: PropTypes.arrayOf(PropTypes.oneOf(['testimonial', 'instagram'])),
};

export default ReviewsSlider;
