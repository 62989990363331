import { useClient } from '../../services/apollo';
import fullLeadViewFragment from '../fragments/fullLeadView.graphql';

import type { LeadView } from '../types';

type TypeName = NonNullable<LeadView['__typename']>;
const LeadViewTypeName: TypeName = 'LeadView';

export function useLazyLeadView() {
  const client = useClient();

  return (): LeadView | null => {
    return client.readFragment<LeadView>({
      fragment: fullLeadViewFragment,
      fragmentName: 'FullLeadView',
      id: getLeadCacheKey(),
    });
  };
}

export function getLeadCacheKey() {
  return `${LeadViewTypeName}:{}`;
}
