import { Picture } from '@farmersdog/corgi-x';
import { useInterval } from 'src/hooks/useInterval/useInterval';
import { ExtendedGallery } from '../../components';

import { CHANGE_ITEM_INTERVAL_SECONDS } from './config';
import { pressBarItems } from './pressBarItems';
import styles from './PressBarMobile.module.css';
import { useState } from 'react';

export const PressBarMobile = () => {
  const [activeItem, setActiveItem] = useState(0);

  useInterval({
    durationSeconds: CHANGE_ITEM_INTERVAL_SECONDS,
    onTimeLapsed: () => setActiveItem(prev => prev + 1),
  });

  const boundedIndex = activeItem % pressBarItems.length;

  return (
    <ExtendedGallery
      activeIndex={activeItem}
      onSlideChange={setActiveItem}
      id="press-bar"
      label="Press Bar"
      withLoop
      className={styles.container}
      slideClassname={styles.slide}
      grayscaleInactiveItems
      disableUserScrolling
      disableScaleAnimation
      footnote={pressBarItems[boundedIndex].item} // Renders items
    >
      {/* Render thumbnails: */}
      {pressBarItems.map(item => (
        <Picture
          key={item.alt}
          sources={item.sources}
          alt={item.alt}
          style={{ height: item.height.mobile }}
          className={styles.slideImage}
        />
      ))}
    </ExtendedGallery>
  );
};
