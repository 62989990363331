import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  type PropsWithChildren,
} from 'react';

import { tosaToken as tosaTokenStorage } from '@farmersdog/lead-browser-storage';

import { useTosaTokenFromUrlEmail } from './useTosaTokenFromUrlEmail';

interface TosaTokenContextValue {
  // Like an Apollo interface, `data` will be undefined until we know what the
  // tosaToken is (or whether or not we can get one at all).
  data?: { tosaToken: string | null };
}

const TosaTokenContext = createContext<TosaTokenContextValue>({
  data: undefined,
});

export function TosaTokenProvider({ children }: PropsWithChildren) {
  const { data: tosaTokenFromUrlEmailData } = useTosaTokenFromUrlEmail();

  // Don't set from storage if we're waiting for a token from an email in the
  // the url
  const initialTosaToken = !tosaTokenFromUrlEmailData
    ? undefined
    : {
        tosaToken:
          tosaTokenFromUrlEmailData.tosaToken ?? tosaTokenStorage.get(),
      };
  const [tosaTokenState, setTosaTokenState] = useState(initialTosaToken);

  const setTosaToken = useCallback((newTosaToken: string | null) => {
    if (newTosaToken) {
      tosaTokenStorage.set(newTosaToken);
    } else {
      tosaTokenStorage.remove();
    }

    setTosaTokenState({ tosaToken: newTosaToken });
  }, []);

  // In case the token stored in localStorage changes for some unforeseen
  // reason, we will be able to react to that change.
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Don't set from storage if we're waiting for a token from an email in
      // the url
      if (!tosaTokenFromUrlEmailData) {
        return;
      }

      const tosaTokenFromStorage = tosaTokenStorage.get();
      if (tosaTokenFromStorage !== tosaTokenState?.tosaToken) {
        setTosaTokenState({ tosaToken: tosaTokenFromStorage });
      }
    }, 100);

    return () => {
      clearInterval(intervalId);
    };
  }, [tosaTokenState, setTosaTokenState, tosaTokenFromUrlEmailData]);

  // Set the token if we get one from an email recovery.
  useEffect(() => {
    if (tosaTokenFromUrlEmailData?.tosaToken) {
      setTosaToken(tosaTokenFromUrlEmailData.tosaToken);
    }
  }, [tosaTokenFromUrlEmailData?.tosaToken, setTosaToken]);

  return (
    <TosaTokenContext.Provider value={{ data: tosaTokenState }}>
      {children}
    </TosaTokenContext.Provider>
  );
}

export function useTosaToken() {
  return useContext(TosaTokenContext);
}
