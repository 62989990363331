import {
  ACQ_HOMEPAGE_PRESS_BAR_LOWER,
  AC_HOMEPAGE_END_TO_END_REDESIGN,
  CVR_HOMEPAGE_HERO_IMAGE,
} from 'src/abTesting/features';
import type { AnonymousExperimentFlag } from './flags';
import { HEALTH_VET_OPTIMIZATION_HOMEPAGE } from './flags';

export const anonymousExperiments: AnonymousExperimentFlag[] = [
  CVR_HOMEPAGE_HERO_IMAGE,
  HEALTH_VET_OPTIMIZATION_HOMEPAGE,
  ACQ_HOMEPAGE_PRESS_BAR_LOWER,
  AC_HOMEPAGE_END_TO_END_REDESIGN,
];
