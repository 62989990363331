import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router';

import { useToast } from '@farmersdog/corgi';
import { ContentLoader } from '@farmersdog/corgi-x';
import { petMixingPlanRatiosState } from '@farmersdog/lead-browser-storage';

import {
  trackRecipesClickBackToRecommendedPlan,
  trackRecipesClickContinueWithRecommendation,
  trackRecipesClickSelectYourOwnPlan,
} from '../../analytics';
import { standardBlueprintVersion } from '../../blueprint';
import { useRegisterLead, useUpdateLead } from '../../graphql/mutations';
import {
  type FetchLeadQuery,
  type FetchQuoteQuery,
  type PetProductFreshSelectionOptionsInput,
} from '../../graphql/types';
import {
  useSurpriseHigherDiscountExperiment,
  useThrowToErrorBoundary,
} from '../../hooks';
import { useHandleRecipesCompleted } from '../../hooks/useHandleRecipesCompleted';
import { useSetLpfProductEligibility } from '../../hooks/useSetLpfProductEligibility';
import { scroller } from '../../utils';
import { TosaLayout } from '../layout';
import { MultiRecipeModal } from '../RecipeModal/MultiRecipeModal';
import { SignUpHeaderNonTosaPage } from '../shared/SignUpHeaderNonTosaPage';

import {
  useFetchPetsAvailableRecipes,
  usePetNamesAndRecommendedCalories,
} from './hooks';
import { useSurpriseHigherDiscountRecipesPage } from './hooks/useSurpriseHigherDiscountRecipesPage';
import styles from './RecipesPage.module.css';
import { MAX_SELECTED_RECIPES } from './RecipesSelection/RecipesSelection';
import { RecipesSelectionV2 } from './RecipesSelection/RecipesSelectionV2';
import { RecommendedPlanV2 } from './RecommendedPlan/RecommendedPlanV2';
import { checkLpfRecipeStatus } from './utils/checkLpfRecipeStatus';
import {
  createLeadInput,
  createUpdateLeadPetsPayload,
} from './utils/createLeadInput';
import { getSelectedAvailableRecipesV2 } from './utils/getSelectedAvailableRecipesV2';

import type { OnTosaCompleted } from '../../hooks';
import type { UseFeatureHook } from '../../types';
import type { Pet, RouteProps } from '../NewTosaRouter/types';
import type { ChangeEvent } from 'react';

export interface RecipesPageNonBlueprintArgs extends RouteProps {
  useFeature: UseFeatureHook;
  onTosaCompleted: OnTosaCompleted;
  lead: FetchLeadQuery['lead'];
  petName: string;
}

// TODO - put a place holder pet name on here from TOSA props
export function RecipesPageNonBlueprint({
  useFeature,
  onTosaCompleted,
  lead,
  petName,
  previousRoute,
  nextRoute,
}: RecipesPageNonBlueprintArgs) {
  const { refetch } = usePetNamesAndRecommendedCalories();
  useEffect(() => {
    // We want to refetch recommended calories when the recipes page is loaded
    // so that if a customer goes back into the signup flow and changes their
    // xpets' details, we don't get now-stale data from the cache.
    //
    // The ideal solution here is making the pet data into inputs to the
    // `FetchPetsWithRecommendedCalories` query itself so that Apollo knows
    // when to refetch (as we do with the fetchQuote query).
    refetch();
  }, [refetch]);

  const currentPet = lead.pets.find(pet => pet.name === petName) as Pet;
  const isLastPet = petName === lead.pets[lead.pets.length - 1].name;

  const { isRecipesPageEnabled, code } = useSurpriseHigherDiscountExperiment({
    useFeature,
  });

  useSurpriseHigherDiscountRecipesPage({
    shouldReplaceDiscount: isRecipesPageEnabled,
    code,
  });

  const [isCustomizingRecipes, setIsCustomizingRecipes] = useState<
    null | boolean
  >(null);
  const [currentPetRecipeSelection, setCurrentPetRecipeSelection] = useState<
    string[] | undefined
  >();

  const onRecipesCompleted = useHandleRecipesCompleted();
  const dispatchToast = useToast();

  const { availablePetRecipesMap } = useFetchPetsAvailableRecipes();
  const availableRecipes = availablePetRecipesMap?.[petName];
  const recommendedRecipes = useMemo(
    () =>
      availableRecipes && availableRecipes.filter(recipe => recipe.recommended),
    [availableRecipes]
  );

  const { isLpfRecipeEligible } = checkLpfRecipeStatus(availableRecipes || []);

  useEffect(() => {
    if (!availableRecipes) {
      return;
    }

    const availableSelectedRecipesNames = getSelectedAvailableRecipesV2({
      availableRecipes,
      currentSelection: currentPet.selection?.fresh?.options?.recipes ?? [],
    });

    setCurrentPetRecipeSelection(availableSelectedRecipesNames);
  }, [
    lead,
    availableRecipes,
    petName,
    currentPet.selection?.fresh?.options?.recipes,
  ]);

  useEffect(() => {
    // We only want to run this for the first setting of isCustomizingRecipes;
    // the user can control it thereafter
    if (
      !recommendedRecipes ||
      !currentPetRecipeSelection ||
      isCustomizingRecipes !== null
    ) {
      return;
    }

    const hasSelectionDistinctFromRecommendation =
      currentPetRecipeSelection.length > 0 &&
      !isEqual(
        new Set(recommendedRecipes.map(recipe => recipe.name)),
        new Set(currentPetRecipeSelection)
      );

    setIsCustomizingRecipes(hasSelectionDistinctFromRecommendation);
  }, [isCustomizingRecipes, recommendedRecipes, currentPetRecipeSelection]);

  useEffect(() => {
    // Ensure page scrolls to top when transitioning between recipes
    scroller.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  }, [isCustomizingRecipes]);

  const lpfRecipeNames = useMemo(
    () =>
      availableRecipes
        ? availableRecipes
            .filter(recipe => recipe.content.productLine === 'lpf')
            .map(recipe => recipe.name)
        : null,
    [availableRecipes]
  );

  useSetLpfProductEligibility({
    shouldPersistLpfEligibility: isLpfRecipeEligible,
    email: lead.email,
    lpfRecipeNames,
  });

  const handleSelectionChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newSelectedRecipes = [...(currentPetRecipeSelection ?? [])];
      const value = e.target.value;
      const isNewValue = !newSelectedRecipes.includes(value);
      if (isNewValue) {
        newSelectedRecipes.push(value);
      } else {
        newSelectedRecipes.splice(newSelectedRecipes.indexOf(value), 1);
      }

      if (newSelectedRecipes.length > MAX_SELECTED_RECIPES) {
        dispatchToast({
          variant: 'neutral',
          children: `You have ${MAX_SELECTED_RECIPES} recipes selected. Please deselect a recipe before choosing another.`,
        });
      } else {
        setCurrentPetRecipeSelection(newSelectedRecipes);
      }
    },
    [currentPetRecipeSelection, dispatchToast]
  );

  const handleReturnToRecommendedPlan = useCallback(() => {
    setIsCustomizingRecipes(false);
    trackRecipesClickBackToRecommendedPlan();
  }, []);

  const handleCustomizePlan = useCallback(() => {
    setIsCustomizingRecipes(true);
    trackRecipesClickSelectYourOwnPlan();
  }, []);

  const [updateLead, updateLeadData] = useUpdateLead();
  const throwToErrorBoundary = useThrowToErrorBoundary();
  if (updateLeadData.error) {
    throwToErrorBoundary(updateLeadData.error);
  }

  const updateLeadRecipeSelection = useCallback(
    async (selection: PetProductFreshSelectionOptionsInput['recipes']) => {
      await updateLead({
        variables: {
          lead: {
            blueprintVersion: standardBlueprintVersion,
            pets: createUpdateLeadPetsPayload({ lead, petName, selection }),
          },
        },
      });
    },
    [lead, petName, updateLead]
  );

  const handleRecommendedPlanSubmit = useCallback(
    (quote: FetchQuoteQuery['fetchQuote'] | null) => {
      if (!recommendedRecipes) {
        return;
      }

      petMixingPlanRatiosState.resetAllPetRatios();
      trackRecipesClickContinueWithRecommendation({
        recommendedRecipes,
      });
      onRecipesCompleted({
        lead,
        isLastPet,
        quote,
      });

      void updateLeadRecipeSelection(
        recommendedRecipes.map(recipe => recipe.name)
      );
    },
    [
      recommendedRecipes,
      onRecipesCompleted,
      lead,
      isLastPet,
      updateLeadRecipeSelection,
    ]
  );

  const handleCustomizeRecipesSubmit = useCallback(
    (quote: FetchQuoteQuery['fetchQuote'] | null) => {
      if (!currentPetRecipeSelection) {
        return;
      }

      petMixingPlanRatiosState.resetAllPetRatios();
      onRecipesCompleted({
        lead,
        isLastPet,
        quote,
      });

      void updateLeadRecipeSelection(currentPetRecipeSelection);
    },
    [
      onRecipesCompleted,
      lead,
      isLastPet,
      updateLeadRecipeSelection,
      currentPetRecipeSelection,
    ]
  );

  const [registerLead, registerLeadData] = useRegisterLead();
  if (registerLeadData.error) {
    throwToErrorBoundary(registerLeadData.error);
  }

  const [hasOnTosaCompletedRun, setHasOnTosaCompletedRun] = useState(false);

  useEffect(() => {
    const updateLeadResponse = updateLeadData.data?.updateLead;

    if (isLastPet && updateLeadResponse) {
      void (async function () {
        const { data } = await registerLead({
          variables: {
            lead: createLeadInput(updateLeadResponse),
            disableRecipesUpdate: true,
          },
        });

        if (!data) {
          throwToErrorBoundary(
            new Error(
              'Unexpected error in TOSA registration: no register lead data found'
            )
          );
          return;
        }

        await onTosaCompleted({
          registeredUserResponse: data.registerLead.user,
        });

        setHasOnTosaCompletedRun(true);
      })();
    }
  }, [
    isLastPet,
    onTosaCompleted,
    registerLead,
    throwToErrorBoundary,
    updateLeadData.data,
  ]);

  const isLoading =
    isCustomizingRecipes === null ||
    !availablePetRecipesMap ||
    !recommendedRecipes ||
    !currentPetRecipeSelection;

  if (isLoading) {
    return (
      <ContentLoader loading={true} height="100vh">
        <></>
      </ContentLoader>
    );
  }

  if (
    updateLeadData.data &&
    (!isLastPet || hasOnTosaCompletedRun) &&
    nextRoute
  ) {
    return <Redirect to={nextRoute} push />;
  }

  const isSubmitting = updateLeadData.loading || registerLeadData.loading;

  return (
    <>
      <SignUpHeaderNonTosaPage previousSignupRoute={previousRoute} />
      <TosaLayout>
        {/* TODO: Move MultiRecipeModal into RecipesSelectionV2, since that's
        where it can be opened */}
        <MultiRecipeModal recipes={recommendedRecipes} petName={petName} />
        {isCustomizingRecipes ? (
          <RecipesSelectionV2
            returnToRecommendedPlan={handleReturnToRecommendedPlan}
            currentPetRecipeSelection={currentPetRecipeSelection}
            onSubmit={handleCustomizeRecipesSubmit}
            isFormSubmitting={isSubmitting}
            handleSelectionChange={handleSelectionChange}
            petName={petName}
            pets={lead.pets}
            availablePetRecipesMap={availablePetRecipesMap}
          />
        ) : (
          <RecommendedPlanV2
            className={styles.recommendedPlan}
            customizePlan={handleCustomizePlan}
            onSubmit={handleRecommendedPlanSubmit}
            isFormSubmitting={isSubmitting}
            pets={lead.pets}
            petName={petName}
            availablePetRecipesMap={availablePetRecipesMap}
          />
        )}
      </TosaLayout>
    </>
  );
}
