import { track } from '@farmersdog/analytics';
import { eventNames } from './eventNames';
import type { ExitIntentOption } from '../options';

export function trackCtaEvent({
  option,
  ctaCopy,
}: {
  option: ExitIntentOption;
  ctaCopy: string;
}) {
  track(eventNames.homepage_exit_intent_clicked_cta, {
    choice_name: option,
    cta_clicked_name: ctaCopy,
  });
}
