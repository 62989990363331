import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import classNames from 'classnames';
import {
  PageWidth,
  Text,
  Link,
  Button,
  Divider,
  Grid,
  GridItem,
} from '@farmersdog/corgi-x';
import {
  PATH_PASSWORD_RESET,
  PATH_RESCHEDULE,
  PATH_ORDERS,
  PATH_PROFILE,
  PATH_PROFILE_CANCEL,
  PATH_SIGNUP_ME,
  PATH_DIY,
} from '@farmersdog/constants/paths';
import {
  LINK_DIGEST_RECIPES_INGREDIENTS,
  LINK_DIGEST_COST_AND_VALUE,
} from 'src/constants/links';
import { QueryParameter } from '@farmersdog/constants';

import Page from 'src/components/Page';
import { FullFooter } from 'src/components/Footer';
import { Navigation } from 'src/components/Navigation';

import FAQMenu from 'src/components/FAQMenu';
import CXContactInfo from 'src/components/CXContactInfo';
import { DiscountCTAText, getCtaText } from 'src/components/DiscountCTAText';
import { scrollAnchorNames, titles, menuItems } from './menuItems';

import { SectionHeading, AnswerText } from './components/Typography';
import { StructuredFAQ } from './components/StructuredFAQ';

import { useCxContact } from 'src/abTesting/useCxContact';
import { selectReferrer } from 'src/reducers/referrer';

import styles from './FAQ.module.css';
import qs from 'querystring';
import { useAccessibilityThemeContext } from 'src/hooks/useAccessibilityThemeContext';

import { getDiscountTypeAndAmount } from 'src/utils/referral';
import { CtaTracker, TrackingEvent } from '@farmersdog/utils';
import type { Referrer } from 'src/hooks/useReferrer';
import { trackGoToSignup } from 'src/analytics';

const signupLink = PATH_SIGNUP_ME;
const createYourPlanLink = `${PATH_SIGNUP_ME}?${qs.stringify({
  c: 'tfd50',
  [QueryParameter.UtmSource]: 'faq',
  [QueryParameter.UtmMedium]: 'internalsite',
  [QueryParameter.UtmCampaign]: 'creatingyourplan_signup',
})}`;
const createYourPlanCostLink = `${PATH_SIGNUP_ME}?${qs.stringify({
  c: 'tfd50',
  [QueryParameter.UtmSource]: 'faq',
  [QueryParameter.UtmMedium]: 'internalsite',
  [QueryParameter.UtmCampaign]: 'creatingyourplan_cost',
})}`;
export const trackingInformationLink = `${PATH_ORDERS}?${qs.stringify({
  [QueryParameter.UtmSource]: 'faq',
  [QueryParameter.UtmMedium]: 'internalsite',
  [QueryParameter.UtmCampaign]: 'packaging-shipping_tracking',
})}`;
const resetPasswordLink = PATH_PASSWORD_RESET;
export const orderArrivalLink = `${PATH_ORDERS}?${qs.stringify({
  [QueryParameter.UtmSource]: 'faq',
  [QueryParameter.UtmMedium]: 'internalsite',
  [QueryParameter.UtmCampaign]: 'your-subscription_orders-arrive',
})}`;
export const orderRescheduleLink = `${PATH_RESCHEDULE}?${qs.stringify({
  [QueryParameter.UtmSource]: 'faq',
  [QueryParameter.UtmMedium]: 'internalsite',
  [QueryParameter.UtmCampaign]: 'your-subscription_orders-arrive',
  [QueryParameter.EntryPoint]: '/faq',
})}`;
const orderBillingLink = `${PATH_ORDERS}?${qs.stringify({
  [QueryParameter.UtmSource]: 'faq',
  [QueryParameter.UtmMedium]: 'internalsite',
  [QueryParameter.UtmCampaign]: 'your-subscription_billed',
})}`;
export const orderCancelLink = `${PATH_PROFILE_CANCEL}?${qs.stringify({
  [QueryParameter.UtmSource]: 'faq',
  [QueryParameter.UtmMedium]: 'internalsite',
  [QueryParameter.UtmCampaign]: 'your-subscription_cancel',
})}`;
const changeAddressLink = `${PATH_PROFILE}?${qs.stringify({
  [QueryParameter.UtmSource]: 'faq',
  [QueryParameter.UtmMedium]: 'internalsite',
  [QueryParameter.UtmCampaign]: 'your-subscription_change-location',
})}`;

function FAQ() {
  const { phone, email } = useCxContact('prospective');
  const ctaReveal = useRef(null);

  const referrer = useSelector(selectReferrer) as Referrer;
  const { discountAmount, discountType } = getDiscountTypeAndAmount(referrer);

  const menuItemRefs = {
    [scrollAnchorNames.food]: useRef(null),
    [scrollAnchorNames.plan]: useRef(null),
    [scrollAnchorNames.subscription]: useRef(null),
    [scrollAnchorNames.shipping]: useRef(null),
  };

  const { accessibilityThemeStyles } = useAccessibilityThemeContext();

  return (
    <>
      <Helmet>
        <html itemScope itemType="https://schema.org/FAQPage" />
      </Helmet>
      <Page
        title="Frequently Asked Questions"
        description="Browse frequently asked questions and learn how to transition your dog to a fresh, healthy diet!"
      >
        <Navigation ctaTrigger={ctaReveal} />
        <PageWidth>
          <div ref={ctaReveal}>
            <Grid columnGap={{ lg: 'lg' }} className={styles.container}>
              <GridItem lg={3}>
                <FAQMenu
                  menuItems={menuItems}
                  className={styles.nav}
                  menuItemRefs={menuItemRefs}
                />
              </GridItem>
              <GridItem lg={9}>
                <div
                  className={styles.scrollElement}
                  ref={menuItemRefs[scrollAnchorNames.food]}
                ></div>
                <SectionHeading className={styles.sectionHeading}>
                  {titles.foodTitle}
                </SectionHeading>
                <StructuredFAQ question="Why should I feed fresh?">
                  <AnswerText>
                    Fresh is not dry, it’s not wet, or raw. Fresh is gently
                    cooked just enough to get rid of dangerous pathogens while
                    maintaining whole food integrity. We believe there’s a big
                    difference between surviving and thriving. As pet obesity,
                    cancer, and diabetes rise at alarming rates, there is a
                    growing body of scientific evidence that points to the
                    health benefits of a fresh food diet, and to the dangers of
                    many commercial pet food manufacturing processes. And
                    whether your dog is a picky eater grappling with a
                    particular health issue or perfectly healthy and willing to
                    eat just about anything, we’ve seen first-hand the positive
                    impact of feeding fresh to our own pups and our customers’.
                  </AnswerText>
                  <AnswerText>
                    There really isn’t anything fancy or magical about what
                    we’re trying to do: real, fresh food made convenient. It’s
                    still dog food, just how it should be.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="What does fresh mean to The Farmer’s Dog?">
                  <AnswerText>
                    Our meals are prepared in human food facilities, where each
                    recipe is gently cooked at low temperatures according to
                    human-grade standards. They’re then quickly frozen for safe
                    shipping and your storage convenience. Since we package and
                    deliver your meals on a convenient subscription, your food
                    is truly made fresh and never contains preservatives which
                    allow kibble to sit on a shelf for years at a time.
                    Sometimes things just work out to be a win-win.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="What are the recipes and ingredients?">
                  <AnswerText>
                    The Farmer’s Dog creates a variety of human-grade{' '}
                    <Link
                      href={LINK_DIGEST_RECIPES_INGREDIENTS}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      recipes
                    </Link>{' '}
                    that are simple in nature using different proteins and
                    vegetables to account for dietary restrictions. Our
                    board-certified veterinary nutritionists carefully formulate
                    each recipe to be 100% complete and balanced according to
                    AAFCO standards, and we use all human-grade ingredients and
                    processes to do so.
                  </AnswerText>
                  <AnswerText>
                    Every recipe has clean human-grade proteins, mixed with
                    simple whole foods, and balanced with vitamins and minerals.
                    That’s it. We never use feed-grade ingredients, and we don’t
                    process our ingredients to be shelf-stable, ensuring your
                    dog is getting the highest quality, most natural nutrients
                    available. All of our ingredients are human-grade and
                    sourced from reputable food suppliers, local farms, and
                    other human food purveyors that meet human-grade standards.
                  </AnswerText>
                  <AnswerText>
                    You can find the specific ingredient lists for the meal plan
                    we recommend on the “Your Plan” section of{' '}
                    <Link to={signupLink}>our meal plan questionnaire.</Link>
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="Do I need to refrigerate the food?">
                  <AnswerText>
                    Yes! Since our food is truly made fresh and we donʼt use any
                    preservatives, itʼs essential that you treat it like real
                    food (because it is!). Keep the food at a safe temperature
                    in a refrigerator or freezer. Your box will also include an
                    easy guide on how to store and feed the food properly (it’s
                    very simple!).
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="Do I need to cook the food?">
                  <AnswerText>
                    No need, our food is pre-portioned and ready-to-serve! You
                    can serve it directly from the fridge or add a touch of hot
                    water if your dog prefers warm food.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="How do you know what kind of food my dog needs?">
                  <AnswerText>
                    We work closely with vets to figure out the optimal meal
                    plans for different combinations of breed, age, activity
                    level, and ideal weight. That way, we can ask you the right
                    questions to create a plan that’s truly customized to your
                    dogs’ characteristics and needs. After you start, our trusty
                    team is here every step of the way to help monitor your
                    dog’s progress and make adjustments to your plan.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="Do I need to consult my vet to use The Farmer’s Dog?">
                  <AnswerText>
                    Please do! They will take comfort in the fact that we work
                    alongside board certified veterinary nutritionists, and
                    while many vets fight over different food trends, just about
                    every vet agrees that freshly made, nutritionally-balanced
                    food is great for dogs. Our recipes are formulated to fully
                    replace a standard diet, but many pups with a range of
                    health issues are also doing great on our meals.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="Why can’t I just cook dog food at home?">
                  <AnswerText>
                    You can - let us help you do it right! Studies have shown
                    95% of home-prepared diets have one or more significant
                    nutrient deficiencies, which may place a pet at risk of
                    developing serious health problems. The Farmer’s Dog DIY
                    Nutrient Mix and online recipes ensure that you are cooking
                    meals that are formulated by a Board-Certified Veterinary
                    Nutritionist (™) to be complete and balanced. Click{' '}
                    <Link to={PATH_DIY}>here</Link> to learn more. Happy
                    cooking!
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="Do you have food suitable for puppies, adult dogs, and seniors?">
                  <AnswerText>
                    Yes! We have recipe options suitable for dogs at different
                    stages of life, from puppies and adult dogs to seniors. Our
                    recipes are formulated to feed with no additional
                    supplementation necessary, and all are developed by our
                    board-certified animal nutritionist and reviewed by a
                    Board-Certified Veterinary Nutritionist® to be complete and
                    balanced according to AAFCO standards (tell your vet,
                    they’ll love it!) Just tell us a little about your dog in
                    our <Link to={signupLink}>dog profile questionnaire</Link>,
                    and we’ll recommend a plan that’s just right for their age
                    and needs!
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="What does The Farmer’s Dog cost?">
                  <AnswerText>
                    Every dog is different, and your exact{' '}
                    <Link
                      href={LINK_DIGEST_COST_AND_VALUE}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      plan and price options
                    </Link>{' '}
                    will depend on your dog(s) and their food needs, factoring
                    in age, weight, activity, recipe choice, and more. Plans
                    start at about $2/day, and delivery options mostly range
                    from 1 week to 2 months of food and include free shipping.
                    Because we deliver the food right to your door, your dollars
                    are going towards the highest quality ingredients, and not
                    to the mark-ups of a big retail store.
                  </AnswerText>
                  <AnswerText>
                    To see your exact pricing options,{' '}
                    <Link to={createYourPlanCostLink}>
                      simply fill out our short questionnaire.
                    </Link>
                  </AnswerText>
                </StructuredFAQ>
                <div
                  className={styles.scrollElement}
                  ref={menuItemRefs[scrollAnchorNames.plan]}
                ></div>
                <SectionHeading className={styles.sectionHeading}>
                  {titles.planTitle}
                </SectionHeading>
                <StructuredFAQ question="How do I sign up for a plan?">
                  <AnswerText>
                    Click <Link to={createYourPlanLink}>here</Link> to tell us
                    about your dog and weʼll determine their perfect plan. Itʼll
                    only take a few minutes!
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="How do you determine my dogʼs portions?">
                  <AnswerText>
                    We work closely with our vet nutritionists to figure out the
                    optimal calorie needs for different combinations of breed,
                    age, activity level, and ideal weight. Certain dogs can
                    metabolize faster than others, so some plans may need
                    further adjusting.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="Who made your website?">
                  <AnswerText>We did. (The dogs helped).</AnswerText>
                </StructuredFAQ>

                <div
                  className={styles.scrollElement}
                  ref={menuItemRefs[scrollAnchorNames.shipping]}
                ></div>
                <SectionHeading className={styles.sectionHeading}>
                  {titles.shippingTitle}
                </SectionHeading>
                <StructuredFAQ question="How do shipments work? Do you ship nationwide?">
                  <AnswerText>
                    We currently ship to the 48 contiguous states. With our meal
                    plans, customers receive deliveries of freshly prepared food
                    on a recurring and flexible basis. Plans are customized to
                    fit your dog’s needs and come in convenient serving packs to
                    make feeding easy. Your pup’s food arrives in refrigerated
                    and environmentally friendly boxes that keep the food safe.
                    We perfectly time deliveries to make sure you never run out
                    of food and you will have the ability to adjust deliveries
                    at any time online.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="How do I know if the food is safe to feed when it arrives?">
                  <AnswerText>
                    Only feed packs that arrive frozen or very cold (as if just
                    out of the fridge). Partial defrosting is okay, but the food
                    should still be below 40°F.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="What if Iʼm not home at the time my box arrives?">
                  <AnswerText>
                    Your boxes are packed with plenty of insulation and coolant
                    for precisely this reason, and keep your food safe and cold
                    during and after transit. Unpack it as soon as youʼre able,
                    and be sure to only feed packs that arrive frozen or very
                    cold (as if just out of the fridge). Partial defrosting is
                    okay, but the food should still be below 40°F.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="How can I find my tracking information?">
                  <AnswerText>
                    Click <Link to={trackingInformationLink}>here</Link> to see
                    tracking info for your shipped orders. We also send an email
                    notification with this information for each order.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="How flexible are the deliveries?">
                  <AnswerText>
                    Very! You can change addresses, rush, delay, or edit the
                    amount of food we send. Once you’re signed up, you can
                    easily make tweaks to your plan to your personal preference
                    online.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="How environmentally friendly are your deliveries?">
                  <AnswerText>
                    This planet gave us dogs, so taking care of it is a
                    priority. We pack our boxes with the environment in mind.
                    You can recycle the cardboard box the food is delivered in.
                    We have two kinds of insulation—one is biodegradable and
                    dissolves right down the drain under running water, and the
                    other you can recycle curbside along with the box. Please
                    refer to your insulation’s liner for exact disposal
                    instructions. The food storage container that comes in each
                    shipment is also biodegradable, and all of our packs are
                    made from BPA-free materials and are non-toxic.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="How do I dispose of the packaging?">
                  <AnswerText>
                    Your order will arrive in a cardboard box with
                    earth-friendly insulation, and either dry ice or cold packs
                    to keep the food at a safe temperature while in transit. Dry
                    ice should be left untouched in the box, where it will
                    disappear very quickly. The cold packs can be refrozen and
                    reused, or can be safely disposed of by allowing them to
                    fully defrost, then cutting the top off and pouring the
                    thawed contents down the drain (itʼs drainsafe and
                    nontoxic!). The empty cold pack and our empty food packs are
                    all recyclable with #4 plastics. Finally, our insulation is
                    one of two kinds—one is biodegradable and dissolves right
                    down the drain under running water, and the other you can
                    recycle curbside along with the box. Please refer to your
                    insulation’s liner for exact disposal instructions.
                  </AnswerText>
                </StructuredFAQ>

                <div
                  className={styles.scrollElement}
                  ref={menuItemRefs[scrollAnchorNames.subscription]}
                ></div>
                <SectionHeading className={styles.sectionHeading}>
                  {titles.subscriptionTitle}
                </SectionHeading>
                <StructuredFAQ question="I forgot my password - how can I reset it to log into my Customer Account?">
                  <AnswerText>
                    You can reset your account password by visiting{' '}
                    <Link to={resetPasswordLink}>this page</Link>. You’ll
                    receive a reset link in your email inbox where you can then
                    input a new password.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="How can I prepare to transition my dog’s food before my box arrives?">
                  <AnswerText>
                    Before your box arrives, you can make sure you have a week’s
                    worth of your dog’s old food on hand to mix with their new
                    food. You’ll also need to clear some freezer space.
                    Everything else you’ll need to know will be in your box.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="How does the transition work?">
                  <AnswerText>
                    Your box will include complete instructions on how to
                    gradually transition your dog from their old food to their
                    new fresh food. But in short: You’ll start with feeding
                    meals of 25% The Farmer’s Dog food, 75% old food. You’ll
                    gradually increase the quantity of new food and decrease the
                    old, until you’re feeding 100% The Farmer’s Dog.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="How should I thaw the food?">
                  <AnswerText>
                    Your packs will be delivered frozen in an insulated box.
                    When you receive your first box, store all of your packs in
                    the freezer—except one, which you can put in the fridge to
                    thaw. Frozen packs take ~12 hours to thaw in the fridge.
                    When you finish a pack, you can move one from the freezer to
                    the fridge to thaw in time for the next meal. We strongly
                    recommend thawing frozen packs in the fridge. Do not thaw on
                    the counter or at room temperature. If you ever forget to
                    thaw a pack in time for your dog’s meal time, you can speed
                    up the process by thawing the pack under cold running
                    water—but the fridge is the safest method.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="What if my dog doesn’t like the food?">
                  <AnswerText>
                    If your dog doesn’t love our food, we can send you
                    additional recipes to try, or you can opt for a refund if
                    you donate the food to a shelter.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="When will my orders arrive?">
                  <AnswerText>
                    Click <Link to={orderArrivalLink}>here</Link> to see a
                    summary of your orders. Once an order is in transit, you’ll
                    see its tracking link appear and can keep an eye on the
                    anticipated delivery date.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="How will I be billed for my plan? ">
                  <AnswerText>
                    You can find a summary of upcoming orders{' '}
                    <Link to={orderBillingLink}>here</Link> . You’ll be notified
                    via email about each order prior to billing and shipment.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="Can I cancel or reactivate my subscription anytime?">
                  <AnswerText>
                    Yes, you can easily pause, cancel or reactivate your
                    subscription anytime. Click{' '}
                    <Link to={orderCancelLink}>here</Link> to visit your
                    Customer Account to do so.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="Can I change when my next order will arrive?">
                  <AnswerText>
                    Yes! In the Subscription tab of your Customer Account you
                    can easily reschedule your upcoming order to receive it
                    sooner, or to delay the shipment. Click{' '}
                    <Link to={orderRescheduleLink}>here</Link> to reschedule
                    your orders.
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="Can I send my shipment to another location which is not my home?">
                  <AnswerText>
                    Definitely! You can change the delivery address on your next
                    order in the Shipping Details section of your{' '}
                    <Link to={changeAddressLink}>Customer Account</Link>. If
                    you’d like subsequent orders to ship to your regular
                    address, just be sure to update your details again before
                    your pup’s next batch of meals is cooked!
                  </AnswerText>
                </StructuredFAQ>
                <StructuredFAQ question="What do I do if I’m traveling?">
                  <AnswerText>
                    It’s easy to feed The Farmer’s Dog while on the move! If you
                    prefer to send food directly to your destination, you can
                    change your shipping address in your{' '}
                    <Link to={changeAddressLink}>Customer Account</Link> at any
                    time. If you’d rather travel with your food and are running
                    low, just rush an order to your home before you leave. When
                    traveling with fresh food, it’s best to keep it in an
                    insulated container or tote along with a few ice packs.
                  </AnswerText>
                </StructuredFAQ>
                <Divider spacing={48} width={1} color="Oyster1" />
                <Text variant="heading-22" bold as="h3" color="kale-3">
                  Can’t find what you are looking for? Reach out:
                </Text>
                <CXContactInfo showPhone={phone} showEmail={email} />
                <CtaTracker
                  event={TrackingEvent.goToSignup}
                  trackingFunction={trackGoToSignup}
                  trackingData={{
                    page: 'FAQ',
                    location: 'Can’t find what you are looking for?',
                    ctaCopy: getCtaText(discountAmount, discountType)
                      .trackableText,
                  }}
                >
                  <Button
                    type="link"
                    variant="primary"
                    className={classNames(
                      styles.cta,
                      accessibilityThemeStyles.responsiveCta
                    )}
                    to={signupLink}
                  >
                    <DiscountCTAText />
                  </Button>
                </CtaTracker>
              </GridItem>
            </Grid>
          </div>
        </PageWidth>
        <FullFooter />
      </Page>
    </>
  );
}

export default FAQ;
