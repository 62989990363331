// https://help.adanalytics.spotify.com/technical-pixel-docs
import { mountVendorWindowEvent } from '../utils';
import type { SpotifyEvent } from './types';

export interface trackSpotifyEventArgs {
  name: SpotifyEvent;
  properties?: Record<string, unknown>;
}

function _trackSpotifyEvent(
  w: Window,
  { name, properties }: trackSpotifyEventArgs
) {
  w.spdt?.(name, properties);
}

export const trackSpotifyEvent = mountVendorWindowEvent(
  'Spotify',
  _trackSpotifyEvent
);
