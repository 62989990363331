import { Logger } from '@farmersdog/logger';
import { createReducer } from 'redux-create-reducer';
import {
  REFERRAL_STATS_FETCH,
  REFERRER_FETCH,
  REFERRER_HAS_CALLED_FETCH,
  REFERRER_IS_FETCHING,
} from 'src/constants/actionTypes';

export const initialState = {
  referrer: undefined,
  isFetching: true,
  stats: {},
  requestAttributes: undefined,
  hasCalledFetch: false,
  lastFetch: undefined,
};

const log = new Logger('app:reducers:referrer');

export default createReducer(initialState, {
  [REFERRAL_STATS_FETCH](state, action) {
    return { ...state, stats: action.payload.data };
  },
  [REFERRER_IS_FETCHING](state, action) {
    return {
      ...state,
      isFetching: action.payload,
    };
  },
  [REFERRER_FETCH](state, action) {
    log.debug('Fetched referrer with data', action.payload.data);
    return {
      ...state,
      referrer: action.payload.data,
      lastFetch: new Date(),
    };
  },
  [`${REFERRER_FETCH}_FAILURE`](state, action) {
    const status =
      action.error && action.error.response && action.error.response.status;
    log.debug('Could not fetch referrer', { status });
    if (status === 404) {
      const newState = {
        ...state,
        referrer: {
          ...state.referrer,
          invalid: true,
        },
      };
      return newState;
    }
    return state;
  },
  [REFERRER_HAS_CALLED_FETCH](state) {
    return {
      ...state,
      hasCalledFetch: true,
    };
  },
});

export function selectReferrer(state) {
  return state.referrer.referrer;
}

/**
 * Return the request attributes defined by node
 *
 * @param {Object} state
 * @returns {import('src/graphql/types').ReferralStatsType}
 */
export function selectReferralStats(state) {
  return state.referrer.stats;
}

export function selectIsFetchingReferrer(state) {
  return state.referrer.isFetching;
}

export function selectLastReferrerFetch(state) {
  return state.referrer.lastFetch;
}

/**
 * Return the request attributes defined by node
 *
 * @param {Object} state
 * @returns {import('./referrer.types').RequestAttributes}
 */
export function selectRequestAttributes(state) {
  return state.referrer.requestAttributes ?? {};
}

export function selectHasCalledFetch(state) {
  return state.referrer.hasCalledFetch;
}
