import { paths } from '@farmersdog/constants';
import { LINK_PRIVACY_OPTOUT } from '@farmersdog/constants/links';
import type { FooterSection } from '@farmersdog/corgi-x';
import { Footer, Link, Text } from '@farmersdog/corgi-x';
import { trackRequestedSupport } from '@/account/analytics';
import MailIcon from 'src/assets/icons/mail.svg';
import SmartPhoneIcon from 'src/assets/icons/smartphone.svg';

export function HighTrafficFooter() {
  const handlePhoneSupportClick = () => {
    trackRequestedSupport({ supportChannel: 'phone' });
  };

  const handleEmailSupportClick = () => {
    trackRequestedSupport({ supportChannel: 'email' });
  };

  const contactSection = [
    <div key="footerEmailLink">
      <Link
        to="mailto:help@thefarmersdog.com"
        onClick={handleEmailSupportClick}
      >
        <MailIcon />
        help@thefarmersdog.com
      </Link>
    </div>,
    <div key="footerPhoneLink">
      <Link to={`tel:+16467807957`} onClick={handlePhoneSupportClick}>
        <SmartPhoneIcon />
        (646)-780-7957
      </Link>
    </div>,
  ];
  // TODO: We need to use inline specific styles right now to add left margin until we add a hSpacing prop to the Text component
  const footerSection: FooterSection[] = [
    [
      <>
        Contact{'  '}
        <Text variant="heading-16" color="white" style={{ marginLeft: '12px' }}>
          {' '}
          Available 24/7
        </Text>
      </>,
      contactSection,
    ],
  ];

  return (
    <Footer
      privacyLink={paths.PATH_PRIVACY}
      termsLink={paths.PATH_TERMS}
      accessibilityLink={paths.PATH_ACCESSIBILITY}
      doNotSellLink={LINK_PRIVACY_OPTOUT}
      sections={footerSection}
    />
  );
}
