import {
  WeightManagementMobileSources,
  PickyEatingMobileSources,
  SeniorCareMobileSources,
  PuppyCareMobileSources,
} from './assets/drawer';

import {
  PickyEatingSources,
  PuppyCareSources,
  SeniorCareSources,
  WeightManagementSources,
} from './assets/modal';

export enum BenefitCardVariant {
  WeightManagement = 'Weight Management',
  PickyEating = 'Picky Eating',
  SeniorCare = 'Senior Care',
  PuppyCare = 'Puppy Care',
}

export interface Sources {
  webP: string;
  jpg: string;
}

export interface BenefitInformation {
  sources: Sources;
  mobileSources: Sources;
  title: string;
  benefits: string[];
  testimonial: {
    quote: string;
    author: string;
  };
  ctaText: string;
}

//TODO: add the images for the other benefits

const weightManagement: BenefitInformation = {
  sources: {
    webP: WeightManagementSources.webP,
    jpg: WeightManagementSources.jpg,
  },
  mobileSources: {
    webP: WeightManagementMobileSources.webP,
    jpg: WeightManagementMobileSources.jpg,
  },
  title: 'Fresh pre-portioned food helps dogs maintain a healthy weight.',
  benefits: [
    'Lean dogs can live up to 2.5 years longer',
    'Their risk of joint issues decreases',
    'You can save an average of $1k/year on vet bills for obesity-related treatments',
  ],
  testimonial: {
    quote:
      "Liam (was) 73lbs. He had no energy to do anything. I knew I needed to change up his food. It’s been about 4.5 months, and look at the difference! He's 8 years old and has his puppy energy back.",
    author: "Liam's person",
  },
  ctaText: 'Get Started',
};

const pickyEating: BenefitInformation = {
  sources: {
    webP: PickyEatingSources.webP,
    jpg: PickyEatingSources.jpg,
  },
  mobileSources: {
    webP: PickyEatingMobileSources.webP,
    jpg: PickyEatingMobileSources.jpg,
  },
  title: 'Still searching for the right food for your picky dog?',
  benefits: [
    'Dogs prefer the taste of our food 2-to-1 over kibble',
    'We use real ingredients and cooking methods that maximize nutrients',
    'Our customer care is available 24/7',
  ],
  testimonial: {
    quote:
      'She has never been ready to eat, it was always a struggle. Now, Allie is right there to eat when her bowl touches the floor.',
    author: 'Allie’s person',
  },
  ctaText: 'Get Started',
};

const seniorCare: BenefitInformation = {
  sources: {
    webP: SeniorCareSources.webP,
    jpg: SeniorCareSources.jpg,
  },
  mobileSources: {
    webP: SeniorCareMobileSources.webP,
    jpg: SeniorCareMobileSources.jpg,
  },
  title: 'Easy on your dog’s digestive system and loaded with benefits.',
  benefits: [
    'Increases your dog’s energy & activity levels',
    'Minimizes risk of developing joint issues',
    'Provides high quality protein to bolster muscle mass',
  ],
  testimonial: {
    quote:
      'At 14 we didn’t expect her to change much...within a couple weeks we noticed her skin had improved and she began to play more.',
    author: 'Lucy’s person',
  },
  ctaText: 'Get Started',
};

const puppyCare: BenefitInformation = {
  sources: {
    webP: PuppyCareSources.webP,
    jpg: PuppyCareSources.jpg,
  },
  mobileSources: {
    webP: PuppyCareMobileSources.webP,
    jpg: PuppyCareMobileSources.jpg,
  },
  title: 'A fresh food plan that grows with your growing puppy.',
  benefits: [
    'Boosts immunity with omega-3 fatty acids',
    'Helps to maintain ideal weight',
    'Grows with your pup—we’ll check in regularly to adjust your plan as they mature',
  ],
  testimonial: {
    quote:
      'Thor is 7 months old. Quick benefits transitioning to The Farmer’s Dog, his poops becoming solid, less room clearing farts, having more energy and his hair/fur seems more vibrant.',
    author: 'Thor’s person',
  },
  ctaText: 'Get Started',
};

export const benefitDataMap = {
  [BenefitCardVariant.WeightManagement]: weightManagement,
  [BenefitCardVariant.PickyEating]: pickyEating,
  [BenefitCardVariant.SeniorCare]: seniorCare,
  [BenefitCardVariant.PuppyCare]: puppyCare,
};
