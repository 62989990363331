import { useHistory } from 'react-router';

import { paths } from '@farmersdog/constants';
import { useCoupon, DiscountType } from '@farmersdog/coupons';
import { isDiySignup } from '@farmersdog/lead-browser-storage';

import { trackPetsSuccess } from '../analytics';
import { getRecipesRouteForPet } from '../components/NewTosaRouter/utils/getRecipesRouteForPet';
import config from '../config';
import { isContinueToNextPet } from '../utils';
import { FeatureName } from '../utils/features';

import { useLeadState } from './useLeadState';
import { useRequestAttributesContext } from './useRequestAttributes';

import type { BranchNode } from '../blueprint/types';
import type {
  FullLeadRegisterResponseFragment,
  FetchQuoteQuery,
  FetchLeadQuery,
} from '../graphql/types';
import type { Experiments } from '../types';

export interface OnTosaCompletedArgs {
  registeredUserResponse: FullLeadRegisterResponseFragment['user'];
}

export interface OnTosaCompleted {
  (args: OnTosaCompletedArgs): Promise<void>;
}

export interface OnMeStepCompletedData {
  lead: FetchLeadQuery['lead'] | null;
  couponData: Pick<
    ReturnType<typeof useCoupon>,
    'couponCode' | 'discountAmount' | 'discountType' | 'referrerType'
  >;
  requireCorePostgresId?: boolean;
  discountOverride: number | null;
}

export interface OnStepCompleted {
  (step: string, lead: FetchLeadQuery['lead'] | null): void;
}

export interface OnMeStepCompleted {
  (step: string, data: OnMeStepCompletedData): void;
}

export interface OnPetStepCompleted {
  (): void;
}

export interface OnRecipesCompletedArgs {
  isLastPet: boolean;
  lead: FetchLeadQuery['lead'] | null;
  quote: FetchQuoteQuery['fetchQuote'] | null;
}

export interface OnRecipesCompleted {
  (args: OnRecipesCompletedArgs): void;
}

export interface OnFormCompleted {
  (step: string): Promise<void>;
}

export interface UseHandleStepCompletedArgs {
  formSteps: BranchNode[];
  experiments: Experiments;
  onMeCompleted?: OnMeStepCompleted;
  onPetsCompleted?: OnPetStepCompleted;
  onTosaCompleted?: OnTosaCompleted;
  onStepCompleted?: OnStepCompleted;
}

export function useHandleFormCompleted({
  formSteps,
  experiments,
  onMeCompleted,
  onPetsCompleted,
  onTosaCompleted,
  onStepCompleted,
}: UseHandleStepCompletedArgs): OnFormCompleted {
  const history = useHistory();
  const requestAttributes = useRequestAttributesContext();

  const { getCachedLead, getCachedUser } = useLeadState();

  const { url, referrerRoute } = config.get('tosa.app.coreApi');
  const useCouponData: Parameters<typeof useCoupon>[0] = {
    apiUrl: `${url}${referrerRoute}`,
    defaultDiscount: {
      discountType: DiscountType.percentage,
      discountAmount: config.get('features.defaultTrialDiscount'),
    },
  };

  const { couponCode, discountAmount, discountType, referrerType } =
    useCoupon(useCouponData);

  const finalMeStep = [...formSteps].reverse().find(formStep => {
    return formStep.route === paths.PATH_SIGNUP_ME;
  });

  const finalPetsStep = [...formSteps].reverse().find(formStep => {
    return formStep.route === paths.PATH_SIGNUP_PETS;
  });

  const [finalStep] = formSteps.slice(-1);

  const handleFormCompleted: OnFormCompleted = async step => {
    const lead = getCachedLead();
    const user = getCachedUser();

    if (step === finalMeStep?.name && onMeCompleted) {
      const globalDiscountOverride =
        experiments[FeatureName.CVR_GLOBAL_DISCOUNT_OVERRIDE];
      const discountPercentage = globalDiscountOverride?.config
        ?.discountPercentage as number | null;
      const isGlobalDiscountOverrideOn =
        globalDiscountOverride?.treatment !== 'off' &&
        globalDiscountOverride?.treatment !== 'control';
      const discountOverride = isGlobalDiscountOverrideOn
        ? discountPercentage
        : null;

      const couponData = {
        couponCode,
        discountAmount,
        discountType,
        referrerType,
      };

      onMeCompleted(step, {
        lead,
        couponData,
        requireCorePostgresId: true,
        discountOverride,
      });
    }

    const currentBranch = formSteps.find(formStep => formStep.name === step);
    const isPetStep = currentBranch?.route === paths.PATH_SIGNUP_PETS;
    if (isPetStep && onPetsCompleted) {
      const isLastPet = step === finalPetsStep?.name;

      if (isLastPet) {
        onPetsCompleted();
        // user object and humanId is needed if it is the last pet
        trackPetsSuccess({ user, humanId: lead?.humanId, requestAttributes });
      } else if (isContinueToNextPet({ formSteps, currentBranchName: step })) {
        onPetsCompleted();
        // user object is not needed if it is not the last pet
        trackPetsSuccess({ user: {}, requestAttributes });
      }
    }

    if (onStepCompleted) {
      onStepCompleted(step, lead);
    }

    if (step === finalStep.name && onTosaCompleted) {
      const isRecipesPageInNewTosaRouter =
        experiments[FeatureName.CVR_RECIPES_PAGE_NON_BLUEPRINT]?.treatment ===
        'on';

      if (isRecipesPageInNewTosaRouter) {
        // For fresh subscriptions, we call onTosaCompleted on the recipes page itself.
        if (isDiySignup()) {
          if (!user) {
            throw new Error(
              'Unexpected error in TOSA registration: user object is not present at TOSA completion'
            );
          }

          await onTosaCompleted({
            registeredUserResponse: user,
          });
        }

        history.push(
          isDiySignup()
            ? paths.PATH_SIGNUP_RECIPES
            : getRecipesRouteForPet(lead!.pets[0].name)
        );
      } else {
        if (!user) {
          throw new Error(
            'Unexpected error in TOSA registration: user object is not present at TOSA completion'
          );
        }

        await onTosaCompleted({
          registeredUserResponse: user,
        });

        history.push(
          finalStep.name.includes('RecipeSelection')
            ? paths.PATH_SIGNUP_TREATS
            : paths.PATH_SIGNUP_RECIPES
        );
      }
    }
  };

  return handleFormCompleted;
}
