import type React from 'react';
import { useCallback, useState } from 'react';
import shuffle from 'lodash/shuffle';

import { PATH_SIGNUP } from '@farmersdog/constants/paths';
import {
  Button,
  Form,
  Grid,
  GridItem,
  Input,
  Link,
  Modal,
  Nowrap,
  RadioButton,
  Text,
} from '@farmersdog/corgi-x';
import { DiscountType, useCoupon } from '@farmersdog/coupons';

import config from '../../../../config';

import { trackCtaEvent, trackSelectedEvent } from './analytics';
import { options, ExitIntentOption } from './options';
import styles from './styles.module.css';

const FREE_RESPONSE = 'free response';
const optionsKeysNoOther = Object.keys(options).filter(
  opt => opt !== ExitIntentOption.Other
);
const shuffledKeysNoOther = shuffle(optionsKeysNoOther);
const shuffledOptions = [...shuffledKeysNoOther, ExitIntentOption.Other];

export interface ExitIntentModalProps {
  onClose: () => void;
}
export function ExitIntentModal({ onClose }: ExitIntentModalProps) {
  const [option, setOption] = useState<ExitIntentOption | null>(null);
  const [isFreeResponseSubmitted, setIsFreeResponseSubmitted] = useState(false);
  const [freeResponseValue, setFreeResponseValue] = useState('');
  const { url, referrerRoute } = config('tosa.app.coreApi');
  const { discountAmount } = useCoupon({
    apiUrl: `${url}${referrerRoute}`,
    defaultDiscount: {
      discountType: DiscountType.percentage,
      discountAmount: config('features.defaultTrialDiscount'),
    },
  });

  const response = option ? options[option] : null;

  const shouldShowFreeResponse =
    option === ExitIntentOption.Other && !isFreeResponseSubmitted;

  const shouldShowResponse = response && !shouldShowFreeResponse;

  const handleChangeFreeResponse: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      e => setFreeResponseValue(e.target.value),
      [setFreeResponseValue]
    );

  const handleClickOption = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      const value = e.currentTarget.value as ExitIntentOption;
      setOption(value);

      if (value !== ExitIntentOption.Other) {
        trackSelectedEvent({ option: value, trigger: 'select' });
      }
    },
    [setOption]
  );

  const handleSubmitFreeResponse = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!freeResponseValue.trim()) {
        return;
      }

      trackSelectedEvent({
        option,
        freeResponseValue,
        trigger: 'select',
      });
      setIsFreeResponseSubmitted(true);
    },
    [setIsFreeResponseSubmitted, option, freeResponseValue]
  );

  const handleClickCta = useCallback(() => {
    if (!response || !option) {
      return;
    }

    trackCtaEvent({ option, ctaCopy: response.ctaCopy });
  }, [option, response]);

  const handleClose = useCallback(() => {
    trackSelectedEvent({ option, freeResponseValue, trigger: 'close' });
    onClose();
  }, [freeResponseValue, option, onClose]);

  return (
    <Modal
      isOpen
      withCloseButton
      backdropClassName={styles.backdrop}
      contentClassName={styles.ExitIntentModal}
      onClose={handleClose}
      width={590}
    >
      <Grid
        flexDirection="column"
        alignItems="stretch"
        justifyContent="space-between"
        rowGap={shouldShowResponse ? 'lg' : 'sm'}
        className={styles.content}
      >
        <GridItem className={styles.top}>
          <Text as="h1" variant="heading-40" bold color="Kale3">
            We’re here to help
          </Text>
          <Text bold variant="heading-16">
            <Nowrap>
              <Link to={PATH_SIGNUP}>
                <Text bold underline color="Carrot2">
                  Save {discountAmount}%
                </Text>
              </Link>{' '}
              <Text bold>on fresh food today!</Text>
            </Nowrap>
          </Text>
          {!shouldShowResponse && (
            <Text
              bold
              as="h2"
              variant="heading-22"
              topSpacing="xl"
              bottomSpacing="md"
              color="kale-3"
            >
              How can we improve?
            </Text>
          )}
        </GridItem>
        <GridItem>
          {shouldShowResponse ? (
            response.content
          ) : (
            <Form className={styles.optionsForm}>
              {shuffledOptions.map(optionKey => {
                return (
                  <RadioButton
                    name="home-exit-intent"
                    key={optionKey}
                    value={optionKey}
                    label={optionKey}
                    onClick={handleClickOption}
                  />
                );
              })}
            </Form>
          )}
        </GridItem>
        {shouldShowResponse && (
          <GridItem>
            <Button
              onClick={handleClickCta}
              type={response.ctaType}
              href={response.ctaPath}
              to={response.ctaPath}
            >
              {response.ctaCopy}
            </Button>
          </GridItem>
        )}
        {shouldShowFreeResponse && (
          <Form
            id="freeResponseForm"
            data-testid="freeResponseForm"
            style={{ display: 'flex', alignItems: 'flex-end' }}
            onSubmit={handleSubmitFreeResponse}
          >
            <Input
              autoFocus
              placeholder="Type"
              aria-label="Free Response"
              name={FREE_RESPONSE}
              value={freeResponseValue}
              onChange={handleChangeFreeResponse}
              className={styles.freeResponseInput}
            />
            <Button className={styles.freeResponseSubmit} type="submit">
              Submit
            </Button>
          </Form>
        )}
      </Grid>
    </Modal>
  );
}
