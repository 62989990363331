import * as Apollo from '@apollo/client';

import countriesQuery from './countries.graphql';

import type { FetchCountriesQuery } from './countries.generated-types';

/**
 * Return the result of the countries query
 */
export function useCountries(options?: Partial<Apollo.QueryDataOptions>) {
  const { data, loading, error } = Apollo.useQuery<FetchCountriesQuery>(
    countriesQuery,
    options
  );

  if (error) {
    throw error;
  }

  return { data, loading };
}
