import { isEligibleForDiy } from '@farmersdog/lead-browser-storage';

import { FreshAndDiyCta } from './FreshAndDiyCta';
import { FreshOnlyCta } from './FreshOnlyCta';

export interface RecommendedPlanCtaProps {
  recommendationDisabled: boolean;
  recommendationLoading: boolean;
  formSubmitting: boolean;
  isNonBlueprintRecipesPage?: boolean;
  onClick: () => void;
}

const RecommendedPlanCta = (props: RecommendedPlanCtaProps) => {
  return isEligibleForDiy() ? (
    <FreshAndDiyCta {...props} />
  ) : (
    <FreshOnlyCta {...props} />
  );
};

export { RecommendedPlanCta };
