import {
  PAW_PATH_TEMPORARY_PAUSE,
  PATH_CUSTOMER_ACCOUNT,
  PATH_INVENTORY,
  PATH_MEALS,
  PATH_ORDERS,
  PATH_PROFILE,
  PATH_PROFILE_CANCEL,
  PATH_PROFILE_REACTIVATE,
  PATH_RESCHEDULE,
  PATH_CALIBRATE_PET_EDIT,
  PATH_GIFT,
} from '@farmersdog/constants/paths';

interface Redirect {
  from: string;
  to: string;
}

export const ACCOUNT_REDIRECTS: Redirect[] = [
  { from: '/app/overview', to: PATH_CUSTOMER_ACCOUNT },
  { from: '/app/freemeals', to: PATH_GIFT },

  { from: '/app/account', to: PATH_CUSTOMER_ACCOUNT },
  { from: '/app/account/orders', to: PATH_ORDERS },
  { from: '/app/account/reactivate', to: PATH_PROFILE_REACTIVATE },
  { from: '/app/account/cancel', to: PATH_PROFILE_CANCEL },
  { from: '/app/account/manage', to: PATH_PROFILE },
  { from: '/app/account/password', to: PATH_PROFILE },

  { from: '/app/subscription', to: PATH_CUSTOMER_ACCOUNT },
  { from: '/app/subscription/calories', to: PATH_MEALS },
  { from: '/app/subscription/frequencies', to: PATH_ORDERS },
  { from: '/app/subscription/rush', to: PATH_RESCHEDULE },
  { from: '/app/subscription/delay', to: PATH_RESCHEDULE },
  { from: '/app/subscription/inventory', to: PATH_INVENTORY },
  { from: '/app/subscription/orders', to: PATH_ORDERS },
  { from: '/app/subscription/suspend', to: PATH_PROFILE },

  { from: '/app/dogs/:petName', to: PATH_CALIBRATE_PET_EDIT },
  { from: '/app/meals/:petName', to: PATH_CALIBRATE_PET_EDIT },

  { from: '/app/pause', to: PAW_PATH_TEMPORARY_PAUSE },
];
