import cloneDeep from 'lodash/cloneDeep';

import { standardBlueprintVersion } from '../../../blueprint';
import { ProductLineType } from '../../../graphql/types';
import { removeTypenamesFromGraphQLResponse } from '../../../utils/removeTypenamesFromGraphQLResponse';

import type {
  PetInput,
  BirthdayInput,
  LeadInput,
  PetProductFreshSelectionOptionsInput,
  PetProductSelectionInput,
} from '../../../graphql/types';
import type { Lead, Pet } from '../../NewTosaRouter/types';

export function createUpdateLeadPetsPayload({
  lead,
  petName,
  selection,
}: {
  lead: Lead;
  petName: string;
  selection: PetProductFreshSelectionOptionsInput['recipes'];
}): PetInput[] {
  return removeTypenamesFromGraphQLResponse(
    lead.pets.map(pet => {
      const petInput = createPetInput(pet);
      if (petInput.name === petName) {
        petInput.selection ??= {};
        petInput.selection.fresh = {
          productLine: ProductLineType.Fresh,
          options: {
            recipes: selection,
          },
        };
      }

      return petInput;
    })
  );
}

export function createLeadInput(lead: Lead): LeadInput {
  const simplifiedLead = {
    anonymousId: lead.anonymousId,
    blueprintVersion: lead.blueprintVersion ?? standardBlueprintVersion,
    discount: lead.discount,
    firstName: lead.firstName,
    freshFoodConfidence: lead.freshFoodConfidence,
    pets: lead.pets.map(createPetInput),
    productLine: lead.productLine,
    selection: lead.selection,
    zip: lead.zip,
  };

  return removeTypenamesFromGraphQLResponse(simplifiedLead);
}

function createPetInput(pet: Pet): PetInput {
  const birthdayInput = pet.birthdayInput
    ? ({ ...pet.birthdayInput } as Record<string, unknown>)
    : null;
  if (birthdayInput) {
    delete birthdayInput.referencedAt;
  }

  const selection = pet.selection
    ? (cloneDeep(pet.selection) as Record<string, unknown>)
    : null;
  if (selection) {
    if (selection.fresh) {
      delete (selection.fresh as Record<string, unknown>).createdAt;
    }

    if (selection.diy) {
      delete (selection.diy as Record<string, unknown>).createdAt;
    }
  }

  return {
    activityLevel: pet.activityLevel,
    birthdayInput: birthdayInput as BirthdayInput | null,
    bodyCondition: pet.bodyCondition,
    breeds: pet.breeds,
    eatingStyle: pet.eatingStyle,
    foodType: pet.foodType,
    gender: pet.gender,
    issues: pet.issues,
    name: pet.name,
    nature: pet.nature,
    neutered: pet.neutered,
    prescriptionDiets: pet.prescriptionDiets,
    selection: selection as PetProductSelectionInput | null,
    treatsQuantity: pet.treatsQuantity,
    weight: pet.weight,

    // Optional properties; Apollo only plays nice with these if we pass them
    // as undefined or not at all (not as null) for some reason
    ...(pet.foodBrand ? { foodBrand: pet.foodBrand } : {}),
    ...(pet.targetWeight ? { targetWeight: pet.targetWeight } : {}),
  };
}
