import {
  useState,
  useEffect,
  useRef,
  type ChangeEvent,
  type FocusEvent,
} from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';

import { QueryParameter } from '@farmersdog/constants';
import { PATH_LOGIN, PATH_PASSWORD_RESET } from '@farmersdog/constants/paths';
import { Link, FormControl } from '@farmersdog/corgi';
import { Button, Input, Text } from '@farmersdog/corgi-x';

import { showError } from 'src/actions/ui';
import Form from 'src/components/Form';
import { Slider } from 'src/components/Slider';
import { ValidationError } from 'src/errors';
import { TOKEN_EXPIRED } from 'src/errors/errorCodes';
import parseQueryString from 'src/utils/parseQueryString';
import useInputValidation from 'src/validation/useInputValidation';

import { AuthPage } from '@/account/app/components/AuthPage';
import { SuccessCheckmark } from '@/account/components/SuccessCheckmark';

import {
  PASSWORD_MATCH_VALIDATION_MESSAGE,
  PAGE_TITLE_DEFAULT,
  PAGE_TITLE_SUCCESS,
  PAGE_HEADER_CHANGE,
  PAGE_HEADER_CREATE,
  PAGE_SUBHEADER,
  PASSWORD_INPUT_LABEL,
  PASSWORD_CONFIRMATION_INPUT_LABEL,
  SET_PASSWORD_BUTTON_LABEL,
  TOKEN_EXPIRED_TITLE,
  PASSWORD_HAS_BEEN_CHANGED_MESSAGE,
} from './ChangePassword.copy';
import styles from './ChangePassword.module.css';
import {
  newPasswordValidationRules,
  newPasswordConfirmationValidationRules,
} from './ChangePassword.validation';
import { useResetPassword } from './hooks';

const PASSWORD_FIELD_NAME = 'password';
const CONFIRM_PASSWORD_FIELD_NAME = 'confirm-password';

interface ChangePasswordProps {
  create?: boolean;
}

export function ChangePassword({ create }: ChangePasswordProps) {
  const dispatch = useDispatch();
  const params = useParams<{ token?: string }>();
  const location = useLocation();

  // TODO: Deprecate path-based tokens in favor or query params
  // This is a temporary solution to support both path-based and query-based tokens
  const token = params.token ?? parseQueryString(location.search, 'token');

  const email = parseQueryString(location.search, 'email') || '';

  const [error, setError] = useState<ValidationError | undefined>();
  const [isExpiredToken, setIsExpiredToken] = useState(false);
  const [success, setSuccess] = useState(false);
  const [newPassword, setNewPassword] = useState<string | undefined>();
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState<
    string | undefined
  >();
  const newPasswordRef = useRef<HTMLInputElement>(null);

  const passwordValidation = useInputValidation(newPasswordValidationRules, {
    test: () => newPassword !== newPasswordConfirmation,
    message: PASSWORD_MATCH_VALIDATION_MESSAGE,
  });

  const handlePasswordBlur = (e: FocusEvent<HTMLInputElement>) => {
    passwordValidation.checkValidity(e);
  };

  const passwordConfirmationValidation = useInputValidation(
    newPasswordConfirmationValidationRules
  );

  const handlePasswordConfirmationBlur = (e: FocusEvent<HTMLInputElement>) => {
    passwordValidation.checkValidity({ target: newPasswordRef.current });
    passwordConfirmationValidation.checkValidity(e);
  };

  const {
    callResetPassword,
    resetPasswordState: { loading },
  } = useResetPassword({
    onCompleted: data => {
      if ('resetPassword' in data && data.resetPassword.email) {
        setSuccess(true);
      }
    },
    onError: err => {
      const errorCause = err?.cause as { extensions?: { code?: string } };
      const errorCode = errorCause?.extensions?.code;

      if (errorCode === TOKEN_EXPIRED) {
        setIsExpiredToken(true);
        setError(new ValidationError('', '', TokenExpiredText));
      } else {
        setError(new ValidationError(err?.message ?? ''));
      }
    },
  });

  const handleSubmit = () => {
    callResetPassword({
      newPassword: newPassword!,
      resetToken: token || '',
    });
  };

  useEffect(() => {
    newPasswordRef.current?.focus();
  }, []);

  useEffect(() => {
    if (!error) {
      return;
    }

    dispatch(
      showError(error, {
        title: isExpiredToken ? TOKEN_EXPIRED_TITLE : '',
      })
    );
  }, [error, dispatch, isExpiredToken]);

  const backToLoginLink = email
    ? `${PATH_LOGIN}?${QueryParameter.Email}=${encodeURIComponent(email)}`
    : PATH_LOGIN;

  const passwordsMatch = newPassword === newPasswordConfirmation;

  const TokenExpiredText = (
    <>
      <div>
        Please check your inbox and use the link in the most recent password
        reset email you received from us, or request a new link{' '}
        <Link to={PATH_PASSWORD_RESET}>here.</Link>
      </div>
      <br />
      <div>
        If you’re still having trouble, please reach out using the link below.
      </div>
    </>
  );

  return (
    <AuthPage
      title={success ? PAGE_TITLE_SUCCESS : PAGE_TITLE_DEFAULT}
      header={create ? PAGE_HEADER_CREATE : PAGE_HEADER_CHANGE}
      subHeader={PAGE_SUBHEADER}
      loading={loading}
    >
      <Slider className={styles.slider} currentSlide={success ? 1 : 0}>
        <Slider.Slide>
          <Form
            className={styles.form}
            onSubmit={handleSubmit}
            enableSubmit={!loading}
          >
            {email && (
              <Input
                name={email}
                label={email}
                type="email"
                autoComplete="email"
                className={styles.field}
                disabled
              />
            )}
            <FormControl
              className={styles.field}
              message={passwordValidation.validationError}
              invalid={Boolean(passwordValidation.validationError)}
              aria-live="polite"
            >
              <Input
                {...passwordValidation.inputProps}
                ref={newPasswordRef}
                name={PASSWORD_FIELD_NAME}
                type="password"
                autoComplete="new-password"
                label={PASSWORD_INPUT_LABEL}
                withRevealButton
                value={newPassword}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setError(undefined);
                  setNewPassword(e.target.value);
                }}
                onBlur={handlePasswordBlur}
              />
            </FormControl>
            <FormControl
              className={styles.field}
              message={passwordConfirmationValidation.validationError}
              invalid={Boolean(passwordConfirmationValidation.validationError)}
              aria-live="polite"
            >
              <Input
                {...passwordConfirmationValidation.inputProps}
                name={CONFIRM_PASSWORD_FIELD_NAME}
                label={PASSWORD_CONFIRMATION_INPUT_LABEL}
                type="password"
                withRevealButton
                value={newPasswordConfirmation}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setError(undefined);
                  setNewPasswordConfirmation(e.target.value);
                }}
                onBlur={handlePasswordConfirmationBlur}
              />
            </FormControl>
            <Button
              className={styles.submitButton}
              type="submit"
              loading={loading}
              disabled={
                !newPassword || !newPasswordConfirmation || !passwordsMatch
              }
            >
              {SET_PASSWORD_BUTTON_LABEL}
            </Button>
          </Form>
        </Slider.Slide>
        <Slider.Slide>
          {success && (
            <div className={styles.success}>
              <SuccessCheckmark />
              <Text as="p" variant="heading-16" color="kale-3">
                {PASSWORD_HAS_BEEN_CHANGED_MESSAGE}
              </Text>
              <Link className={styles.loginLink} to={backToLoginLink}>
                <Text as="p" variant="heading-16" bold>
                  Back to log in
                </Text>
              </Link>
            </div>
          )}
        </Slider.Slide>
      </Slider>
    </AuthPage>
  );
}
