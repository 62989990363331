import { Redirect, Route } from 'react-router';

import type { PropsWithChildren } from 'react';

/**
 * Adds an optional email segment to the passed path (e.g. turns `/signup` into
 * `/signup/:email?`). If the optional email segment is present in the URL, the
 * component will redirect to the base path sans email.
 */
export function RouteWithOptionalEmail({
  path,
  children,
}: PropsWithChildren<{ path: string }>) {
  const pathWithEmail = `${path}/:email`;

  return (
    <>
      <Route path={path}>{children}</Route>

      <Route path={pathWithEmail}>
        <Redirect to={path} />
      </Route>
    </>
  );
}
