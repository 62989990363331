import type React from 'react';
import classNames from 'classnames';
import { Text, Grid, GridItem } from '@farmersdog/corgi-x';

import DoubleQuote from '../../assets/double-quote.svg';

import styles from './Quote.module.css';

interface QuoteProps {
  doubleQuotesOnTop?: boolean;
  text: string | React.ReactNode;
  author?: string;
  className?: string;
  textStyles?: string;
  rotateQuote?: boolean;
  mirrorQuote?: boolean;
}

export const Quote = ({
  doubleQuotesOnTop = false,
  rotateQuote = false,
  mirrorQuote = false,
  text,
  author,
  className,
  textStyles,
}: QuoteProps) => {
  return (
    <Grid gap="sm" className={className}>
      {doubleQuotesOnTop && (
        <GridItem>
          <DoubleQuote
            className={classNames({
              [styles.rotateQuote]: rotateQuote,
              [styles.mirrorQuote]: mirrorQuote,
            })}
          />
        </GridItem>
      )}
      <GridItem>
        <Text
          variant="article-20"
          className={classNames(styles.quote, textStyles)}
          color="charcoal-3"
        >
          {text}
        </Text>
      </GridItem>
      {!doubleQuotesOnTop && (
        <GridItem>
          <DoubleQuote className={styles.rotateQuote} />
        </GridItem>
      )}
      {author && (
        <GridItem>
          <Text
            variant="heading-16"
            color="charcoal-3"
            className={styles.author}
          >
            {author}
          </Text>
        </GridItem>
      )}
    </Grid>
  );
};
