import bowlIngredients from 'src/assets/images/bowl-ingredients.png';
import bowlMixed from 'src/assets/images/bowl-mixed.png';
import styles from './ExitIntent.module.scss';

export function ExitIntentImages() {
  return (
    <figure className={styles.bowlGraphic}>
      <img
        src={bowlIngredients}
        alt="bowl-ingredients"
        className={styles.leftBowl}
      />
      <img src={bowlMixed} alt="bowl-mixed" className={styles.rightBowl} />
    </figure>
  );
}
