import { PATH_HOME, PATH_SIGNUP_CHECKOUT } from '@farmersdog/constants/paths';
import { trackSpotifyEvent } from './trackSpotifyEvent';
import { SpotifyEvent } from './types';

const SPOTIFY_PAGE_VIEW_ROUTES = [PATH_HOME, PATH_SIGNUP_CHECKOUT];

/**
 * track a Spotify lead event
 */
export function trackSpotifyPageView(pathname: string): void {
  if (SPOTIFY_PAGE_VIEW_ROUTES.includes(pathname)) {
    trackSpotifyEvent({ name: SpotifyEvent.PageView });
  }
}
