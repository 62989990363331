import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import requestPasswordResetMutation from '@/account/app/graphql/auth/mutations/requestPasswordReset.graphql';
import type {
  RequestPasswordResetInput,
  RequestPasswordResetResponse,
  MutationRequestPasswordResetArgs,
} from '@/account/graphql/auth/types';
import { GraphQLEndpoints } from '@/account/graphql/constants';

import type { ApolloError } from '@apollo/client';

interface UseResetPasswordArgs {
  onCompleted?: (data: RequestPasswordResetResponse) => void;
  onError?: (error: ApolloError) => void;
}

type UseRequestPasswordReset = [
  (input: RequestPasswordResetInput) => Promise<void>,
  {
    loading: boolean;
    error?: ApolloError;
  },
];

export function useRequestPasswordReset({
  onCompleted,
  onError,
}: UseResetPasswordArgs = {}): UseRequestPasswordReset {
  const [requestPasswordReset, requestPasswordResetState] = useMutation<
    RequestPasswordResetResponse,
    MutationRequestPasswordResetArgs
  >(requestPasswordResetMutation, {
    context: { endpoint: GraphQLEndpoints.AUTH },
    onCompleted,
    onError,
  });

  const submit = useCallback(
    async (input: RequestPasswordResetInput) => {
      await requestPasswordReset({
        variables: { input },
      });
    },
    [requestPasswordReset]
  );

  return [submit, requestPasswordResetState];
}
