import { PreloadLink, PreloadLinkAs, PreloadLinkType } from '../PreloadLink';

import { turkeyBowls, kibbleBowls } from './assets';

export function AssetPreloader() {
  const [, turkeyBowlJp2, turkeyBowlPng] = turkeyBowls;
  const [, kibbleBowlJp2, kibbleBowlPng] = kibbleBowls;

  return (
    <>
      <PreloadLink
        href={turkeyBowlJp2}
        as={PreloadLinkAs.Image}
        type={PreloadLinkType.Jpg}
      />
      <PreloadLink
        href={turkeyBowlPng}
        as={PreloadLinkAs.Image}
        type={PreloadLinkType.Png}
      />
      <PreloadLink
        href={kibbleBowlJp2}
        as={PreloadLinkAs.Image}
        type={PreloadLinkType.Jpg}
      />
      <PreloadLink
        href={kibbleBowlPng}
        as={PreloadLinkAs.Image}
        type={PreloadLinkType.Png}
      />
    </>
  );
}
