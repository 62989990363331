import { GridItem, Text, Nowrap } from '@farmersdog/corgi-x';
import styles from './HeroTitle.module.css';
import { ExtendedText } from '../../../ExtendedText';

export const HeroTitle = () => (
  <GridItem>
    <ExtendedText
      as="h1"
      bold
      color="Kale3"
      variant="heading-36"
      align="center"
      bottomSpacing="sm"
    >
      The recipe for a
      <br /> long healthy life
    </ExtendedText>
    <Text
      as="p"
      color="Kale3"
      variant="heading-16"
      align="center"
      vSpacing="none"
      className={styles.heroSubtitle}
    >
      <Nowrap>Made by Board-Certified </Nowrap>
      <Nowrap>Vet Nutritionists.</Nowrap>
      <br />
      <Nowrap>Plans tailored just </Nowrap>
      <Nowrap>for your dog.</Nowrap>
    </Text>
  </GridItem>
);
