import type { OnTosaCompleted } from '@farmersdog/tosa';
import { useDispatch } from 'react-redux';
import { fetchPreviousUser } from 'src/actions/signup';

import { useThrowToErrorBoundary } from 'src/hooks/useThrowToErrorBoundary';
import { loginWithToken } from 'src/actions/auth';
import { useCallback } from 'react';

export function useHandleTosaCompleted(): OnTosaCompleted {
  const dispatch = useDispatch();
  const throwToErrorBoundary = useThrowToErrorBoundary();

  const handleTosaCompleted: OnTosaCompleted = useCallback(
    async ({ registeredUserResponse }) => {
      if (!registeredUserResponse) {
        return throwToErrorBoundary(new Error('Cannot find user'));
      }

      // @ts-expect-error This is the same type. It is disagreeing because we
      // have not fully typed the register response from TOSA
      const user: Reducer.User & { token: string } = registeredUserResponse;

      // Persist token in cookie for use outside of TOSA
      dispatch(loginWithToken(user.token));
      // Ensure clean signup state by recovering with existing user before navigating to website
      await dispatch(fetchPreviousUser(user.email));
    },
    [dispatch, throwToErrorBoundary]
  );

  return handleTosaCompleted;
}
