interface GetSpotifyScriptReturn {
  fallbackScript: string;
  sdkLoader: string;
}

export function getSpotifyScript(key: string): GetSpotifyScriptReturn {
  const fallbackScript = `
  (function(w){
    w.spdt = w.spdt || function() { (w.spdt.q = w.spdt.q || []).push(arguments); };
  })(window);
`;

  const sdkLoader = `
  (function(w, d){
    var id='spdt-capture', n='script';
    if (!d.getElementById(id)) {
      var e = d.createElement(n); e.id = id; e.async=1;
      e.src = 'https://pixel.byspotify.com/ping.min.js';
      var s = d.getElementsByTagName(n)[0];
      s.parentNode.insertBefore(e, s);
    }
    w.spdt('conf', { key: '${key}' });
  })(window, document);
`;

  return { fallbackScript, sdkLoader };
}
