import { useGetLead } from '../../graphql/queries';

import type { UseFeatureHook, UseFeatureHookReturn } from '../../types';
import type { FeatureName } from '../../utils';

interface UseTosaUserFeatureProps {
  featureName: FeatureName;
  attributes?: Record<string, unknown>;
  useFeature: UseFeatureHook;
  lazy?: boolean;
}

export function useTosaUserFeature({
  useFeature,
  featureName,
  attributes,
  lazy,
}: UseTosaUserFeatureProps): UseFeatureHookReturn {
  const userId = useGetLead().data?.lead?.corePostgresUserId;
  const stringUserId = userId ? String(userId) : undefined;

  return useFeature(featureName, { attributes, lazy }, stringUserId);
}
