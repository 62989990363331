import { useState, useCallback } from 'react';

import {
  Button,
  Divider,
  Grid,
  GridItem,
  Text,
  ToggleCard,
} from '@farmersdog/corgi-x';

import { LightboxId, useGlobalLightbox } from '../../../hooks';
import { useFetchQuote } from '../../../hooks/useFetchQuote';
import { bowlPictureSources } from '../../images/RecipeBowlPicture/assets';
import RecipeModal from '../../RecipeModal/RecipeModal';
import { useCheapestQuotesV2 } from '../hooks/useCheapestQuotesV2';
import { getQuoteData } from '../utils/getQuoteData';
import { FRESH_PRODUCT_LINES } from '../utils/overwriteWithCheapestSelection';

import { HeadingText } from './HeadingText';
import styles from './RecipesSelectionV2.module.css';
import { SubmitButton } from './SubmitButton';

import type {
  FetchLeadQuery,
  FetchQuoteQuery,
  FreshRecipeRecommendation,
} from '../../../graphql/types';
import type { PetRecipes } from '../../../types';
import type { ChangeEvent } from 'react';

export interface RecipesSelectionProps {
  returnToRecommendedPlan: () => void;
  currentPetRecipeSelection: string[];
  onSubmit: (quote: FetchQuoteQuery['fetchQuote'] | null) => void;
  isFormSubmitting: boolean;
  handleSelectionChange: (e: ChangeEvent<HTMLInputElement>) => void;
  petName: string;
  pets: FetchLeadQuery['lead']['pets'];
  availablePetRecipesMap: PetRecipes;
}

export const MAX_SELECTED_RECIPES = 3;

export function RecipesSelectionV2({
  returnToRecommendedPlan,
  currentPetRecipeSelection,
  onSubmit,
  isFormSubmitting,
  handleSelectionChange,
  petName,
  pets,
  availablePetRecipesMap,
}: RecipesSelectionProps) {
  const recipes = availablePetRecipesMap[petName];

  const { open } = useGlobalLightbox({
    id: LightboxId.RecipeDetailModal,
  });

  const [selectedRecipeForDetailsModal, setSelectedRecipeForDetailsModal] =
    useState<string>();

  const handleSeeDetails = useCallback(
    (recipe: FreshRecipeRecommendation) => {
      setSelectedRecipeForDetailsModal(recipe.name);
      open();
    },
    [open]
  );

  const { cheapestCoreQuote, cheapestLpfQuote } = useCheapestQuotesV2({
    currentPetName: petName,
    availablePetRecipesMap,
    pets,
  });

  const { quote, quoteQueryState } = useFetchQuote({
    currentPetName: petName,
    availablePetRecipesMap,
    currentPetRecipeSelection,
    pets,
  });

  const { petDailySubtotal, petDailyDiscountedSubtotal, discountPercentage } =
    getQuoteData({
      quote,
      currentPetName: petName,
    });

  const { petDailySubtotal: cheapestCorePetDailySubtotal } = getQuoteData({
    quote: cheapestCoreQuote,
    currentPetName: petName,
  });
  const { petDailySubtotal: cheapestLpfPetDailySubtotal } = getQuoteData({
    quote: cheapestLpfQuote,
    currentPetName: petName,
  });

  const lpfRecipes = recipes.filter(
    recipe => recipe.content.productLine === FRESH_PRODUCT_LINES.lpf
  );

  const nonLpfRecipes = recipes.filter(
    recipe => recipe.content.productLine !== FRESH_PRODUCT_LINES.lpf
  );

  const areMoreThanOneLpfRecipes = lpfRecipes.length > 1;
  const recipeForDisplayInDetailsModal = recipes.find(
    recipe => recipe.name === selectedRecipeForDetailsModal
  );
  const recommendedMultipleRecipes =
    availablePetRecipesMap[petName].filter(recipe => recipe.recommended)
      .length > 1;

  const handleSubmit = useCallback(() => {
    onSubmit(quote);
  }, [onSubmit, quote]);

  return (
    <>
      {recipeForDisplayInDetailsModal && (
        <RecipeModal
          recipe={recipeForDisplayInDetailsModal}
          petName={petName}
        />
      )}
      <Grid
        justifyContent="flex-start"
        alignItems="center"
        flexDirection="column"
        vSpacing="lg"
        className={styles.recipesBackgroundContainer}
      >
        <HeadingText
          header={`Build ${petName}’s Plan`}
          subheader={`Choose up to ${MAX_SELECTED_RECIPES} Recipes`}
        />

        <Grid justifyContent={'center'}>
          <Text
            className={styles.recipesCopy}
            variant="article-12"
            align={'center'}
          >
            The recipe{recommendedMultipleRecipes && 's'} we picked{' '}
            {recommendedMultipleRecipes ? 'are' : 'is'} most popular for dog
            profiles similar to {petName}’s, but all the options below are
            recommended.
          </Text>
        </Grid>

        {lpfRecipes.length > 0 ? (
          <Grid
            className={styles.gridContainer}
            columnGap={areMoreThanOneLpfRecipes ? 'lg' : 'xs'}
          >
            <GridItem
              className={styles.gridItem}
              xl={areMoreThanOneLpfRecipes ? 5 : 3}
            >
              <div
                data-testid="lpf-recipes-container"
                className={
                  areMoreThanOneLpfRecipes
                    ? styles.dividerWrapper
                    : styles.oneRecipeDividerWrapper
                }
              >
                <PriceDivider
                  text={`Starting at ${cheapestLpfPetDailySubtotal}/day`}
                />
              </div>
              <Grid
                columnGap="md"
                className={styles.recipeOptionsContainer}
                justifyContent={{ xs: 'flex-start', md: 'center' }}
              >
                {lpfRecipes.map(recipe => {
                  return (
                    <ClickableRecipe
                      key={recipe.name}
                      recipe={recipe}
                      handleSelectionChange={handleSelectionChange}
                      currentPetRecipeSelection={currentPetRecipeSelection}
                      handleSeeDetails={handleSeeDetails}
                    />
                  );
                })}
              </Grid>
            </GridItem>
            <GridItem
              className={styles.gridItem}
              xl={areMoreThanOneLpfRecipes ? 7 : 9}
            >
              <PriceDivider
                text={`Starting at ${cheapestCorePetDailySubtotal}/day`}
              />

              <Grid
                justifyContent={{
                  md: 'center',
                  xl: 'flex-start',
                }}
                columnGap="md"
                className={styles.recipeOptionsContainer}
              >
                {nonLpfRecipes.map(recipe => {
                  return (
                    <ClickableRecipe
                      key={recipe.name}
                      recipe={recipe}
                      handleSelectionChange={handleSelectionChange}
                      currentPetRecipeSelection={currentPetRecipeSelection}
                      handleSeeDetails={handleSeeDetails}
                    />
                  );
                })}
              </Grid>
            </GridItem>
          </Grid>
        ) : (
          <Grid
            columnGap="sm"
            className={styles.recipeOptionsContainer}
            justifyContent="center"
          >
            {recipes.map(recipe => {
              return (
                <ClickableRecipe
                  key={recipe.name}
                  recipe={recipe}
                  handleSelectionChange={handleSelectionChange}
                  currentPetRecipeSelection={currentPetRecipeSelection}
                  handleSeeDetails={handleSeeDetails}
                />
              );
            })}
          </Grid>
        )}

        <GridItem md={12} justifyContent="center" alignItems={'center'}>
          <Button
            type="button"
            variant="plain-text"
            onClick={returnToRecommendedPlan}
            className={styles.returnButton}
          >
            <Text as="h3" variant="heading-16" color="carrot-2">
              Back to Recommended Plan
            </Text>
          </Button>
        </GridItem>
        <SubmitButton
          currentSelection={currentPetRecipeSelection}
          isQuoteLoading={quoteQueryState.loading}
          hasSelectedRecipes={Boolean(currentPetRecipeSelection?.length > 0)}
          onClick={handleSubmit}
          formSubmitting={isFormSubmitting}
          petDailySubtotal={petDailySubtotal}
          petDailyDiscountedSubtotal={petDailyDiscountedSubtotal}
          discountPercentage={discountPercentage}
        />
      </Grid>
    </>
  );
}

const PriceDivider = ({ text }: { text: string }) => (
  <div className={styles.priceWrapper}>
    <Text bold variant="heading-16" className={styles.priceText}>
      {text}
    </Text>
    <div className={styles.priceDivider}>
      <Divider width={4} spacing={11} color="Charcoal0" borderStyle="dotted" />
    </div>
  </div>
);

const ClickableRecipe = ({
  recipe,
  handleSelectionChange,
  currentPetRecipeSelection,
  handleSeeDetails,
}: {
  recipe: FreshRecipeRecommendation;
  handleSelectionChange: (e: ChangeEvent<HTMLInputElement>) => void;
  currentPetRecipeSelection: string[];
  handleSeeDetails: (recipe: FreshRecipeRecommendation) => void;
}) => (
  <GridItem
    xl={12}
    md={3}
    xs={6}
    key={recipe.name}
    className={styles.recipeToggleCard}
    alignItems="stretch"
    growContent
  >
    <ToggleCard
      heading={recipe.content.displayName}
      description={recipe.content.mainIngredients}
      imageSources={bowlPictureSources[recipe.name]}
      imageAlt={`bowl of ${recipe.content.displayName} recipe food`}
      badge={recipe.recommended ? 'Our Pick' : undefined}
      label="Add"
      checkedLabel={'Added'}
      onChange={handleSelectionChange}
      checked={currentPetRecipeSelection.includes(recipe.name)}
      value={recipe.name}
      buttonLabel={'See Details'}
      buttonProps={{
        className: styles.detailsButton,
        type: 'button',
      }}
      onButtonClick={() => handleSeeDetails(recipe)}
      contentClassName={styles.contentContainer}
      controlClassName={styles.controlContainer}
    />
  </GridItem>
);
