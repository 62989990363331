import { useLocation } from 'react-router';

import { useCoupon, DiscountType } from '@farmersdog/coupons';
import { isUserReferrerResponse } from '@farmersdog/coupons/src/utils/discount';

import config from '../../../../config';
import { useAnonymousExperimentsContext } from '../../../../hooks/useAnonymousExperimentsContext';

import { DiscountBannerInner } from './DiscountBannerInner';
import { HigherDiscountBanner } from './HigherDiscount/HigherDiscountBanner';
import { isEligibleForHigherDiscountBanner } from './HigherDiscount/hooks/isEligibleForHigherDiscountBanner';
import { deriveBannerProps } from './utils';

export function FreshDiscountBanner() {
  const { url, referrerRoute } = config.get('tosa.app.coreApi');
  const { pathname } = useLocation();
  const isRecipesPage = pathname.includes('/recipes');

  const { experiments } = useAnonymousExperimentsContext();
  const { discountAmount, loading, couponCode, coupon } = useCoupon({
    apiUrl: `${url}${referrerRoute}`,
    defaultDiscount: {
      discountType: DiscountType.percentage,
      discountAmount: config.get('features.defaultTrialDiscount'),
    },
  });

  if (loading) {
    return <DiscountBannerInner />;
  }
  // Onboarding frequency cap experiment banner
  if (
    isEligibleForHigherDiscountBanner({
      couponCode,
      discountAmount,
      experiments,
    })
  ) {
    return (
      <HigherDiscountBanner
        isRecipesPage={isRecipesPage}
        discountAmount={discountAmount}
      />
    );
  }

  const referrerName =
    coupon && isUserReferrerResponse(coupon) ? coupon.name : undefined;

  const { copy, linkCopy, linkPath, className, color } = deriveBannerProps({
    experiments,
    discountAmount,
    referrerName,
    couponCode,
    isRecipesPage,
  });

  return (
    <DiscountBannerInner
      copy={copy}
      linkPath={linkPath}
      linkCopy={linkCopy}
      className={className}
      color={color}
    />
  );
}
