import classNames from 'classnames';

import { useCorgiViewport } from 'src/screen';

import styles from './InputGrid.module.scss';

import type { ReactNode } from 'react';

interface InputGridProps {
  children: ReactNode;
  className?: string;
}

export function InputGrid({ children, className }: InputGridProps) {
  const responsiveClassName = useCorgiViewport({
    lg: styles.lg,
    xl: styles.xl,
  });

  return (
    <div className={classNames(responsiveClassName, styles.grid, className)}>
      {children}
    </div>
  );
}
