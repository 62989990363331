import {
  CHICKEN_SNAPS_V2_REGULAR_NUTRITION_FACTS,
  PORK_SNAPS_REGULAR_NUTRITION_FACTS,
  SnapSticksRecipeName,
  TURKEY_SNAPS_V2_REGULAR_NUTRITION_FACTS,
} from './graphql/utils';

import type {
  Node,
  LeafNode,
  BranchNode,
  NodeRegistry,
  CompiledBlueprint,
  ComponentInput,
  LeadFields,
  MaybeNodeInput,
  NodeInput,
  IndexedPetFields,
  IndexedPetBirthdayFields,
  IndexedPetFreshSelectionFields,
  IndexedPetFreshRecommendationFields,
} from './blueprint/types';
import type { FreshRecipeRecommendation } from './graphql/types';
import type { UseFormSubmitState } from './hooks/useFormSubmit';
import type { OnFormCompleted } from './hooks/useHandleFormCompleted';
import type { Progress } from './hooks/useProgress';
import type { ReloadApp } from './hooks/useReloadApp';
import type { FeatureName } from './utils/features';
import type {
  BaseSyntheticEvent,
  ChangeEvent,
  Dispatch,
  SetStateAction,
} from 'react';
import type { UseFormReturn } from 'react-hook-form';
import type { ObjectSchema } from 'yup';

export interface FindCurrentProps {
  formSteps: BranchNode[];
}

export type RoutingAction = 'push' | 'replace';

export interface FormNavigationListener {
  (current: string, options?: { routingAction?: RoutingAction }): void;
}

export interface Unsubscribe {
  (): void;
}

export interface Subscribe {
  (listener: FormNavigationListener): Unsubscribe;
}

export interface GoToStepOptions {
  propagate?: boolean;
  routingAction?: RoutingAction;
}

export interface UseFormNavigateReturn {
  subscribe: Subscribe;
  current: string | undefined;
  setNext: (props: FindCurrentProps) => void;
  setPrevious: (props: FindCurrentProps) => void;
  findCurrentIndex: (props: FindCurrentProps) => number;
  findNext: (props: FindCurrentProps) => string | undefined;
  findPrevious: (props: FindCurrentProps) => string | undefined;
  getIsOnFirstBranch: (props: FindCurrentProps) => boolean;
  goToStep(
    this: void,
    stepId: BranchNode['__self__'],
    options?: GoToStepOptions
  ): void;
}

export interface RestoreCachedLeadArgs {
  reset: UseForm['reset'];
  blueprint: UseSchemaReturn['schema'];
  setFormSteps: UseSchemaReturn['setFormSteps'];
  experiments: Experiments;
}

export interface UseSchemaReturn {
  schema: CompiledBlueprint | undefined;
  registry: NodeRegistry | undefined;
  formSteps: BranchNode[];
  setFormSteps: SetFormStepsType;
}

export interface UpdateSchemaWithFragmentsProps {
  fragmentCount: number;
  fragments: Array<UpdateSchemaFragments>;
  index?: number;
  schema: CompiledBlueprint;
}

export interface UpdateSchemaFragments {
  /* Indicates which fragment to use when a question Node is answered. */
  source: string;
  /* Indicates which anchor branch to assign this fragment to. Only applied to fragments. */
  target: string;
  /* Indicates whether the target is indexed. */
  indexedTarget?: boolean;
}

export interface DownshiftEvent {
  target: {
    name: string;
    value: string | number;
  };
}

export interface OnSubmitHandler {
  (data: FormFieldsType, e?: BaseSyntheticEvent): Promise<void> | void;
}

export interface NodeInputOptions {
  name: string;
  value: string | number;
  description?: string;
}

export interface TOSANodeInput extends NodeInput {
  label: string;
  options?: NodeInputOptions[];
  inline?: boolean;
  default?: string | number;
  hint?: string;
  placeholder?: string;
  accessibleLabel?: string;
  ariaDescribedBy?: string;
  excludeFromProgress?: boolean;
  errorMap: Record<string, string>;
}

/* Special type for expanding Leaf Node functionality only needed on client side. */
export interface TOSALeafNode extends LeafNode {
  input?: MaybeNodeInput<TOSANodeInput>;
  name: keyof FormFieldsType;
}

export interface UseFeatureHookReturn {
  isReady: boolean;
  treatment: string;
  config: Record<string, unknown>;
  getFeatureData: () => Record<string, unknown>;
}

export type UseTosaUserFeatureHook = (
  featureName: FeatureName,
  attributes: Record<string, unknown>,
  useFeature: UseFeatureHook
) => UseFeatureHookReturn;

export type UseFeatureHook = (
  name: string,
  options?: { attributes?: Record<string, unknown>; lazy?: boolean },
  splitKeyOverride?: string
) => UseFeatureHookReturn;

export type Experiments = Record<
  FeatureName | string,
  UseFeatureHookReturn | undefined
>;

export interface UseTreatsInCheckoutUiReturn {
  shouldShowTreatsPage: boolean;
  shouldShowTreatsOnCheckoutPage: boolean;
  shouldRemoveTreatsPage: boolean;
  getFeatureData: () => Record<string, unknown>;
}

export interface PetRecipes {
  [key: string]: FreshRecipeRecommendation[];
}

export type FormValidationSchema = ObjectSchema<Record<string, unknown>>;

/* Special type for expanding ComponentInput functionality only needed on client side. */
export interface TOSAComponentInput<T extends Node> extends ComponentInput<T> {
  formMethods: UseForm;
  formSteps: BranchNode[];
  setFormSteps: SetFormStepsType;
  formNavigation: UseFormNavigateReturn;
  onChange?: (e: ChangeEvent<HTMLInputElement> | DownshiftEvent) => void;
  onSubmit?: OnSubmitHandler;
  onFormCompleted?: OnFormCompleted;
  progress: Progress;
  formSubmitRequest: UseFormSubmitState;
  formValidationSchema: FormValidationSchema;
  reloadApp: ReloadApp;
  useFeature: UseFeatureHook;
  experiments: Experiments;
  petRecipes: PetRecipes;
  setPetRecipes: Dispatch<SetStateAction<PetRecipes>>;
  setErrorModalMessage: (message: string) => void;
  clearAvailablePetRecipesCache: () => void;
  clearSignupUser: () => void;
}

export type TOSAComponent =
  | (<N extends Node>(props: TOSAComponentInput<N>) => JSX.Element | null)
  | (<N extends BranchNode>(props: TOSAComponentInput<N>) => JSX.Element | null)
  | (<N extends LeafNode>(props: TOSAComponentInput<N>) => JSX.Element | null)
  | (<N extends TOSALeafNode>(
      props: TOSAComponentInput<N>
    ) => JSX.Element | null);

export type TOSATreeIndex = number[];

export type NoSubmitFields = {
  numPets: number;
  phoneConsent: boolean;
  healthy: string;
  prescriptionDiet: string;
} & IndexedPetBirthdayFields &
  IndexedPetFreshSelectionFields &
  IndexedPetFreshRecommendationFields;

export type FormFieldsType = Partial<LeadFields> &
  Partial<IndexedPetFields> &
  Partial<NoSubmitFields>;

export type UseForm = UseFormReturn<FormFieldsType>;

export type SetFormStepsType = (blueprint: CompiledBlueprint) => BranchNode[];

export type ExtendedTextInputChangeEvent = ChangeEvent<HTMLInputElement> & {
  nativeEvent: {
    inputType: string;
    data: string | null;
  };
};

export interface BreedType extends NodeInputOptions {
  label: string;
  name: string;
  value: string;
}

export interface NutritionFactsContent {
  ingredients: string;
  ingredientsSub: string;
  guaranteedAnalysis: { item: string; percentage: string }[];
  calculatedCalorieContent: string[];
}

export type TreatsInCheckoutTreatName =
  | SnapSticksRecipeName.ChickenV2
  | SnapSticksRecipeName.TurkeyV2
  | SnapSticksRecipeName.Pork;

export const treatNutritionFactsByName: Record<
  TreatsInCheckoutTreatName,
  NutritionFactsContent
> = {
  [SnapSticksRecipeName.ChickenV2]: CHICKEN_SNAPS_V2_REGULAR_NUTRITION_FACTS,
  [SnapSticksRecipeName.TurkeyV2]: TURKEY_SNAPS_V2_REGULAR_NUTRITION_FACTS,
  [SnapSticksRecipeName.Pork]: PORK_SNAPS_REGULAR_NUTRITION_FACTS,
};
