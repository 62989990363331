import sumBy from 'lodash/sumBy';
import { useEffect, useState } from 'react';

import { usePetRecipes } from '../../../graphql/queries/usePetRecipes';
import { useThrowToErrorBoundary } from '../../../hooks/useThrowToErrorBoundary';

import { usePetNamesAndRecommendedCalories } from './usePetNamesAndRecommendedCalories';

import type { PetRecipes } from '../../../types';

export function useFetchPetsAvailableRecipes() {
  const [availablePetRecipesMap, setAvailablePetRecipesMap] =
    useState<PetRecipes>();

  const { refetch: petRecipesQuery } = usePetRecipes({
    errorPolicy: 'all',
    skip: true,
  });
  const throwToErrorBoundary = useThrowToErrorBoundary();

  const { petNamesAndRecommendedCalories } =
    usePetNamesAndRecommendedCalories();

  useEffect(() => {
    if (!petNamesAndRecommendedCalories) return;

    const allPetsCaloriesSum = sumBy(
      petNamesAndRecommendedCalories,
      'requestedCalories'
    );

    async function fetchAvailableRecipesForPet(petName: string) {
      try {
        const { data, error } = await petRecipesQuery({
          petName: petName.trim(),
          allPetsCaloriesSum,
        });

        if (error) {
          return throwToErrorBoundary(error);
        }

        if (data) {
          setAvailablePetRecipesMap(prev => {
            return {
              ...prev,
              [petName]: data.petRecipes,
            };
          });
        }
      } catch (error) {
        return throwToErrorBoundary(error);
      }
    }

    petNamesAndRecommendedCalories.forEach(({ petName }) =>
      fetchAvailableRecipesForPet(petName)
    );
  }, [petNamesAndRecommendedCalories, petRecipesQuery, throwToErrorBoundary]);

  const hasFetchedAllRecipeRecommendations =
    petNamesAndRecommendedCalories &&
    petNamesAndRecommendedCalories
      .map(({ petName }) => petName)
      .every(petName => {
        const recipes = availablePetRecipesMap?.[petName];
        return recipes && recipes.length > 0;
      });

  return {
    availablePetRecipesMap: hasFetchedAllRecipeRecommendations
      ? availablePetRecipesMap
      : undefined,
  };
}
