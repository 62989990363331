import {
  PageWidth,
  Text,
  Grid,
  Link,
  Picture,
  Nowrap,
  useViewport,
  GridItem,
} from '@farmersdog/corgi-x';
import config from 'src/config';
import classNames from 'classnames';

import { Quote } from '../Quote';

import vetsKnowBest from '../../assets/vets-know-best.png';
import TripleUnderline from '../../assets/triple-underline.svg';
import CheckMark from '../../assets/checkmark.svg';
import Certification from '../../assets/certification.svg';
import CertificationStethoscope from '../../assets/certification-stethoscope.svg';
import APOPLogo from '../../assets/apop-logo.png';
import WSAVALogo from '../../assets/wsava-logo.png';

import styles from './VetsKnowBest.module.css';

const QUOTE_TEXT = (
  <>
    No animal’s digestive system is designed to eat high-processed food every
    meal of their lives. The Farmer’s Dog makes it easy to feed{' '}
    <Nowrap>
      something
      <span className={styles.underlinedSubject}>
        {' '}
        better.
        <TripleUnderline className={styles.underline} />
      </span>
    </Nowrap>
  </>
);

interface Certification {
  icon: JSX.Element;
  text: string;
}

const CERTIFICATIONS: Certification[] = [
  {
    icon: <CertificationStethoscope />,
    text: 'On-staff veterinarians and board-certified nutritionists',
  },
  {
    icon: <Certification />,
    text: 'AAFCO-certified complete and balanced nutrition',
  },
  {
    icon: <CheckMark />,
    text: 'Made and tested in FDA-compliant facilities',
  },
];

export const VetsKnowBest = () => {
  const viewport = useViewport();
  const isMobile = !viewport?.lg;

  return (
    <PageWidth className={styles.vetsKnowBestWrapper}>
      <div>
        {/* TODO: replace this placeholder image with video once we have it. */}
        <img
          src={vetsKnowBest}
          className={styles.placeholderImage}
          alt="Vets Know Best"
        />
      </div>
      <div className={styles.testimonialContainer}>
        <Text
          variant={isMobile ? 'heading-28' : 'heading-40'}
          color="kale-3"
          bold
        >
          Vets know best
        </Text>
        <Quote
          className={styles.quote}
          text={QUOTE_TEXT}
          author="Dr. Alex Schechter"
          doubleQuotesOnTop
          textStyles={styles.quoteText}
          rotateQuote={!isMobile}
          mirrorQuote={!isMobile}
        />
      </div>
      <Grid
        flexDirection={isMobile ? 'column' : 'row'}
        gap={isMobile ? 'md' : 'xxl'}
        justifyContent="center"
      >
        {CERTIFICATIONS.map(({ icon, text }, index) => (
          <GridItem
            key={`${text} - ${index}`}
            sm={12}
            lg={4}
            className={classNames(styles.certificationContainer, {
              [styles.withBottomBorder]: index < CERTIFICATIONS.length - 1,
            })}
          >
            <div className={styles.iconContainer}>{icon}</div>
            <Text
              as="div"
              variant="heading-16"
              color="kale-3"
              className={styles.certificationLabel}
              topSpacing={isMobile ? 'none' : 'sm'}
              align={isMobile ? 'left' : 'center'}
            >
              {text}
            </Text>
          </GridItem>
        ))}
      </Grid>
      <Grid
        className={styles.associationLogos}
        justifyContent="center"
        gap="sm"
      >
        <Picture
          sources={[WSAVALogo]}
          alt="World Small Animal Veterinary Association Logo"
        />
        <Picture
          sources={[APOPLogo]}
          alt="Association for Pet Obesity Prevention Logo"
        />
      </Grid>
      <div className={styles.vetProfessional}>
        <Text as="div" align="center">
          Are you a vet professional?
        </Text>
        <Text as="div" align="center">
          Visit our{' '}
          <Link
            to={`${config('app.vetPortalUrl')}`}
            className={styles.vetPortalLink}
          >
            Vet Team Portal
          </Link>
        </Text>
      </div>
    </PageWidth>
  );
};
