import type { FC } from 'react';
import { SdkLoaderWithFallbackScript } from 'src/components/Script/SdkLoaderWithFallbackScript';

export const SPOTIFY_PIXEL_KEY = 'ea14977d871541fcb5bc2dc933ae1b0a';

const fallbackScript = `
  (function(w){
    w.spdt = w.spdt || function() { (w.spdt.q = w.spdt.q || []).push(arguments); };
  })(window);
`;

const sdkLoader = `
  (function(w, d){
    var id='spdt-capture', n='script';
    if (!d.getElementById(id)) {
      var e = d.createElement(n); e.id = id; e.async=1;
      e.src = 'https://pixel.byspotify.com/ping.min.js';
      var s = d.getElementsByTagName(n)[0];
      s.parentNode.insertBefore(e, s);
    }
    w.spdt('conf', { key: '${SPOTIFY_PIXEL_KEY}' });
  })(window, document);
`;

export const SpotifyScript: FC = () => {
  return (
    <SdkLoaderWithFallbackScript
      sdkLoader={sdkLoader}
      fallbackScript={fallbackScript}
    />
  );
};
