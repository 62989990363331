import { ContentReveal } from '@farmersdog/corgi-x';

import { DropdownInput } from '../base/DropdownInput';

import type { TOSAComponentInput, TOSALeafNode } from '../../../types';

export function TreatsQuantityInput(props: TOSAComponentInput<TOSALeafNode>) {
  const { node, progress } = props;

  const shouldRevealInput = progress.getPreviousLeafComplete(node.__self__);

  const overrideLabel = node.input?.label;

  return (
    <ContentReveal in={shouldRevealInput} inAnimation="fade">
      <div>
        <DropdownInput {...props} overrideLabel={overrideLabel} />
      </div>
    </ContentReveal>
  );
}
