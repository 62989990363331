import isNil from 'lodash/isNil';
import { useState } from 'react';
import { useParams } from 'react-router';

import { useStripeCreateToken } from '@farmersdog/corgi-x';

import { ApplicationError, ValidationError } from 'src/errors';

import type { State } from '@/account/graphql/anon/types';

import { useUpdatePaymentMethodMutation } from './useUpdatePaymentMethodMutation';

export type FormValues = {
  cardHolder?: string;
  cardNumberComplete?: boolean;
  expiryComplete?: boolean;
  cvcComplete?: boolean;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: State;
  zip?: string;
};

type RouteParams = {
  token: string;
};

export function useUpdatePaymentMethod() {
  const [formValues, setFormValues] = useState<FormValues>({});
  const [isLoadingStripeToken, setIsLoadingStripeToken] = useState(false);
  const createToken = useStripeCreateToken();
  const {
    updatePaymentMethodMutation,
    success,
    loading: isLoadingMutation,
    error: mutationError,
  } = useUpdatePaymentMethodMutation();

  const { token } = useParams<RouteParams>();

  const updateFormValue = <T extends keyof FormValues>(
    fieldName: keyof FormValues,
    value: FormValues[T]
  ) => {
    setFormValues(prev => ({ ...prev, [fieldName]: value }));
  };

  const canSubmit = (
    values: FormValues
  ): values is Required<FormValues> & { state: { abbr: string } } => {
    return Boolean(
      !isNil(values.cardHolder) &&
        values.cardHolder.trim() !== '' &&
        values.cardNumberComplete &&
        values.expiryComplete &&
        values.cvcComplete &&
        !isNil(values.addressLine1) &&
        values.addressLine1.trim() !== '' &&
        !isNil(values.city) &&
        values.city.trim() !== '' &&
        !isNil(values.state?.abbr) &&
        !isNil(values.zip) &&
        values.zip.trim() !== ''
    );
  };

  const submit = async () => {
    if (!canSubmit(formValues)) return;

    setIsLoadingStripeToken(true);
    const stripeResponse = await createToken({
      name: formValues.cardHolder,
      address_line1: formValues.addressLine1,
      address_line2: formValues.addressLine2,
      address_city: formValues.city,
      address_state: formValues.state.abbr,
      address_zip: formValues.zip,
      address_country: 'US',
    });
    setIsLoadingStripeToken(false);

    if (!stripeResponse) {
      throw new ApplicationError('Uh oh something went wrong.');
    }

    const { error, token: stripeToken } = stripeResponse;

    if (error?.type === 'validation_error' && error?.message) {
      throw new ValidationError(error.message, 'card');
    }

    if (error) {
      throw new ApplicationError('Uh oh something went wrong.');
    }

    if (!stripeToken) {
      throw new ApplicationError('Uh oh something went wrong.');
    }

    await updatePaymentMethodMutation({
      token,
      stripeToken: stripeToken.id,
    });
  };

  return {
    formValues,
    updateFormValue,
    canSubmit,
    submit,
    isLoading: isLoadingStripeToken || isLoadingMutation,
    success,
    error: mutationError,
  };
}
