import classNames from 'classnames';

import {
  Text,
  type TextProps as OriginalTextProps,
  type TextVariant,
} from '@farmersdog/corgi-x';

import styles from './ExtendedText.module.css';

export type ExtendedTextVariant =
  | TextVariant
  | 'spaced-12'
  | 'spaced-16'
  | 'article-24'
  | 'heading-36';

export type ExtendedTextProps = Omit<OriginalTextProps, 'variant'> & {
  variant?: ExtendedTextVariant;
};

const getTextVariantClassName = (variant?: ExtendedTextVariant) => {
  if (variant === 'spaced-12') return styles.spaced12;
  if (variant === 'spaced-16') return styles.spaced16;
  if (variant === 'article-24') return styles.article24;
  if (variant === 'heading-36') return styles.heading36;
  return '';
};

export const ExtendedText = ({
  variant,
  className,
  ...rest
}: ExtendedTextProps) => {
  return (
    <Text
      variant={variant as TextVariant}
      {...(rest as OriginalTextProps)}
      className={classNames(getTextVariantClassName(variant), className)}
    />
  );
};
