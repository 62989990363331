import { useState } from 'react';
import {
  Grid,
  GridItem,
  PageWidth,
  Picture,
  Text,
  useViewport,
} from '@farmersdog/corgi-x';
import { PATH_SIGNUP_ME, PATH_REVIEWS } from '@farmersdog/constants/paths';
import { getLinkToFreshSignup } from '@farmersdog/tosa/src/utils/getLinkToSignupFlow';

import VideoPlaceholderMobile1 from './assets/video-placeholder-mobile-1.png';
import VideoPlaceholderMobile2 from './assets/video-placeholder-mobile-2.png';
import VideoPlaceholderMobile3 from './assets/video-placeholder-mobile-3.png';
import VideoPlaceholderMobile4 from './assets/video-placeholder-mobile-4.png';
import VideoPlaceholderMobile5 from './assets/video-placeholder-mobile-5.png';
import VideoPlaceholderMobile6 from './assets/video-placeholder-mobile-6.png';
import VideoPlaceholderDesktop1 from './assets/video-placeholder-desktop-1.png';
import VideoPlaceholderDesktop2 from './assets/video-placeholder-desktop-2.png';
import VideoPlaceholderDesktop3 from './assets/video-placeholder-desktop-3.png';
import VideoPlaceholderDesktop4 from './assets/video-placeholder-desktop-4.png';
import VideoPlaceholderDesktop5 from './assets/video-placeholder-desktop-5.png';
import VideoPlaceholderDesktop6 from './assets/video-placeholder-desktop-6.png';

import {
  ExtendedButton,
  ExtendedGallery,
  ExtendedText,
  Quote,
} from '../../components';

import styles from './StoriesFromSection.module.css';

// TODO: replace with real video sources
type PlaceholderSources = {
  mobile: { png: string };
  desktop: { png: string };
};

type VideoGalleryItem = {
  sources: PlaceholderSources;
  alt: string;
  title: string;
  quote: string;
};

const videoItemsData: VideoGalleryItem[] = [
  {
    sources: {
      mobile: { png: VideoPlaceholderMobile1 },
      desktop: { png: VideoPlaceholderDesktop1 },
    },
    alt: 'video-placeholder-1',
    title: 'MEET LULU',
    quote:
      'I identified right away with the ingredients in The Farmer’s Dog. They’ve been a key part of Lulu’s health at 22 years old.',
  },
  {
    sources: {
      mobile: { png: VideoPlaceholderMobile2 },
      desktop: { png: VideoPlaceholderDesktop2 },
    },
    alt: 'video-placeholder-2',
    title: 'MEET KONA & VANDI',
    quote:
      'After a month, Kona started to perk up a lot more, and he lost weight. Seeing that spark in him again was super exciting.',
  },
  {
    sources: {
      mobile: { png: VideoPlaceholderMobile3 },
      desktop: { png: VideoPlaceholderDesktop3 },
    },
    alt: 'video-placeholder-3',
    title: 'MEET KIMCHI',
    quote:
      'When we fed her The Farmer’s Dog food, Kimchi got excited about eating in a way that she wasn’t with any other food.',
  },
  {
    sources: {
      mobile: { png: VideoPlaceholderMobile4 },
      desktop: { png: VideoPlaceholderDesktop4 },
    },
    alt: 'video-placeholder-4',
    title: 'MEET BERNE & URSULA',
    quote:
      'Berne’s much more youthful compared to dogs his own age. He’s in good shape and he’s always active.',
  },
  {
    sources: {
      mobile: { png: VideoPlaceholderMobile5 },
      desktop: { png: VideoPlaceholderDesktop5 },
    },
    alt: 'video-placeholder-5',
    title: 'MEET THE WOLFGANG',
    quote:
      'I switched to The Farmer’s Dog to get away from highly-processed food. All dogs need is love and good nutritious food.',
  },
  {
    sources: {
      mobile: { png: VideoPlaceholderMobile6 },
      desktop: { png: VideoPlaceholderDesktop6 },
    },
    alt: 'video-placeholder-6',
    title: 'MEET ZBO',
    quote:
      'We changed food every week before starting The Farmer’s Dog. Best part is ZBO seems healthier and he likes the taste!',
  },
];

export const StoriesFromSection = () => {
  const linkToFreshSignup = getLinkToFreshSignup(PATH_SIGNUP_ME);
  const [activeItem, setActiveItem] = useState(0);

  const viewport = useViewport();
  const isMobile = viewport.xs && !viewport.md;

  return (
    <div className={styles.wrapper}>
      <section
        aria-labelledby="video-gallery-section"
        className={styles.container}
      >
        <Grid gap="lg">
          <GridItem>
            <Text
              variant={isMobile ? 'heading-28' : 'heading-40'}
              bold
              id="video-gallery-section"
            >
              Stories from {isMobile && <br />}
              over 1 billion meals
            </Text>
          </GridItem>
          <ExtendedGallery
            id="video-gallery"
            label="Video Gallery"
            slideClassname={styles.slide}
            onSlideChange={setActiveItem}
            withLoop
            isWide={!isMobile}
            footnote={
              <PageWidth className={styles.slideTextContainer} key={activeItem}>
                <ExtendedText
                  variant="spaced-16"
                  bold
                  color="kale-3"
                  as="h3"
                  topSpacing="lg"
                  bottomSpacing="sm"
                  className={styles.slideTitle}
                >
                  {videoItemsData[activeItem].title}
                </ExtendedText>
                <Quote
                  className={styles.quote}
                  text={videoItemsData[activeItem].quote}
                />
              </PageWidth>
            }
          >
            {videoItemsData.map(item => (
              <Picture
                key={item.title}
                sources={
                  isMobile
                    ? [item.sources.mobile.png]
                    : [item.sources.desktop.png]
                }
                alt={item.alt}
                className={styles.videoPlaceholder}
              />
            ))}
          </ExtendedGallery>
          <GridItem
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent="center"
            alignItems="center"
            className={styles.buttonsContainer}
          >
            <ExtendedButton
              variant="solid-kale"
              type="link"
              to={linkToFreshSignup}
              className={styles.button}
            >
              Build Your Plan
            </ExtendedButton>
            <ExtendedButton
              variant="bordered-kale"
              type="link"
              to={PATH_REVIEWS}
              className={styles.button}
            >
              See More Stories
            </ExtendedButton>
          </GridItem>
        </Grid>
      </section>
    </div>
  );
};
