export default {
  adjust_daily_calories_ask_to_confirm_changes:
    'Adjust Daily Calories - Ask to confirm changes updated plan',
  adjust_daily_calories_confirmed_changes:
    'Adjust Daily Calories - Confirmed changes',
  // This spelling error is known, but has to stay until we sync with the data
  // team on a strategy for fixing
  adjust_daily_calories_asking_to_swith_to_mixing_plan:
    'Adjust Daily Calories - Asking to swith to mixing plan',
  adjust_daily_calories_choose_full_plan:
    'Adjust Daily Calories - Choose full plan',
  adjust_daily_calories_choose_mixing_plan:
    'Adjust Daily Calories - Choose mixing plan',
  adjust_daily_calories_closed: 'Adjust Daily Calories - Closed',
  adjust_daily_calories_error_while_saving_changes:
    'Adjust Daily Calories - Error while saving changes',
  adjust_daily_calories_opened: 'Adjust Daily Calories - Opened',
  adjust_daily_calories_saved: 'Adjust Daily Calories - Saved',
  adjust_daily_calories_went_back_from_confirming_changes:
    'Adjust Daily Calories - Went back from confirming changes',
  adjust_daily_calories_increased_calories:
    'Adjust Daily Calories - Increased calories',
  adjust_daily_calories_decreased_calories:
    'Adjust Daily Calories - Decreased calories',
  adjust_daily_calories_exit_cta_clicked:
    'Adjust Daily Calories - Exit CTA clicked',
  calibration_subscription_state: 'Calibration - Subscription State',
  calibration_success_page: 'Calibration Success Page',
  calibration_reschedule: 'Calibration - Reschedule',
  calibration_plans: 'Calibration - Plans',
  cancel_subscription_box_size_changed_successfully:
    'Cancel Subscription - Box Size changed successfully',
  cancel_subscription_canceled_are_you_sure_prompt:
    'Cancel Subscription - Canceled "Are you sure?" prompt',
  cancel_subscription_change_box_size: 'Cancel Subscription - Change Box Size',
  cancel_subscription_clicked_change_meals_size:
    'Cancel Subscription - Clicked change Meals Size',
  cancel_subscription_compiled_desired_price:
    'Cancel Subscription - Compiled desired price',
  cancel_subscription_confirmed_are_you_sure_prompt:
    'Cancel Subscription - Confirmed "Are you sure?" prompt',
  cancel_subscription_diy_downsell_cta:
    'Cancel Subscription - DIY Downsell CTA',
  cancel_subscription_meals_size_changed_successfully:
    'Cancel Subscription - Meals Size changed successfully',
  cancel_subscription_flow_start: 'Cancel Subscription - Flow start',
  cancel_subscription_flow_exit: 'Cancel Subscription - Flow exit',
  cancel_subscription_flow_save: 'Cancel Subscription - Flow save',
  cancel_subscription_modal_closed: 'Cancel Subscription - Modal closed',
  cancel_subscription_selected_food_replacement:
    'Cancel Subscription - Selected food replacement',
  cancel_subscription_selected_prescription_diet:
    'Cancel Subscription - Selected Prescription Diet',
  cancel_subscription_selected_reason: 'Cancel Subscription - Selected reason',
  cancel_subscription_subscription_canceled:
    'Cancel Subscription - Subscription Canceled',
  cancel_subscription_user_delayed_the_next_shipment:
    'Cancel Subscription - User delayed the next shipment',
  cancel_diy_subscription_success: 'Cancel DIY Subscription - Success',
  change_order_size_success: 'Change Order Size - Success',
  change_recipes_saved: 'Change Recipes - Saved',
  checkout_after_your_trial_viewed: `Checkout - After Your Trial Viewed`,
  checkout_card_validation_error: 'Checkout - Card validation error',
  checkout_changed_start_date: 'Checkout - Changed start date',
  checkout_checkout_form_submit: 'Checkout - Checkout form submit',
  checkout_click_pay_with_credit_card: 'Checkout - Click Pay with Credit Card',
  checkout_data_validation_failed: 'Checkout - Data validation failed',
  checkout_interacted_with_account_form:
    'Checkout - Interacted with account form',
  checkout_interacted_with_shipping_form:
    'Checkout - Interacted with shipping form',
  checkout_interacted_with_payment_form:
    'Checkout - Interacted with payment form',
  checkout_phone_field_lost_focus: 'Checkout - Phone field lost focus',
  checkout_quotes: 'Checkout - Quotes',
  checkout_success: 'Checkout - Success',
  checkout_daily_price_and_frequency: 'Checkout - Daily Price and Frequency',
  checkout_complete_express_pay: 'Checkout - Complete using Express Pay',
  checkout_submit_skip_express_pay:
    'Checkout - Submit - Express Pay available but not used',
  checkout_express_pay_availability: 'Checkout - Express Pay availability',
  checkout_address_error: 'Checkout - Address Error',
  checkout_stateless_quoting_delta: 'Checkout - Stateless Quoting Delta',
  comms_calibration_complete: 'Calibration - Completed',
  diy_cadence_change_success: 'DIY Cadence Change - Success',
  diy_downsell_success: 'DIY Downsell - Success',
  diy_recipe_details_update: 'DIY Recipe Details - Update',
  exit_intent_completed: 'Exit Intent - Completed',
  exit_intent_selected: 'Exit Intent - Selected',
  exit_intent_shown: 'Exit Intent - Shown',
  forever_video_play: 'Forever Video - Play',
  forever_video_pause: 'Forever Video - Pause',
  forever_video_end: 'Forever Video - End',
  about_us_video_play: 'About Us Video - Play',
  about_us_video_pause: 'About Us Video - Pause',
  about_us_video_end: 'About Us Video - End',
  gladly_sidekick_clicked: 'Gladly Sidekick - Clicked',
  homepage_click_bowl_cta: 'Homepage - Click bowl CTA',
  homepage_click_how_it_works_cta: 'Homepage - click how it works CTA',
  homepage_scroll_past_bowl_section: 'Homepage - scroll past bowl section',
  homepage_success: 'Homepage - Success',
  homepage_vet_section_clicked_vet_portal:
    'Homepage Vet Section - Clicked Vet Portal',
  homepage_vet_section_seen: 'Homepage Vet Section - Seen',
  impact_radius_email_conversion: 'Impact Radius - Email Conversion',
  update_plan_quote: 'Update Plan Quote',
  update_plan_success: 'Update Plan Success',
  update_portions_cta_clicked: 'Update Portions CTA Clicked',
  update_packs_cta_clicked: 'Update Packs CTA Clicked',
  login_challenge_expired: 'Login - Challenge Expired',
  login_challenge_success: 'Login - Challenge Success',
  login_enter_interactive_challenge_mode:
    'Login - Enter Interactive Challenge Mode',
  login_leave_interactive_challenge_mode:
    'Login - Leave Interactive Challenge Mode',
  login_frontend_success: 'Login Frontend - Success',
  request_password_reset_challenge_expired:
    'Request Password Reset - Challenge Expired',
  request_password_reset_challenge_success:
    'Request Password Reset - Challenge Success',
  request_password_reset_enter_interactive_challenge_mode:
    'Request Password Reset - Enter Interactive Challenge Mode',
  request_password_reset_leave_interactive_challenge_mode:
    'Request Password Reset - Leave Interactive Challenge Mode',
  logout_frontend_success: 'Logout Frontend - Success',
  me_success: 'Me - Success',
  meal_plans_success: 'Meal Plans - Success',
  mixing_plan_modal_opened: 'Mixing Plan - Modal Opened',
  mixing_plan_option_selected: 'Mixing Plan - Option Selected',
  mixing_plan_saved: 'Mixing Plan - Saved',
  partner_inbound: 'PARTNER_INBOUND',
  pet_display_next: 'Pets - Display next',
  pet_page_error_shown: 'Pet Page - Error Shown',
  pets_success: 'Pets - Success',
  prices_seen: 'Prices - Seen',
  portal_referral_share_click: 'Portal Referral Share - Click',
  portal_referral_email_submitted: 'Portal Referral - Email Submitted',
  post_checkout_hdyhau: 'Post-Checkout - HDYHAU',
  post_checkout_dyld: 'Post-Checkout - Do you love dogs',
  post_checkout_wtfd: 'Post-Checkout - Why did you choose TFD',
  post_checkout_frequency_click: 'Post-Checkout - Frequency Click',
  post_checkout_daily_price_and_frequency:
    'Post-Checkout - Daily Price and Frequency',
  checkout_success_view_your_account_clicked:
    'checkout success - view your account clicked',
  hdyhau_submit: 'HDYHAU - Submit',
  recipes_display_next_pet: 'Recipes - Display next pet',
  recipes_quotes: 'Recipes - Quotes',
  recipes_plan_details: 'Recipes - Plan Details',
  recipes_success: 'Recipes - Success',
  recipes_viewed_recipe_details: 'Recipes - Viewed Recipe Details',
  reschedule_success: 'Reschedule - Success',
  reschedule_exited: 'Reschedule - Exited',
  reschedule_success_cta_clicked: 'Reschedule - Success - CTA Clicked',
  reschedule_update_plan_subscription_state_success:
    'Reschedule - Update Plan Subscription State',
  reschedule_date_scene_click_continue:
    'Reschedule - Date Scene - Click Continue',
  show_error_message: 'Show error message',
  split_get_treatment: 'Split - get_treatment',
  starttrial: 'StartTrial',
  success_referral_share_click: 'Signup Success Referral Share - click',
  edit_pet_calories_suggested: 'Edit Pet - Calories suggested',
  edit_pet_suggest_calories_modal_opened:
    'Edit Pet - Suggest Calories Modal Opened',
  edit_pet_calories_success: 'Edit Pet - Calories success',
  edit_pet_suggest_calories_exited: 'Edit Pet - Suggest Calories Modal Exited',
  edit_pet_contact_cx_opened: 'Edit Pet - Contact CX Opened',
  toggle_product_line_click: 'Toggle Product Line - Click',
  calibration_in_transit_order_warning:
    'Calibration - In transit order warning',
  treats_unavailable_option_selected: 'Treats - Unavailable Option Selected',
  mixing_plans_change_plans_click: 'MPE - Click Change Plans',
  mixing_plans_plans_and_pricing_click: 'MPE - Click Plans and Pricing',
  mixing_plans_select_plan_click: 'MPE - Click Select Plan',
  mixing_plans_modal_view: 'MPE - View Mixing Plan Modal',
  mixing_plans_modal_close_click: 'MPE - Click Exit Mixing Plan Modal',
  reactivate_load: 'Reactivate - Load',
  reactivate_success: 'Reactivate - Success',
  requested_support: 'Requested - Support',
  terms_changes_notice_dismissed: 'Terms Changes Notice - Dismissed',
  transition_tips_link_clicked: 'Transition Tips - Link Clicked',
  treats_request_a_treat: 'Treats - Request a treat',
  treats_removed_a_treat: 'Treats - Removed a treat',
  view_feeding_tips_clicked: 'View Feeding Tips - Clicked',
  signup_get_started: 'Signup - Get Started',
  signup_site_shutoff_email_form_submitted:
    'Signup Site Shutoff - Email Form Submitted',
  signup_free_treats_add_treat_to_cart:
    'Signup Free Treats - Add Treat to Cart',
  signup_free_treats_open_learn_more: 'Signup Free Treats - Open Learn More',
  signup_free_treats_open_nutrition_facts:
    'Signup Free Treats - Open Nutrition Facts',
  signup_free_treats_remove_treat_from_cart:
    'Signup Free Treats - Remove Treat from Cart',
  signup_surprise_higher_discount: 'Signup - Surprise Higher Discount',
  treats_add_to_plan: 'Treats - Add to Plan',
  treats_product_add_to_plan: 'Treats Product - Add to Plan',
  tiktok_page_view: 'TikTok Page View',
  tiktok_initiate_checkout: 'TikTok Initiate Checkout',
  signup_referral_code: 'Signup - Referral Code',
  checkout_edit_recipes: 'Checkout - Edit Recipes',
  checkout_click_sms_consent: 'Checkout - Click SMS consent',
  checkout_address_validation_error: 'Checkout - Address Validation Error',
  treats_recommendation: 'Treats - How To Treat Recommendation',
  treats_skip_modal_view: 'Treats - Skip Modal View',
  treats_skip_from_modal: 'Treats - Skip From Modal',
  treats_final_sku_removed: 'Treats - Final SKU Removed',
  treats_banner_clicked: 'Treats Banner — Clicked',
  condensed_press_bar_clicked: 'Homepage - interact with press bar',
  account_standalone_popup_appear: 'Account - Standalone popup appears',
  account_standalone_popup_close: 'Account - Standalone popup is closed',
  safety_stock_increase: 'Safety Stock - Increase Button Clicked',
  safety_stock_max_threshold_warning: 'Safety Stock - Max Threshold Warning',
  safety_stock_past_max_threshold:
    'Safety Stock - Over Max Safety Stock Message',
  safety_stock_decrease: 'Safety Stock - Decrease Button Clicked',
  safety_stock_min_threshold_warning: 'Safety Stock - Min Threshold Warning',
  safety_stock_form_submitted: 'Safety Stock - Form Submitted',
  safety_stock_exited: 'Safety Stock - Exited',
  safety_stock_warning_modal_opened: 'Safety Stock - Warning Modal Opened',
  safety_stock_warning_modal_exited: 'Safety Stock - Warning Modal Exited',
  safety_stock_rush_order_cta_clicked: 'Safety Stock - Rush Order CTA Clicked',
  fluent_lead_conversion: 'Fluent - Lead Conversion',
  fluent_trial_conversion: 'Fluent - Trial Conversion',
  secondary_cta_clicked: 'Secondary CTA Clicked',
  back_cta_clicked: 'Back CTA Clicked',
  start_update_portions: 'Start Update Portions',
  update_plan_options_clicked: 'Update Plan Options Clicked',
  update_plan_quote_see_more_options: 'Update Plan Quote See More Options',
};
