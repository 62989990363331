import classNames from 'classnames';
import { useCallback, useState } from 'react';

import {
  ToggleBox,
  Text,
  Button,
  Picture,
  useViewport,
  Grid,
  GridItem,
} from '@farmersdog/corgi-x';

import { trackSignupFreeTreatsOpenNutritionFacts } from '../../../../analytics';
import { SnapSticksRecipeName } from '../../../../graphql/utils';
import { TreatsNutritionFactsModal } from '../../../TreatsNutritionFactsModal';
import chickenPng from '../../assets/chickenSnapSticks.png';
import chickenWebp from '../../assets/chickenSnapSticks.webp';
import porkPng from '../../assets/porkSnapSticks.png';
import porkWebp from '../../assets/porkSnapSticks.webp';
import turkeyPng from '../../assets/turkeySnapSticks.png';
import turkeyWebp from '../../assets/turkeySnapSticks.webp';

import styles from './Treats.module.css';

import type { LeadSelectionTreat, Treat } from '../../../../graphql/types';
import type { TreatsInCheckoutTreatName } from '../../../../types';
import type { ChangeEvent, ReactNode } from 'react';

const TREATS_INGREDIENTS: Record<TreatsInCheckoutTreatName, ReactNode> = {
  [SnapSticksRecipeName.ChickenV2]: (
    <>
      Chicken (protein)
      <br />
      Apple (fiber)
      <br />
      Sea Salt (electrolytes)
    </>
  ),
  [SnapSticksRecipeName.TurkeyV2]: (
    <>
      Turkey (protein)
      <br />
      Pear (fiber)
      <br />
      Sea Salt (electrolytes)
    </>
  ),
  [SnapSticksRecipeName.Pork]: (
    <>
      Pork (protein)
      <br />
      Pear (fiber)
      <br />
      Sea Salt (electrolytes)
    </>
  ),
};

const TREATS_IMAGES = {
  [SnapSticksRecipeName.ChickenV2]: [chickenWebp, chickenPng],
  [SnapSticksRecipeName.TurkeyV2]: [turkeyWebp, turkeyPng],
  [SnapSticksRecipeName.Pork]: [porkWebp, porkPng],
};

/**
 * The snap sticks have display names, but we only want to display the protein as a title
 */
const SNAP_STICK_NAMES = {
  [SnapSticksRecipeName.ChickenV2]: 'Chicken',
  [SnapSticksRecipeName.TurkeyV2]: 'Turkey',
  [SnapSticksRecipeName.Pork]: 'Pork',
};

interface TreatsProps {
  treats: Treat[];
  currentSelection: Treat | null;
  onSelect: (treat: Treat) => void;
  alreadyAddedTreats: LeadSelectionTreat[];
}

export const Treats = ({
  treats,
  currentSelection,
  onSelect,
  alreadyAddedTreats,
}: TreatsProps) => {
  const [nutritionFactsTreat, setNutritionFactsTreat] =
    useState<TreatsInCheckoutTreatName | null>(null);
  const viewport = useViewport();

  const isSingleTreat = treats.length === 1;

  const onCloseNutritionFacts = useCallback(() => {
    setNutritionFactsTreat(null);
  }, []);

  const onOpenNutritionFacts = (treat: Treat) => {
    trackSignupFreeTreatsOpenNutritionFacts(treat);
    setNutritionFactsTreat(treat.name as TreatsInCheckoutTreatName);
  };

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const selectedTreat = treats.find(treat => treat.name === value) as Treat;
      onSelect(selectedTreat);
    },
    [onSelect, treats]
  );

  if (treats.length === 0) return null;

  const currentlySelectedTreat = treats.find(treat =>
    alreadyAddedTreats?.some(addedTreat => addedTreat.name === treat.name)
  );

  const toggleBoxContent = (treat: Treat) => (
    <Grid className={styles.toggleBoxContent}>
      <Picture
        sources={TREATS_IMAGES[treat.name as TreatsInCheckoutTreatName]}
        alt={`${treat.displayName} Snap Sticks`}
      />
      <div className={styles.treatInfo}>
        <div className={styles.treatTitle}>
          <Text
            vSpacing="none"
            as="p"
            color="charcoal-3"
            variant={viewport.md ? 'heading-22' : 'heading-16'}
            bold
          >
            {isSingleTreat
              ? 'Snap Sticks'
              : SNAP_STICK_NAMES[treat.name as TreatsInCheckoutTreatName]}
          </Text>
          <Button
            className={styles.treatNutritionFacts}
            size="sm"
            variant="plain-text"
            weight="normal"
            onClick={() => onOpenNutritionFacts(treat)}
          >
            Nutrition Facts
          </Button>
        </div>
        <Text
          variant={viewport.md ? 'heading-16' : 'heading-12'}
          italic
          color="charcoal-2"
          className={styles.treatIngredients}
        >
          {TREATS_INGREDIENTS[treat.name as TreatsInCheckoutTreatName]}
        </Text>
      </div>
    </Grid>
  );

  if (currentlySelectedTreat && isSingleTreat) {
    const treatToDisplay = currentlySelectedTreat || treats[0];

    return (
      <div className={styles.treatsContainer}>
        <Grid
          className={styles.singleTreatContainer}
          justifyContent="center"
          alignItems="center"
          leftSpacing="md"
          bottomSpacing="sm"
          topSpacing="sm"
          rightSpacing="sm"
        >
          {toggleBoxContent(treatToDisplay)}
          {currentlySelectedTreat && <AddedToPlanBadge />}
        </Grid>
        <TreatsNutritionFactsModal
          treat={nutritionFactsTreat as TreatsInCheckoutTreatName}
          handleOnClose={onCloseNutritionFacts}
        />
      </div>
    );
  }

  return (
    <div className={styles.treatsContainer}>
      {isSingleTreat ? (
        <Grid
          className={styles.singleTreatContainer}
          justifyContent="center"
          alignItems="center"
          leftSpacing="md"
          bottomSpacing="sm"
          topSpacing="sm"
          rightSpacing="sm"
        >
          {toggleBoxContent(treats[0])}
        </Grid>
      ) : (
        <Grid
          className={classNames({
            [styles.multiTreatsContainer]: treats.length > 1,
            [styles.doubleTreatsContainer]: treats.length === 2,
          })}
        >
          {treats.map(treat => (
            <GridItem
              className={styles.treatItemContainer}
              key={`${treat.name}-${treat.size}`}
            >
              <ToggleBox
                type="radio"
                value={treat.name}
                // empty label so I can handle the toggle box content with the children prop
                label=""
                className={styles.treatItem}
                checked={treat.name === currentSelection?.name}
                onChange={onChange}
                toggleSize={viewport.sm ? 'lg' : 'md'}
              >
                {toggleBoxContent(treat)}
                {currentlySelectedTreat &&
                  treat.name === currentlySelectedTreat.name && (
                    <AddedToPlanBadge />
                  )}
              </ToggleBox>
            </GridItem>
          ))}
        </Grid>
      )}
      <TreatsNutritionFactsModal
        treat={nutritionFactsTreat as TreatsInCheckoutTreatName}
        handleOnClose={onCloseNutritionFacts}
      />
    </div>
  );
};

const AddedToPlanBadge = () => (
  <Text variant="heading-12" color="white" className={styles.badge}>
    Added to Plan!
  </Text>
);
