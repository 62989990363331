import { events, track } from 'src/analytics';

import type { RemoveTreatsReasonsEnum } from '@/account/fresh-plans/TreatsSection';

interface TrackRemovedTreatV2Params {
  feedback: string;
  productName: string | null;
  size: string | null;
  removedTreat: string | null;
  selectedReason: RemoveTreatsReasonsEnum | string;
}

/**
 * This function tracks the customer’s desired treat
 * @param feedback - The customer’s feedback
 * @param productName - The customer’s removed treat productName
 * @param size - The customer’s removed treat size
 * @param selectedReason - The customer’s selected reason
 * @param removedTreat - The SKU corresponding to the removed treat
 */
export function trackRemovedTreatV2(params: TrackRemovedTreatV2Params) {
  track(events.treats_removed_a_treat, {
    feedback: params.feedback,
    productName: params.productName,
    size: params.size,
    removedTreat: params.removedTreat,
    selectedReason: params.selectedReason,
  });
}
