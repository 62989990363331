import {
  PATH_PRIVACY,
  PATH_PROFILE_REACTIVATE,
  PATH_TERMS,
  PATH_LOGIN,
} from '@farmersdog/constants/paths';

import { ACCOUNT_REDIRECTS } from '@/account/app/constants/redirects';

interface Redirect {
  from: string;
  to: string;
}

export const ROOT_REDIRECTS: Redirect[] = [
  { from: '/terms', to: PATH_TERMS },
  { from: '/privacy', to: PATH_PRIVACY },
  { from: '/cookdiy', to: PATH_LOGIN },
  { from: '/reactivate', to: PATH_PROFILE_REACTIVATE },
  { from: '/comeback', to: PATH_PROFILE_REACTIVATE },
];

/**
 * A collection of all old urls and their new paths
 */
export const ALL_REDIRECTS: Redirect[] = [
  ...ROOT_REDIRECTS,
  ...ACCOUNT_REDIRECTS, // ugh, remove this once the account is standalone and doesn't have a server (this is used by redirectMiddleware)
];
