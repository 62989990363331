import { useEffect, useMemo } from 'react';

import { useCreateLead } from '../../../graphql/mutations';
import { useRecoverSignupByUrl } from '../../useRecoverSignupByUrl';
import { useThrowToErrorBoundary } from '../../useThrowToErrorBoundary';

interface UseTosaTokenFromUrlEmailReturn {
  data?: { tosaToken: string | null };
}

export function useTosaTokenFromUrlEmail(): UseTosaTokenFromUrlEmailReturn {
  const [createLead, { data, error }] = useCreateLead();
  const throwToErrorBoundary = useThrowToErrorBoundary();

  const recoveryEmailFromUrl = useRecoverSignupByUrl();

  useEffect(() => {
    if (recoveryEmailFromUrl) {
      void createLead({
        variables: {
          email: recoveryEmailFromUrl,
        },
      });
    }
  }, [createLead, throwToErrorBoundary, recoveryEmailFromUrl]);

  if (error) {
    throwToErrorBoundary(error);
  }

  const dataToReturn = useMemo(() => {
    if (data) {
      return { tosaToken: data.createLead.token };
    }

    // Without a recoveryEmailFromUrl, we know we'll never get a tosaToken, so
    // we can just return `null` for it.
    //
    // If we DO have a recoveryEmailFromUrl, we return data: undefined until we
    // get a response back from the server so that the caller knows to wait.
    return recoveryEmailFromUrl ? undefined : { tosaToken: null };
  }, [data, recoveryEmailFromUrl]);

  return {
    data: dataToReturn,
  };
}
