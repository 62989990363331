import {
  Grid,
  GridItem,
  IconButton,
  Picture,
  Section,
  Text,
} from '@farmersdog/corgi-x';

import OpenIcon from '../../assets/open-icon.svg';

import { BenefitCardVariant, benefitDataMap } from '../../data';

import styles from './LifetimeOfBenefitsMobile.module.css';
import {
  PickyEatingMobileSources,
  WeightManagementMobileSources,
  SeniorCareMobileSources,
  PuppyCareMobileSources,
} from '../../assets';

const tileData = {
  [BenefitCardVariant.WeightManagement]: {
    sources: WeightManagementMobileSources,
    title: (
      <>
        Weight
        <br />
        Management
      </>
    ),
  },
  [BenefitCardVariant.PickyEating]: {
    sources: PickyEatingMobileSources,
    title: (
      <>
        Picky
        <br />
        Eating
      </>
    ),
  },
  [BenefitCardVariant.SeniorCare]: {
    sources: SeniorCareMobileSources,
    title: (
      <>
        Senior
        <br />
        Care
      </>
    ),
  },
  [BenefitCardVariant.PuppyCare]: {
    sources: PuppyCareMobileSources,
    title: (
      <>
        Puppy
        <br />
        Care
      </>
    ),
  },
};

interface LifetimeOfBenefitsProps {
  isOpen: boolean;
  onTileClick: (key: BenefitCardVariant) => void;
}

export const LifetimeOfBenefitsMobile = ({
  isOpen,
  onTileClick,
}: LifetimeOfBenefitsProps) => {
  const handleButtonClick = (data: BenefitCardVariant) => {
    onTileClick(data);
  };

  return (
    <Section
      variant="default"
      className={styles.section}
      aria-label="A lifetime of benefits"
    >
      <Text variant="heading-28" color="kale-3" bold>
        A lifetime of benefits
      </Text>
      <Text
        as="div"
        variant="heading-16"
        color="kale-3"
        className={styles.subtitle}
      >
        Real, fresh food for all dogs with plans personalized to your dog’s
        specific needs.
      </Text>
      <Grid columnGap="sm" rowGap="sm">
        {Object.keys(benefitDataMap).map((tileName: string) => {
          const data = benefitDataMap[tileName as BenefitCardVariant];
          const { sources, title } = tileData[tileName as BenefitCardVariant];

          return (
            <GridItem sm={6} key={tileName}>
              <div className={styles.tile}>
                <Text
                  variant="heading-16"
                  bold
                  color="white"
                  className={styles.tileTitle}
                  align="left"
                >
                  {title}
                </Text>
                <IconButton
                  aria-label={`Expand ${tileName}`}
                  aria-controls="lifetime-benefits-drawer"
                  aria-expanded={isOpen}
                  onClick={() =>
                    handleButtonClick(tileName as BenefitCardVariant)
                  }
                  icon={<OpenIcon className={styles.openIcon} />}
                />
                <Picture
                  className={styles.tileImage}
                  sources={[sources.webP, sources.jpg]}
                  alt={data.title}
                />
              </div>
            </GridItem>
          );
        })}
      </Grid>
    </Section>
  );
};
