import eventNames from 'src/analytics/eventNames';
import track from 'src/analytics/track';

export function trackAccountStandalonePopupAppear({
  times,
}: {
  times: number;
}) {
  track(eventNames.account_standalone_popup_appear, {
    times,
  });
}
