/**
 * Ensures the index stays within the valid range `[0, numberOfSlides - 1]`,
 * handling negative values and wrapping correctly in a looping gallery.
 *
 * This prevents issues with indices going out of bounds when looping,
 * replacing the need for explicit `+ numberOfSlides` or `- numberOfSlides` checks.
 *
 * @param index - The index to normalize.
 * @param numberOfSlides - The total number of slides in the gallery.
 * @returns The index wrapped within `[0, numberOfSlides - 1]`.
 *
 * @example
 * numberOfSlides = 6
 * index = 7
 * mapSlideToChild(index, numberOfSlides) // 1
 *
 * index = -1
 * mapSlideToChild(index, numberOfSlides) // 5
 */
export const mapSlideToChild = (index: number, numberOfSlides: number) =>
  ((index % numberOfSlides) + numberOfSlides) % numberOfSlides;
