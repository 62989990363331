import { useMemo } from 'react';

import { couponCookie, ReferrerType } from '@farmersdog/coupons';

import { FeatureName } from '../../../utils';
import { useTosaUserFeature } from '../../useTosaUserFeature';

import type {
  UseFeatureHook,
  UseTreatsInCheckoutUiReturn,
} from '../../../types';
import type { CvrTreatsInCheckoutUiTreatments } from '../../../utils';

interface UseTreatsInCheckoutUiProps {
  useFeature: UseFeatureHook;
  lazy?: boolean;
}

export const useTreatsInCheckoutUi = ({
  useFeature,
  lazy,
}: UseTreatsInCheckoutUiProps): UseTreatsInCheckoutUiReturn => {
  const { referrerCode, referrerType } = couponCookie.get();
  const pcode =
    referrerCode && referrerType == ReferrerType.Partner ? referrerCode : '';

  const { treatment, isReady, getFeatureData } = useTosaUserFeature({
    useFeature,
    featureName: FeatureName.CVR_TREATS_IN_CHECKOUT_UI,
    attributes: { pcode },
    lazy,
  }) as {
    treatment: CvrTreatsInCheckoutUiTreatments;
    isReady: boolean;
    getFeatureData: () => Record<string, unknown>;
  };

  const shouldShowTreatsPage = treatment === 'page';

  return useMemo(
    () => ({
      shouldShowTreatsPage,
      shouldShowTreatsOnCheckoutPage: !shouldShowTreatsPage,
      shouldRemoveTreatsPage: isReady && !shouldShowTreatsPage,
      getFeatureData,
    }),
    [shouldShowTreatsPage, isReady, getFeatureData]
  );
};
