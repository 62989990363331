import { track } from '@farmersdog/analytics';

import { segmentTrackEvents } from '../segmentTrackEventNames';

interface TrackSignupFreeTreatsShownParams {
  treats: string[];
  // This event only fires on the Treats page within TOSA currently
  page: 'treats';
}

/** A Segment tracking event for tracking the treats shown to the user with their order */

export function trackSignupFreeTreatsShown({
  treats,
  page,
}: TrackSignupFreeTreatsShownParams) {
  track(segmentTrackEvents.signup_free_treats_shown, {
    treats,
    page,
  });
}
