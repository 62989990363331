import mobileKidInTfdBoxJpg from './mobile-kid-in-tfd-box.jpg';
import mobileKidInTfdBoxWebp from './mobile-kid-in-tfd-box.webp';

import desktopKidInTfdBoxJpg from './desktop-kid-in-tfd-box.jpg';
import desktopKidInTfdBoxWebp from './desktop-kid-in-tfd-box.webp';

export const imageCardSources = {
  xs: {
    jpg: mobileKidInTfdBoxJpg,
    webp: mobileKidInTfdBoxWebp,
  },

  lg: {
    jpg: desktopKidInTfdBoxJpg,
    webp: desktopKidInTfdBoxWebp,
  },
};
