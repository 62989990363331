/* CH#49502: Used by RET and CVR */
import type { HTMLAttributes, Ref, FormEventHandler } from 'react';
import { forwardRef, createElement } from 'react';

export interface FormProps extends HTMLAttributes<HTMLFormElement> {
  /** Enable or disable the form submit event */
  enableSubmit?: boolean;
  innerRef?: Ref<HTMLFormElement>;
}

export function Form({
  children,
  onSubmit,
  enableSubmit = true,
  innerRef,
  ...props
}: FormProps) {
  const handleSubmit: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();
    e.stopPropagation();

    if (!enableSubmit) return;
    onSubmit?.(e);
  };

  return (
    <form
      data-name="Form"
      noValidate
      onSubmit={handleSubmit}
      ref={innerRef}
      {...props}
    >
      {children}
    </form>
  );
}

export default forwardRef(
  (props: FormProps, innerRef: Ref<HTMLFormElement>) => {
    const WrappedForm = createElement(Form, { ...props, innerRef });
    return WrappedForm;
  }
);
