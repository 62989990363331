import {
  ButtonBase,
  Grid,
  GridItem,
  Lightbox,
  Picture,
  Text,
} from '@farmersdog/corgi-x';
import type { BenefitInformation } from '../../data';

import CheckIcon from '../../assets/check-icon.svg';
import CloseIcon from '../../assets/close-icon.svg';

import styles from './BenefitModal.module.css';
import { paths } from '@farmersdog/constants';
import { ExtendedButton } from '../../../ExtendedButton';

interface BenefitModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  data: BenefitInformation;
}

export const BenefitModal = ({
  isOpen,
  setIsOpen,
  data,
}: BenefitModalProps) => {
  const { sources, title, benefits, testimonial, ctaText } = data;

  return (
    <div className={styles.modal}>
      <Lightbox
        width="1210px"
        height="750px"
        isOpen={isOpen}
        backdropClassName={styles.backdrop}
        closeOnBackdropClick
        onClose={() => setIsOpen(false)}
      >
        <Grid>
          <GridItem lg={5}>
            <Picture
              className={styles.image}
              sources={[sources.webP, sources.jpg]}
              alt={title}
            />
          </GridItem>
          <GridItem lg={7}>
            <div className={styles.modalContent}>
              <Text
                bottomSpacing="lg"
                as="h3"
                variant="heading-40"
                bold
                color="kale-3"
              >
                {title}
              </Text>
              <ul className={styles.list}>
                {benefits.map((benefit, index) => {
                  const isLastItem = index === benefits.length - 1;

                  return (
                    <li key={index}>
                      <CheckIcon />
                      <Text
                        bold
                        as="p"
                        color="kale-3"
                        topSpacing="none"
                        variant="heading-22"
                        bottomSpacing={isLastItem ? 'lg' : 'md'}
                      >
                        {benefit}
                      </Text>
                    </li>
                  );
                })}
              </ul>
              <Text
                bold
                as="p"
                topSpacing="none"
                bottomSpacing="xs"
                variant="heading-12"
                className={styles.quoteTitle}
              >
                Heard around the park
              </Text>
              <blockquote className={styles.blockquote}>
                <Text
                  as="p"
                  topSpacing="none"
                  bottomSpacing="sm"
                  variant="article-16"
                >
                  &quot;{testimonial.quote}&quot;
                </Text>
                <footer>
                  <Text bold variant="heading-16">
                    - {testimonial.author}
                  </Text>
                </footer>
              </blockquote>
              <ExtendedButton
                type="link"
                to={paths.PATH_SIGNUP}
                variant="bordered-charcoal"
                className={styles.button}
              >
                {ctaText}
              </ExtendedButton>
              <ButtonBase
                className={styles.closeButton}
                onClick={() => setIsOpen(false)}
              >
                <CloseIcon />
              </ButtonBase>
            </div>
          </GridItem>
        </Grid>
      </Lightbox>
    </div>
  );
};
