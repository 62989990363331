/* CH#49502: Used by RET and CVR */
import { Link } from '@farmersdog/corgi';
import { Text } from '@farmersdog/corgi-x';
import { emails, phoneNumbers } from '@farmersdog/constants';

import { trackRequestedSupport } from '@/account/analytics';

import MailIcon from 'src/assets/icons/mail.svg';
import SmartPhoneIcon from 'src/assets/icons/smartphone.svg';

import styles from './CXContactInfo.module.scss';

interface CXContactInfoProps {
  email?: string;
  showPhone: boolean;
  showEmail: boolean;
}

function CXContactInfo({ showPhone, showEmail, email }: CXContactInfoProps) {
  const emailToUse = email ?? emails.EMAIL_HELP;

  const handlePhoneSupportClick = () => {
    trackRequestedSupport({ supportChannel: 'phone' });
  };

  const handleEmailSupportClick = () => {
    trackRequestedSupport({ supportChannel: 'email' });
  };

  return (
    <>
      {showPhone && (
        <Text as="div" variant="heading-16" color="kale-3" vSpacing="sm">
          <Link
            href={`tel:${phoneNumbers.FRESH_CX_PHONE.link}`}
            variant="secondary"
            mode="dark"
            onClick={handlePhoneSupportClick}
          >
            <SmartPhoneIcon className={styles.icon} />{' '}
            {phoneNumbers.FRESH_CX_PHONE.display}
          </Link>
        </Text>
      )}
      {showEmail && (
        <>
          <Text as="div" variant="heading-16" color="kale-3" vSpacing="sm">
            <Link
              href={`mailto:${emailToUse}`}
              variant="secondary"
              mode="dark"
              onClick={handleEmailSupportClick}
            >
              <MailIcon className={styles.icon} /> {emailToUse}
            </Link>
          </Text>
        </>
      )}
    </>
  );
}

export default CXContactInfo;
